import * as React from "react";
import moment from "moment";
import LocationDetails from "../layouts/LocationDetails";
import SearchInput from "../inputs/SearchInput";
import HolidaySetupModal from "../layouts/HolidaySetupModal";
import LoadingSpinner from "../../resources/loadingSpinner";
import AreYouSure from "../layouts/AreYouSure";
import EditHoliday from "../layouts/EditHoliday";
import Cookies from "js-cookie";

// Api
import { Get, Delete } from "../../utility/fetch";
import { URLAPI } from "../../utility/ApiMethods";

// icons
import { RiAddLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { GiTrashCan } from "react-icons/gi";

const tableHead = {
  padding: "8px",
  textAlign: "center"
};

const tableContent = {
  whiteSpace: "nowrap",
  textAlign: "left"
};

const HolidaySetup = (props) => {
  const [holidayDetail, setHolidayDetail] = React.useState({
    loading: true,
    holiday: [],
    error: null
  });

  const [showModal, setShowModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [currentHoliday, setCurrentHoliday] = React.useState({});

  const getHolidays = (data) => {
    console.log("Logging leave periods gotten", data);

    data && data.result
      ? setHolidayDetail({
          ...holidayDetail,
          loading: false,
          holiday: [...data.result]
        })
      : setHolidayDetail({
          ...holidayDetail,
          loading: false,
          error: data.message
        });
  };

  const handleDeleteBtn = (id) => {
    confirmDelete === true && Delete(`${URLAPI.HolidaySetup.Get}/${id}`, deleteHoliday);
  };

  const deleteHoliday = (data) => {
    console.log("Logging the data of the leave to delete", data);
    setConfirmDelete(false);
    closeModal();
    Get(URLAPI.HolidaySetup.Get, getHolidays);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditModal(false);
    setDeleteModal(false);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Leave Management";

    console.log("holiday route", URLAPI.HolidaySetup.Get);

    Get(URLAPI.HolidaySetup.Get, getHolidays);
  }, []);
  return (
    <div className="w-100">
      {holidayDetail.loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {!holidayDetail.error ? (
            <>
              <div className="w-100 flex space-between flex-v-center">
                <h2>Holiday Management</h2>
                {/* <LocationDetails /> */}
              </div>
              <div className="w-100 m-t-20 flex space-between flex-v-center">
                {/* <h4 className="san-sherif">Holiday Management</h4> */}
                <div />
                <div>
                  <form>
                    <SearchInput />
                  </form>
                </div>
              </div>
              <div className="table-view m-t-10">
                <div className="table-header">
                  <div className="table-row">
                    <div className="th" style={{ width: "20%", padding: "8px" }}>
                      <p className="san-sherif">Holiday Name</p>
                    </div>
                    <div className="th" style={{ ...tableHead }}>
                      <p className="san-sherif">Holiday Date</p>
                    </div>
                    <div className="th" style={{ ...tableHead }}>
                      <p className="san-sherif">Deductible</p>
                    </div>
                    <div className="th" style={{ ...tableHead }}>
                      <p className="san-sherif">Days Deducted</p>
                    </div>
                    <div className="th" style={{ ...tableHead }}>
                      <p className="san-sherif">Last Updated By:</p>
                    </div>
                    <div className="th" style={{ ...tableHead }}>
                      <button
                        className="add-button pointer white-space-nowrap"
                        type="button"
                        onClick={() => setShowModal(true)}>
                        <RiAddLine />
                        Add Holiday
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-body">
                  {holidayDetail.holiday.map(
                    ({ id, holidayName, holidayDate, isDeductible, daysDeducted, modifiedBy }) => (
                      <div className="table-row" key={id}>
                        <div className="td" style={{ whiteSpace: "nowrap", textAlign: "left" }}>
                          {holidayName}
                        </div>
                        <div className="td" style={{ ...tableContent }}>
                          {moment(`${holidayDate}`).format("MMMM Do, yyyy")}
                        </div>
                        <div className="td" style={{ ...tableContent }}>
                          {isDeductible ? "Yes" : "No"}
                        </div>
                        <div className="td" style={{ ...tableContent }}>
                          {/* {moment(`${modifiedAt}`).format("yy:MM:DD/ hh:mm")} */}
                          {daysDeducted}
                        </div>
                        <div className="td" style={{ ...tableContent }}>
                          {Cookies.get("fullname")}
                        </div>
                        <div className="td actions">
                          <button
                            type="button"
                            className="pointer tooltip"
                            onClick={() => {
                              setCurrentHoliday({
                                holidayName,
                                holidayDate: moment(`${holidayDate}`).format("YYYY-MM-DD"),
                                isDeductible,
                                daysDeducted,
                                id
                              });
                              setEditModal(true);
                            }}>
                            <BiEditAlt />
                            <span className="tooltiptext">Edit</span>
                          </button>
                          <button
                            type="button"
                            className="m-l-10 pointer del tooltip"
                            onClick={() => {
                              setCurrentHoliday({
                                title: "Holiday Name",
                                name: holidayName,
                                id
                              });
                              setDeleteModal(true);
                            }}>
                            <GiTrashCan />
                            <span className="tooltiptext">Delete</span>
                          </button>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
              {showModal ? (
                <HolidaySetupModal
                  closeModal={closeModal}
                  leavePeriod={holidayDetail}
                  setLeavePeriod={setHolidayDetail}
                />
              ) : editModal ? (
                <EditHoliday
                  closeModal={closeModal}
                  setLeaveDetails={setHolidayDetail}
                  leaveDetails={holidayDetail}
                  editLeave={currentHoliday}
                />
              ) : deleteModal ? (
                <AreYouSure
                  closeModal={closeModal}
                  currentPeriod={currentHoliday}
                  setConfirmDelete={setConfirmDelete}
                  handleDeleteBtn={handleDeleteBtn}
                  confirmDelete={confirmDelete}
                />
              ) : null}
            </>
          ) : (
            <div
              className="flex flex-v-center flex-h-center bolder-text"
              style={{ color: "red", fontSize: "1.2rem" }}>
              {holidayDetail.error}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HolidaySetup;
