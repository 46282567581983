import React from 'react';
import moment from "moment";
import LocationDetails from "../layouts/LocationDetails";
import SelectInput from "../inputs/SelectInput";
import FormDateInput from "../inputs/FormDateInput";
import TextAreaInput from "../inputs/TextareaInput";
import FormSearchInput from "../inputs/FormSearchInput";
import { BiEditAlt } from "react-icons/bi";
import { GiTrashCan } from "react-icons/gi";
import Accordion from "../layouts/Accordion";
import LoadingSpinner from "../../resources/loadingSpinner";
import AreYouSure from "../layouts/AreYouSure";
import Popup from "../layouts/Popup";
import SuccessModal from "../layouts/SuccessModal";
import notification from "../../utility/notification";
import ErrorModal from "../layouts/ErrorModal";
import Loader from "../../assets/svg/loading.svg";
import { getLeaveDays } from "../../utility/general";
import { Get, GetwithQueryString, Post, Delete } from "../../utility/fetch";
import { URLAPI } from "../../utility/ApiMethods";
import { IoMdClose } from 'react-icons/io';







 const LeaveRequestModal = (props) =>{
    const [leaveSession, setLeaveSession] = React.useState({
        loading: true,
        data: [],
        error: null
      });
      const [leaveSessionCont, setLeaveSessionCont] = React.useState({
        loading: true,
        data: [],
        error: null
      });
      const [reliefOfficers, setReliefOfficers] = React.useState([]);
      const [leaveTypeID, setLeaveTypeID] = React.useState(null);
      const [reliefOfficerID, setReliefOfficerID] = React.useState(null);
      const [daysSelected, setDaysSelected] = React.useState(0);
      const [success, setSuccess] = React.useState(false);
      const [successMessage, setSuccessMessage] = React.useState('')
      const [modalOpen, setModalopen] = React.useState(false)
      const [action, setAction] = React.useState("successfully Submitted for Approval");
      const [leavePlan, setLeavePlan] = React.useState({
        loading: true,
        data: [],
        error: null
      });
      // const [accordionDetails, setAccordionDetails] = React.useState([]);
      const [leaveType, setLeaveType] = React.useState([]);
      const [leaveType2, setLeaveType2] = React.useState(props.leaveTypes);
      const [leaveTypeName, setLeaveTypeName] = React.useState("");
      const [officerPlan, setOfficerPlan] = React.useState({
        loading: true,
        data: []
      });
      const [dateselected, setDateselected] = React.useState({
        loading: true,
        data: [],
        error: null
      });
      const [createLeavePlan, setCreateLeavePlan] = React.useState({
        leaveName: "",
        startDate: "",
        endDate: "",
        reliefOfficerName: ""
      });
      const [reliefOfficerPlan, setReliefOfficerPlan] = React.useState([]);
      const [reliefOfficerTo, setReliefOfficerTo] = React.useState({
        loading: true,
        data: [],
        error: null
      });
      const [showPopup, setShowPopup] = React.useState(true);
      const [currentLeavePlan, setCurrentLeavePlan] = React.useState({});
      const [confirmDelete, setConfirmDelete] = React.useState(false);
      const [handoverNote, setHandoverNote] = React.useState('')
      const [leaveReason, setLeaveReason] = React.useState('')
      const [showDeleteModal, setShowDeleteModal] = React.useState(false);
      const [isLoading, setIsLoading] = React.useState(false);
      const closePopup = () => {
        setShowPopup(false);
        setSuccess(false);
      };
      const [showErrorModal, setShowErrorModal] = React.useState(false);
      const [errorMsg, setErrorMsg] = React.useState("");
      const closeSuccessModal = () => setSuccess(false);

      React.useEffect(()=>{
        console.log(createLeavePlan.leaveName ) 
        console.log(leaveType2)
        console.log(createLeavePlan.endDate)
      }, [createLeavePlan])
      

      function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      }

      const centerContent = {
        textAlign: "left"
      };
      
      const inputStyle = {
        margin: "10px 0"
      };
      
      const labelStyle = {
        color: "rgba(112,112,112,.8)"
      };

      const checkIsRequestSave = (data) => {
        const { statusCode } = data;
        console.log("Logging to check if request is saved", data);
    
        if (data && statusCode === "01") {
          Get(URLAPI.LeavePlan.Get, getLeavePlan);
          setIsLoading(false);
          setAction("Leave Plan Saved Successfully!");
          setSuccess(true);
        }
      };


      const clearForm = () =>{
        setCreateLeavePlan({
          leaveName: "",
          startDate: "",
          endDate: "",
          reliefOfficerName: ""
        })
        setLeaveReason('')
        setReliefOfficerID(0)
        setHandoverNote('');
        setDaysSelected(0)
      }

      const getLeavePlan = (data) => {
        console.log("get leave Plan", data);
    
        data && data.result
          ? setLeavePlan({
              ...leavePlan,
              loading: false,
              data: [...data.result]
            })
          : setLeavePlan({
              ...leavePlan,
              loading: false,
              error: data.message
            });
      };

      React.useEffect(()=>{
        const { startDate, endDate } = createLeavePlan;
        const { leavedaysleft } = leaveSessionCont.data;

        if (startDate && endDate && startDate <= endDate) {
          createLeavePlan.leaveName === 'Maternity' ?
          setDaysSelected(90) :
      setDaysSelected(getLeaveDays(startDate, endDate));
      console.log(getLeaveDays(startDate, endDate))
        }
      },[createLeavePlan])



      const submitLeave = (id) => {

        const { startDate, endDate, leaveName, reliefOfficerName } = createLeavePlan;
    
        if (!leaveTypeID) {
          notification({
            title: "Leave Type Error!",
            message: "Please Select a Leave Type!",
            type: "danger"
          });
          return;
        } else if (!reliefOfficerID) {
          notification({
            title: "Releive Officer Error!",
            message: "Please Select a Relieve Officer!",
            type: "danger"
          });
          return;
        } else if (startDate <= moment().format("YYYY-MM-DD")) {
          notification({
            title: "Date Error!",
            message: "Start date must be greater than today's date!",
            type: "danger"
          });
          return;
        } else if (startDate >= endDate) {
          notification({
            title: "Date Error!",
            message: "End date must be greater than start date!",
            type: "danger"
          });
          return;
        }
        else{
          setIsLoading(true)
       
        setLeaveSession({ ...leaveSession, loading: true });
        Post({ leaveReason:leaveReason, handoverNote:handoverNote,reliefOfficerId:reliefOfficerID, reliefOfficer:reliefOfficerName, leaveStatus: 1,leaveType:leaveName, leaveDuration:daysSelected,leaveTypefk: 0,leaveSession:"",startDate:startDate, endDate:endDate }, URLAPI.Approver.submitOtherLeave, (data) => {
         console.log(leaveReason, handoverNote, reliefOfficerID, reliefOfficerName, leaveName)
          const { statusCode, apiMessage } = data;
          
          console.log(
            "Logging data after submitting leave request",
            data,
            typeof statusCode,
            apiMessage
          );
    
          if (data && statusCode === "00") {
            setSuccessMessage(apiMessage)
            setSuccess(true)
            clearForm();
            Get(URLAPI.LeavePlan.Get, getLeavePlan);
            setIsLoading(false)
            setLeaveSession({ ...leaveSession, loading: false });
          } else if (data && statusCode === "01") {
            Get(URLAPI.LeavePlan.Get, getLeavePlan);
            setErrorMsg(apiMessage);
            setShowErrorModal(true);
            setLeaveSession({ ...leaveSession, loading: false });
          }
        });
      }
      };

      const saveRequest = () => {
        const { startDate, endDate, leaveName, reliefOfficerName } = createLeavePlan;
    
        if (!leaveTypeID) {
          notification({
            title: "Leave Type Error!",
            message: "Please Select a Leave Type!",
            type: "danger"
          });
          return;
        } else if (!reliefOfficerID) {
          notification({
            title: "Releive Officer Error!",
            message: "Please Select a Relieve Officer!",
            type: "danger"
          });
          return;
        } else if (startDate <= moment().format("YYYY-MM-DD")) {
          notification({
            title: "Date Error!",
            message: "Start date must be greater than today's date!",
            type: "danger"
          });
          return;
        } else if (startDate >= endDate) {
          notification({
            title: "Date Error!",
            message: "End date must be greater than start date!",
            type: "danger"
          });
          return;
        } else {
          if (leaveSessionCont.data.leavedaysleft >= daysSelected) {
            Post(
              {
                reliefEmpfk: reliefOfficerID,
                reliefOfficer: reliefOfficerName,
                leaveTypefk: leaveTypeID,
                leaveType: leaveName,
                leaveDuration: daysSelected,
                startDate: startDate,
                endDate: endDate
              },
              URLAPI.LeavePlan.save,
              checkIsRequestSave
            );
            setIsLoading(true);
            Get(URLAPI.LeavePlan.Get, getLeavePlan);
            setCreateLeavePlan({
              ...createLeavePlan,
              leaveName: "",
              startDate: "",
              endDate: "",
              reliefOfficerName: ""
            });
          } else {
            notification({
              title: "Error!",
              message: "Days Selected is greater than leave days left",
              type: "danger"
            });
          }
        }
      };


      const setReliefOficerPlan = (response) => console.log("Relief Officer plan", response);


      const getReliefOfficer = (id) => Get(`${URLAPI.leaveType.Post}${id}`, setReliefOficerPlan);

      const getReliefOfficerNames = (data) => {
        console.log("logging getReliefOfficerNames", data);
        data && data.result ? setReliefOfficers([...data.result]) : setReliefOfficers([]);
      };

      const getLeaveSessionCont = (data) => {
        console.log("Leave SessionCont Data", data);
    
        if (data) {
          if (data.result) {
            data.result.result &&
              setLeaveSessionCont({
                ...leaveSessionCont,
                loading: false,
                data: { ...data.result.result }
              });
          } else {
            setLeaveSessionCont({
              ...leaveSession,
              loading: false,
              error: data.message
            });
          }
          setLeaveSession({ ...leaveSession, loading: false });
        }
      };

      const selectReliefOfficer = (id) => {
        setLeaveSession({ ...leaveSession, loading: true });
        GetwithQueryString(
          URLAPI.LeavePlan.selectReliefOfficer,
          { name: "ReliefOfficerid", value: id },
          (data) => {
            data && data.result ? setReliefOfficerPlan([...data.result]) : setReliefOfficerPlan([]);
            data && data.result
              ? setLeaveSession({ ...leaveSession, loading: false })
              : setLeaveSession({ ...leaveSession, loading: true });
          }
        );
      };

    return(
<>
        <div className="w-100">

            <div
            className='overlay'
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0px 2px 6px #0000000A',
            }}>
            <div className='flex flex-v-center flex-h-center flex-direction-column'>
              <div
                className='modal-box'
                style={{
                  boxShadow: '0px 2px 6px #0000000A',
                  position: 'relative',
                  width: '650px',
                }}>

            <div className="bold-text m-t-20" style={{ margin: "10px" }}>
              <h3 >Create Leave Request</h3>
            </div>
            <IoMdClose
                    className='pointer m-l-5'
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '100%',
                      fontSize: '24px',
                      color: '#fff',
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#F74F11',
                      padding: '3px',
                    }}
                    onClick={props.closeModal}
                  />
            <div className="row w-100" style={{ margin: ".6rem 0 0" }}>
              <div
                className="col-12"
                style={{
                  backgroundColor: "white",
                  boxShadow: "0px 2px 6px #0000000A",
                  padding: "1rem .7rem",
                 
                }}>
                <form>
                  <div style={{ ...inputStyle }}>
                    <SelectInput
                      label={"Leave Type"}
                      options={leaveType2}
                      value={createLeavePlan.leaveName}
                      onChange={(e) => {
                        setCreateLeavePlan({
                          ...createLeavePlan,
                          leaveName: e.target.value
                        });
                        e.target.children[e.target.selectedIndex].id &&
                          GetwithQueryString(
                            URLAPI.LeavePlan.leaveSessionCont,
                            {
                              name: "leavetypeid",
                              value: e.target.children[e.target.selectedIndex].id
                            },
                            getLeaveSessionCont
                          );
                        setLeaveSession({ ...leaveSession, loading: true });
                        e.target.children[e.target.selectedIndex].id &&
                          setLeaveTypeID(Number(e.target.children[e.target.selectedIndex].id));
                      }}
                      selectOption={getReliefOfficer}
                    />
                  </div>
                  <div style={{ ...inputStyle }}>
                    <FormSearchInput
                      label={"Select Relief Officer"}
                      placeholder="Relief Officer Name....."
                      value={createLeavePlan.reliefOfficerName}
                      onChange={(e) => {
                        setCreateLeavePlan({
                          ...createLeavePlan,
                          reliefOfficerName: e.target.value
                        });
                        GetwithQueryString(
                          URLAPI.LeavePlan.staffList,
                          {
                            name: "name",
                            value: e.target.value
                          },
                          getReliefOfficerNames
                        );
                      }}
                      displayType="names"
                      setStateValue={setCreateLeavePlan}
                      formState={createLeavePlan}
                      stateName={"reliefOfficerName"}
                      searchValue={reliefOfficers}
                      setSuggestions={setReliefOfficers}
                      selectedId={setReliefOfficerID}
                      selectReliefOfficer={selectReliefOfficer}
                    />
                  </div>
                  <div style={{ ...inputStyle }}>
                      <TextAreaInput
                         label={"Leave Reason"}
                         name="leaveReason"
                         value={leaveReason}
                         onChange={(e)=>{setLeaveReason(e.target.value)}}
                      />
                  </div>

                  <div style={{ ...inputStyle }}>
                      <TextAreaInput
                        label={"Handover Note"}
                        name="handOverNote"
                        value={handoverNote}
                        onChange={(e)=>{setHandoverNote(e.target.value)}}
                      />
                  </div>


                  <div style={{ ...inputStyle }}>
                    <FormDateInput
                      label={"Start Date"}
                      name="startDate"
                      value={createLeavePlan.startDate}
                      onChange={(e) => {
                        if (moment(e.target.value).day() < 1 || moment(e.target.value).day() > 5) {
                          notification({
                            title: "Date Error!",
                            message: "Leave cannot start during the weekend",
                            type: "danger"
                          });
                        } else if (e.target.value <= moment().format("YYYY-MM-DD")) {
                          notification({
                            title: "Date Error!",
                            message: "Start date must be greater than today's date!",
                            type: "danger"
                          });
                        } else {
                          createLeavePlan.leaveName === 'Maternity' ?
                          setCreateLeavePlan({ ...createLeavePlan,startDate: e.target.value, endDate: addDays(e.target.value, 90).toISOString().split('T')[0], startDate: e.target.value})
                          :
                          setCreateLeavePlan({ ...createLeavePlan, startDate: e.target.value });

                        }
                      }}
                    />
                  </div>
                  <div style={{ ...inputStyle }}>
                    <FormDateInput
                      label={"End Date"}
                      name="endDate"
                      disabled={createLeavePlan.leaveName === 'Maternity'}
                      value={createLeavePlan.endDate}
                      onChange={(e) => {
                        if (moment(e.target.value).day() < 1 || moment(e.target.value).day() > 5) {
                          notification({
                            title: "Date Error!",
                            message: "Leave cannot end during the weekend",
                            type: "danger"
                          });
                        } else if (
                          !createLeavePlan.startDate ||
                          createLeavePlan.startDate > e.target.value
                        ) {
                          notification({
                            title: "Date Error!",
                            message: "End date must be greater than start date!",
                            type: "danger"
                          });
                        } else {
                           
                          setCreateLeavePlan({ ...createLeavePlan, endDate: e.target.value});
                        }
                      }}
                    />
                  </div>

                  <div style={{ margin: ".8rem 0", ...labelStyle }}>
                    Number of days selected: <span>{daysSelected}</span>
                  </div>

                      {success && (
                        <div
                          className="overlay flex"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            boxShadow: "0px 2px 6px #0000000A"
                          }}>
                          <SuccessModal action={action} closeModal={closeSuccessModal} />
                        </div>
                      )}

                  <div className=" space-around w-100">
                    <button
                      className="btn btn-large p-r w-100 flex  flex-v-center flex-h-center justify-content-center"
                      style={{ textAlign: "center", height: "43px" }}
                      type="button"
                      onClick={submitLeave}
                      disabled={isLoading}>
                      <p style={{ margin: "auto 15px auto" }}>{isLoading ? "Submitting" : "Submit"}</p>
                      {isLoading && <img src={Loader} className="btn-loading" alt="Loading..." />}
                    </button>
                    {/* <button className="btn btn-large w-45" type="button">
                      Submit
                    </button> */}
                  </div>
                </form>
              </div>
              {/* <div className="col-8" style={{ marginBottom: "10px" }}>
                <Accordion
                  title="Leave Plan Details"
                  content={
                    leavePlan.data.length > 0 ? leavePlan.data : "You Have no Leave Plan Yet"
                  }
                />
                <Accordion
                  title="Relief Officer Plan"
                  content={reliefOfficerPlan.length > 0 ? reliefOfficerPlan : "No Leave Plan"}
                />
                <Accordion
                  title="You are a Relief Officer to"
                  content={
                    reliefOfficerTo.data.length > 0
                      ? reliefOfficerTo.data
                      : "Not A Relief Officer to Anyone yet"
                  }
                />
              </div> */}
            </div>
            </div>
            </div>
            </div>
          </div>
          </>
    )


}

export default LeaveRequestModal;