import * as React from "react";
import { IoMdClose } from "react-icons/io";
import moment from "moment";
import TextInput from "../inputs/TextInput";
import FormDateInput from "../inputs/FormDateInput";
import CheckboxInput from "../inputs/CheckboxInput";
import SuccessModal from "./SuccessModal";
import notification from "../../utility/notification";
import LoaderSrc from "../../assets/svg/loading.svg";
// Api
import { Post, Get } from "../../utility/fetch";
import { URLAPI } from "../../utility/ApiMethods";

const LeavePeriodModal = ({
  closeModal,
  setLeavePeriod,
  leavePeriod,
  ...props
}) => {
  const [success, setSuccess] = React.useState(false);
  const [modalError, setModalError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [modalValues, setModalValues] = React.useState({
    holidayName: "",
    holidayDate: "",
    isDeductible: false,
    daysDeducted: 0,
  });

  const getLeavePeriod = (data) => {
    console.log("Logging leave periods gotten", data);

    data && data.result
      ? setLeavePeriod({
          ...leavePeriod,
          loading: false,
          holiday: [...data.result],
        })
      : setLeavePeriod({
          ...leavePeriod,
          loading: false,
          error: data.message,
        });
  };

  const getResponse = (response) => {
    console.log("REsponse of posting leave type", response);

    response && response.statusCode === "00" && setSuccess(true);
    response &&
      response.statusCode === "00" &&
      Get(URLAPI.HolidaySetup.Get, getLeavePeriod);
  };

  const submitForm = (e) => {
    e.preventDefault();

    console.log("Work in Progress...", modalValues);
    if (
      modalValues.holidayDate < moment(`${new Date()}`) ||
      moment().format("YYYY-MM-DD") > modalValues.holidayDate
    ) {
      setModalError("Invalid holiday Date");
      console.log("error true");
    } else {
      setLoading(true);
      Post(modalValues, URLAPI.HolidaySetup.Post, getResponse);
      setModalError("");
    }
  };

  const closeSuccessModal = () => {
    setSuccess(false);
    closeModal();
  };

  return (
    <div
      className="overlay flex"
      style={{ alignItems: "center", justifyContent: "center" }}
    >
      {!success ? (
        <div className="w-100" style={{ boxShadow: "0px 2px 6px #0000000A" }}>
          <IoMdClose className="close-btn pointer" onClick={closeModal} />
          <div className="modal-box max-w-700">
            <div className="content">
              <form
                className="w-100"
                onSubmit={(e) => {
                  submitForm(e);
                }}
              >
                <TextInput
                  className="w-100 m-b-10"
                  label="Holiday Name"
                  value={modalValues.holidayName}
                  onChange={(e) =>
                    setModalValues({
                      ...modalValues,
                      holidayName: e.target.value,
                    })
                  }
                  required
                />
                <div className="flex my-10 w-100 space-between">
                  <FormDateInput
                    name="startDate"
                    label="Holiday Date"
                    className="w-100"
                    value={modalValues.holidayDate}
                    onChange={(e) =>
                      setModalValues({
                        ...modalValues,
                        holidayDate: e.target.value,
                      })
                    }
                    required
                  />
                  {/* <FormDateInput
                    name="endDate"
                    label="End Date"
                    className="w-50"
                    value={modalValues.endDate}
                    onChange={(e) =>
                      setModalValues({
                        ...modalValues,
                        endDate: e.target.value,
                      })
                    }
                    required
                  /> */}
                </div>
                <CheckboxInput
                  name="deductible"
                  label="Deductible"
                  value={modalValues.isDeductible}
                  onChange={(e) =>
                    setModalValues({
                      ...modalValues,
                      isDeductible: e.target.checked,
                    })
                  }
                  required
                />
                {modalError &&
                  notification({
                    title: "Modal Error!",
                    message: `${modalError}`,
                    type: "danger",
                  })}
                {modalError &&
                  setTimeout(() => {
                    setModalError("");
                  }, 5000)}
                <button
                  type="submit"
                  className="btn btn-large p-r w-100 flex m-t-20 flex-v-center flex-h-center justify-content-center"
                  disabled={loading}
                  onClick={() => {
                    setModalValues({
                      ...modalValues,
                      daysDeducted: modalValues.isDeductible
                        ? Number(
                            `${moment(`${modalValues.holidayDate}`).diff(
                              moment().format("YYYY-MM-DD"),
                              "days"
                            )}`
                          )
                        : 0,
                    });
                  }}
                >
                  <p style={{ margin: "auto 15px auto" }}>
                    {loading ? " Creating Holiday" : " Create Holiday"}
                  </p>
                  {loading && (
                    <img
                      src={LoaderSrc}
                      className="btn-loading"
                      alt="Loading..."
                    />
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <SuccessModal closeModal={closeSuccessModal} />
      )}
    </div>
  );
};

export default LeavePeriodModal;
