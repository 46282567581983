import * as React from "react";
import moment from "moment";
import LocationDetails from "../layouts/LocationDetails";
import SearchInput from "../inputs/SearchInput";
import LeaveTypeModal from "../layouts/LeaveTypeModal";
import EditLeaveType from "../layouts/EditLeaveType";
import LoadingSpinner from "../../resources/loadingSpinner";
import AreYouSure from "../layouts/AreYouSure";
// import Cookies from "js-cookie";
// icons
import { RiAddLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { GiTrashCan } from "react-icons/gi";

// Api
import { Get, Delete } from "../../utility/fetch";
import { URLAPI } from "../../utility/ApiMethods";

const tableHead = {
  padding: "8px"
};

const centerContent = {
  textAlign: "left"
};
const CreateLeaveType = (props) => {
  const [leaveDetails, setLeaveDetails] = React.useState({
    loading: true,
    data: [],
    error: null
  });
  const [showModal, setShowModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [currentLeave, setCurrentLeave] = React.useState({});

  const closeModal = () => {
    setShowModal(false);
    setEditModal(false);
    setDeleteModal(false);
  };

  // console.log("Logging Current Leave", currentLeave);

  const deleteLeaveType = (data) => {
    console.log("Logging the data of the leave to delete", data);
    setConfirmDelete(false);
    closeModal();
    Get(URLAPI.LeaveTypeSetup.Get, getLeaveDetails);
  };

  const handleDeleteBtn = (id) => {
    confirmDelete === true && Delete(`${URLAPI.LeaveTypeSetup.Delete}${id}`, deleteLeaveType);
  };

  const getLeaveDetails = (data) => {
    console.log("Logging leave details gotten", data.message);

    data && data.result
      ? setLeaveDetails({
          ...leaveDetails,
          loading: false,
          data: [...data.result]
        })
      : setLeaveDetails({
          ...leaveDetails,
          loading: false,
          error: data.message
        });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Leave Management";

    Get(URLAPI.LeaveTypeSetup.Get, getLeaveDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-100">
      {leaveDetails.loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {!leaveDetails.error ? (
            <>
              <div className="w-100 flex space-between flex-v-center">
                <h2>Leave Type</h2>
                {/* <LocationDetails /> */}
              </div>
              <div className="w-100 m-t-20 flex space-between flex-v-center">
                {/* <h4 className="san-sherif">Create Leave Type</h4> */}
                <h4 className="san-sherif"></h4>
                <div>
                  <form>
                    <SearchInput />
                  </form>
                </div>
              </div>
              <div className="table-view m-t-10">
                <div className="table-header">
                  <div className="table-row">
                    <div className="th" style={{ width: "30%", ...tableHead }}>
                      <p className="san-sherif">Leave Name</p>
                    </div>
                    <div className="th" style={{ ...tableHead, ...centerContent }}>
                      <p className="san-sherif">Number of Days</p>
                    </div>
                    <div className="th" style={{ ...tableHead, ...centerContent }}>
                      <p className="san-sherif">Deductible</p>
                    </div>
                    <div className="th" style={{ ...tableHead, ...centerContent }}>
                      <p className="san-sherif">Last Updated</p>
                    </div>
                    <div className="th" style={{ ...tableHead, ...centerContent }}>
                      <p className="san-sherif">Last Updated By:</p>
                    </div>
                    <div className="th" style={{ ...tableHead }}>
                      <button
                        className="add-button pointer"
                        type="button"
                        onClick={() => setShowModal(true)}>
                        <RiAddLine />
                        Add Leave Type
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-body">
                  {leaveDetails.data.map(
                    ({
                      id,
                      leaveName,
                      isDeductible,
                      modifiedAt,
                      modifiedBy,
                      maxLeaveDays,
                      leaveCategory
                    }) => (
                      <div className="table-row" key={id}>
                        <div className="td">{leaveName}</div>
                        <div className="td" style={{ ...centerContent }}>
                          {maxLeaveDays}
                        </div>
                        <div className="td" style={{ ...centerContent }}>
                          {isDeductible ? "Yes" : "No"}
                        </div>
                        <div className="td" style={{ ...centerContent }}>
                          {/* {moment(`${modifiedAt}`).format("YYYY:MM:DD/ HH:mm")} */}
                          {moment(`${modifiedAt}`).format("DD:MM:YYYY/ HH:mm")}
                        </div>
                        <div className="td" style={{ ...centerContent }}>
                          {/* {Cookies.get("fullname")} */}
                          {modifiedBy}
                        </div>
                        <div className="td actions">
                          <button
                            type="button"
                            className="pointer tooltip"
                            onClick={() => {
                              setCurrentLeave({
                                id,
                                leaveName,
                                isDeductible,
                                modifiedAt,
                                modifiedBy,
                                leaveCategory,
                                maxLeaveDays
                              });
                              setEditModal(true);
                            }}>
                            <BiEditAlt />
                            <span className="tooltiptext">Edit</span>
                          </button>
                          <button
                            type="button"
                            className="m-l-10 pointer del tooltip"
                            onClick={() => {
                              setCurrentLeave({
                                title: "Leave Name",
                                name: leaveName,
                                id
                              });
                              setDeleteModal(true);
                            }}>
                            <GiTrashCan />
                            <span className="tooltiptext">Delete</span>
                          </button>
                        </div>
                      </div>
                    )
                  )}
                </div>
                {showModal ? (
                  <LeaveTypeModal
                    closeModal={closeModal}
                    setLeaveDetails={setLeaveDetails}
                    leaveDetails={leaveDetails}
                  />
                ) : editModal ? (
                  <EditLeaveType
                    closeModal={closeModal}
                    setLeaveDetails={setLeaveDetails}
                    leaveDetails={leaveDetails}
                    editLeave={currentLeave}
                  />
                ) : deleteModal ? (
                  <AreYouSure
                    closeModal={closeModal}
                    currentPeriod={currentLeave}
                    setConfirmDelete={setConfirmDelete}
                    handleDeleteBtn={handleDeleteBtn}
                    confirmDelete={confirmDelete}
                  />
                ) : null}
              </div>
            </>
          ) : (
            <div
              className="flex flex-v-center flex-h-center bolder-text"
              style={{ color: "red", fontSize: "1.2rem" }}>
              {leaveDetails.error}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CreateLeaveType;
