export const URLAPI = {
  Auth: {
    Post: "/api/Auth/authorization",
    PostId: "/api/Auth/signin",
  },
  Approver: {
    submit: "/submitleave",
    submitOtherLeave: "/submitotherleave",
    getPending: "/pendingapprovals/",
    approve: "/approveleave",
  },
  LeavePlan: {
    leaveSession: "/leaveplan/leavesession",
    leaveSessionCont: "/leaveplan/onselectleavetypes",
    Get: "/leaveplan/getmyleaveplan",
    getSpecificPlan: "/leaveplan/getmyspecificleaveplan",
    getLeavePlanDetails:"/leaveplan/getmyleaveplandetails",
    updateSpecificPlan:"/leaveplan/updatemyspecificleaveplan",
    GetApproval: "/leaveplan/getmyleaveplanapprovals",
    GetSignature:"/leaveplan/getEmpLeavePlanSignature",
    staffList: "/stafflist",
    checkdateselected: "/leaveplan/checkdateselected",
    save: "/saveleaveplan",
    selectReliefOfficer: "/leaveplan/onselectofReliefOfficer",
    reliefTo: "/leaveplan/reliefto",
    Delete: "/leaveplan/",
    submit: "/submitleaverequest",
  },
  LeaveRequest: {
    saveRequest: "/api/LeaveRequest/saveleaverequest",
    Get: "/LeaveRequest/getmyleaverequest",
    // https://edogoverp.com/leave2/leaveplan/getmyleaveplanapprovals
    // https://edogoverp.com/leave2/leaverequest/getmyleaverequestapprovals
    GetApproval: "/LeaveRequest/getmyleaverequestapprovals",
  },
  LeaveType: {
    Get: "/api/Setup/leavetypes",
    Get2:"/api/Setup/otherleavetypes",
    Post: "/api/LeavePlan/selectleavetype/",
    GetDate: "/api/LeavePlan/checkdateselected",
  },
  LeaveTypeSetup: {
    Post: "/api/Setup/createleavetype",
    Get: "/api/Setup/leavetypes",
    Delete: "/api/Setup/leavetypes/",
    Put: "/api/Setup/leaveTypes/",
  },
  LeavePeriodSetup: {
    Post: "/api/Setup/createleaveperiod",
    Get: "/api/Setup/leaveperiod/all",
    Delete: "/api/Setup/leaveperiods/",
    Put: "/api/Setup/leaveperiods",
  },
  HolidaySetup: {
    Post: "/api/Setup/createholidaysetup",
    Get: "/api/Setup/holidaysetup",
  },
  LeavePlanApprover: {
    Get: "/api/Setup/leaveplanapprover",
    Post: "/api/Setup/createnewleaveplanapprover",
    Delete: "​/api/Setup/leaveplanapprover/",
    Put: "​​/api/Setup/leaveplanapprover/",
    Roles: "/getroles",
  },
  Mdas: {
    Get: "/getmdas",
    GetUser:"/api/Setup/getuserbymda",
    // Post: "/api/Setup/createleaveplanapprover",
    // Delete: "​/api/Setup/leaveplanapprover/",
    // Put: "​​/api/Setup/leaveplanapprover/",
    // Roles: "/getroles",
  },
  LeaveRequestApprover: {
    Get: "/api/Setup/leaverequestapprover",
    Post: "/api/Setup/createleaverequestapprover",
    Delete: "/api​/Setup​/leaverequestapprover​/",
    Put: "/api​/Setup​/leaverequestapprover/",
    Roles: "/getroles",
  },

  LeaveComments: {
    planComment: '/getleaveplancomments',
    RequestComment: '/getleaverequestcomments'
  }
};
