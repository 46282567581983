import React, { Component } from "react";
import { IoMdClose } from "react-icons/io";
import { LoadingSpinner } from "../../resources/loadingSpinner";
import { URLAPI } from "../../utility/ApiMethods";
import { Post, Get } from "../../utility/fetch";
import moment from "moment";
import { IoIosSettings } from "react-icons/io";
//import TextareaInput from '../inputs/TextareaInput';
import SelectInput from "../inputs/SelectInput";
import TextInput from "../inputs/TextInput";

class RatingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grade: "",
      score: 0,
      modifiedBy: "",
      formErrors: {},
      errorMessage: "",
      button: "Save",
      className: "",
      class: "btn btn-large w-100",
      loading: false,
      visible: false,
      records: [],
      status: 1,
      ratingsId: 0,
      modifiedBy: 0,
      date: "09-Dec-2020 02:41:39 PM",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  openModal = (id) => {
    const selected = this.state.records.filter((item) => id === item.id);
    if (selected.length > 0) {
      this.setState({ visible: false, setSelectedRecord: selected[0] });
    }
  };

  closeModal = () => {
    this.setState({ visible: false, selectedRecord: [] });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    let error_data = "";

    if (this.state.grade === "") {
      error_data = "Enter grade";
    } else if (this.state.score === "") {
      error_data = "Enter score";
    }
    if (error_data !== "") {
      alert(error_data);
    } else if (error_data === "") {
      let rate = {
        id: this.state.ratingsid,
        grade: this.state.grade,
        score: this.state.score,
      };

      if (this.state.button === "Save") {
        this.onSubmitSave(rate);
      } else if (this.state.button === "Update") {
        this.onSubmitUpdate(rate);
      }
    }
  };

  onSubmitSave = (data) => {
    this.setState({ loading: true }, () => {
      Post(data, URLAPI.AppraisalRatingSetup.Post, this.onSubmitAfterSave);
      console.log(data);
    });
  };
  onSubmitAfterSave = (data) => {
    if (data.code === 1) {
      alert("Record Saved");

      //create array of data submitted
      let ratings = {
        id: data.ratingsId,
        grade: this.state.grade,
        score: this.state.score,
      };

      //clone the records in array
      let _records = [...this.state.records];

      //update the new record into the array
      _records.push(ratings);

      //commit the new record into array
      this.setState({
        records: _records,
        grade: "",
        score: "",
        loading: false,
      });
    } else if (data.code === 0) {
      alert(data.message);
      this.setState({ loading: false });
    } else {
      alert("Error saving data");
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        <div className="overlay">
          <IoMdClose className="close-btn pointer" onClick={this.closeModal} />
          <div className="modal-box max-w-400">
            <div className="content">
              <TextInput
                name="grade"
                value={this.state.grade}
                onChange={(e) => this.handleChange(e)}
                className="w-100 m-b-10"
                label="grade"
              />
              <input
                name="score"
                value={this.state.score}
                onChange={(e) => this.handleChange(e)}
                type="text"
                className="w-100 m-b-10"
                placeholder="score"
              />
              {loading ? (
                <LoadingSpinner />
              ) : (
                <button
                  type="button"
                  className={this.state.class}
                  onClick={this.onSubmit}
                >
                  {this.state.button}
                </button>
              )}
              <div className="form-group">
                <div className="text-center" style={{ color: "Red" }}>
                  {" "}
                  {this.state.errorMessage}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RatingsForm;
