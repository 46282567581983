import * as React from "react";
import moment from "moment";
import LocationDetails from "../layouts/LocationDetails";
import SearchInput from "../inputs/SearchInput";
import LeavePeriodModal from "../layouts/LeavePeriodModal";
import LoadingSpinner from "../../resources/loadingSpinner";
import EditLeavePeriod from "../layouts/EditLeavePeriod";
import AreYouSure from "../layouts/AreYouSure";
import Cookies from "js-cookie";

// Api
import { Get, Put, Delete } from "../../utility/fetch";
import { URLAPI } from "../../utility/ApiMethods";

// icons
import { RiAddLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { GiTrashCan } from "react-icons/gi";

const tableHead = {
  padding: "8px"
};

const tableContent = {
  whiteSpace: "nowrap",
  textAlign: "left"
};

const CreateLeavePeriod = (props) => {
  const [leavePeriod, setLeavePeriod] = React.useState({
    loading: true,
    period: [],
    error: null
  });
  const [showModal, setShowModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [currentPeriod, setCurrentPeriod] = React.useState({});
  const [confirmDelete, setConfirmDelete] = React.useState(false);

  const closeModal = () => {
    setShowModal(false);
    setEditModal(false);
    setDeleteModal(false);
  };

  const deleteLeavePeriod = (data) => {
    console.log("Logging the data of the leave to delete", data);
    setConfirmDelete(false);
    closeModal();
    Get(URLAPI.LeavePeriodSetup.Get, getLeavePeriod);
  };

  const handleDeleteBtn = (id) => {
    confirmDelete === true && Delete(`${URLAPI.LeavePeriodSetup.Delete}${id}`, deleteLeavePeriod);
  };

  const getLeavePeriod = (data) => {
    console.log("Logging leave periods gotten", data);

    data && data.result
      ? setLeavePeriod({
          ...leavePeriod,
          loading: false,
          period: [...data.result]
        })
      : setLeavePeriod({
          ...leavePeriod,
          loading: false,
          error: data.message
        });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Leave Management";

    Get(URLAPI.LeavePeriodSetup.Get, getLeavePeriod);
  }, []);
  return (
    <div className="w-100">
      {leavePeriod.loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {!leavePeriod.error ? (
            <>
              <div className="w-100 flex space-between flex-v-center">
                <h2>Leave Period</h2>
                {/* <LocationDetails /> */}
              </div>
              <div className="w-100 m-t-20 flex space-between flex-v-center">
                {/* <h4 className="san-sherif">Create Leave Period</h4> */}
                <h4 className="san-sherif"></h4>
                <div>
                  <form>
                    <SearchInput />
                  </form>
                </div>
              </div>
              <div className="table-view m-t-10">
                <div className="table-header">
                  <div className="table-row">
                    <div className="th" style={{ width: "25%", ...tableHead }}>
                      <p className="san-sherif">Items</p>
                    </div>
                    <div className="th" style={{ ...tableHead }}>
                      <p className="san-sherif">Start Date</p>
                    </div>
                    <div className="th" style={{ ...tableHead }}>
                      <p className="san-sherif">End Date</p>
                    </div>
                    <div className="th" style={{ ...tableHead }}>
                      <p className="san-sherif">Last Updated</p>
                    </div>
                    <div className="th" style={{ ...tableHead }}>
                      <p className="san-sherif">Last Updated By:</p>
                    </div>
                    <div className="th" style={{ ...tableHead }}>
                      <button
                        className="add-button pointer white-space-nowrap"
                        type="button"
                        onClick={() => setShowModal(true)}>
                        <RiAddLine />
                        Add Leave Period
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-body">
                  {leavePeriod.period.map(
                    ({ sessionYear, startDate, endDate, modifiedAt, modifiedBy, id }) => (
                      <div className="table-row" key={id}>
                        <div className="td" style={{ ...tableContent }}>
                          {sessionYear}
                        </div>
                        <div className="td" style={{ ...tableContent }}>
                          {moment(`${startDate}`).format("MMMM Do, yyyy")}
                        </div>
                        <div className="td" style={{ ...tableContent }}>
                          {moment(`${endDate}`).format("MMMM Do, yyyy")}
                        </div>
                        <div className="td" style={{ ...tableContent }}>
                          {moment(`${modifiedAt}`).format("YYYY:MM:DD/ HH:mm")}
                        </div>
                        <div className="td" style={{ ...tableContent }}>
                          {Cookies.get("fullname")}
                        </div>
                        <div className="td actions">
                          <button
                            type="button"
                            className="pointer tooltip"
                            onClick={() => {
                              setCurrentPeriod({
                                id,
                                sessionYear,
                                startDate,
                                endDate,
                                modifiedAt,
                                modifiedBy
                              });
                              setEditModal(true);
                            }}>
                            <BiEditAlt />
                            <span className="tooltiptext">Edit</span>
                          </button>
                          <button
                            type="button"
                            className="m-l-10 pointer del tooltip"
                            onClick={() => {
                              setCurrentPeriod({
                                title: "Leave Duration",
                                name: sessionYear,
                                id
                              });
                              setDeleteModal(true);
                            }}>
                            <GiTrashCan />
                            <span className="tooltiptext">Delete</span>
                          </button>
                        </div>
                      </div>
                    )
                  )}
                </div>
                {showModal ? (
                  <LeavePeriodModal
                    closeModal={closeModal}
                    setLeavePeriod={setLeavePeriod}
                    leavePeriod={leavePeriod}
                  />
                ) : editModal ? (
                  <EditLeavePeriod
                    closeModal={closeModal}
                    setLeaveDetails={setLeavePeriod}
                    leaveDetails={leavePeriod}
                    editLeave={currentPeriod}
                  />
                ) : deleteModal ? (
                  <AreYouSure
                    closeModal={closeModal}
                    currentPeriod={currentPeriod}
                    setConfirmDelete={setConfirmDelete}
                    handleDeleteBtn={handleDeleteBtn}
                    confirmDelete={confirmDelete}
                  />
                ) : null}
              </div>
            </>
          ) : (
            <div
              className="flex flex-v-center flex-h-center bolder-text"
              style={{ color: "red", fontSize: "1.2rem" }}>
              {leavePeriod.error}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CreateLeavePeriod;
