/* eslint-disable max-len */
import React, { Component, useEffect } from "react";
import Cookies from "js-cookie";
import { location } from "../../utility/Location";
import { store } from "react-notifications-component";
import querySearch from "stringquery";
import moment from "moment";
// Api Methods
import { NewLogin } from "../../utility/fetch";
import { URLAPI } from "../../utility/ApiMethods";
import LoadingSpinner from "../../resources/loadingSpinner";

let fromDashboard = "";

class Home extends Component {
  

  handleSignin = () => {
    let queryString = querySearch(this.props.location.search);

    const urltoken = queryString.emp;
    Cookies.set("fromDashboard", queryString.fromdashboard)  
    fromDashboard = queryString.fromdashboard;
    Cookies.set("type", queryString.type) 

    Cookies.set("requestid", queryString.requestid)
    
    console.log("Logging QueryId", urltoken);

    NewLogin(URLAPI.Auth.Post, { urltoken }, this.getToken);
  };
  componentDidMount() {
    // location();
    Cookies.set("loginTime", moment().format("HH:mm a"));
    this.handleSignin();
  }

  // componentDidUpdate() {
  //   // location();
  //   Cookies.set("loginTime", moment().format("HH:mm a"));
  //   this.handleSignin();
  // }

  getToken = async (result) => {
    const { history } = this.props;
    console.log("token", result);
    this.setState({
      ...this.state,
      token: result && result.result && result.result.token,
      fullname: result && result.result && result.result.fullName,
      role: result && result.result && result.result.role,
      
      result: result && !result.result && result.message,
    });

    if (result && result.result) {
      console.log(result.result.fullName)
      Cookies.set("homeLink",result.result.homeLink);
      Cookies.set("userId",result.result.id);
      
      Cookies.set("mda", result.result.mdaName);
      Cookies.set("mdaAddress", result.result.mdaAddress);
      Cookies.set("token", this.state.token, { expires: 0.0416665 });
      localStorage.setItem("token", this.state.token, { expires: 0.0416665 });
      Cookies.set("tokenExist", true, { expires: 0.0416665 });
      Cookies.set("fullname", result.result.fullName)
      Cookies.set("role", this.state.role, {
        expires: 0.0416665,
      });
      store.addNotification({
        message: "Welcome To Leave Management",
        type: "success",
        insert: "bottom",
        container: "bottom-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else {
      Cookies.set("token", null);
      Cookies.set("tokenExist", false);
      Cookies.set("fullname", null);
      Cookies.set("role", null);
      store.addNotification({
        title: "Error!",
        message: "Something Went Wrong!",
        type: "danger",
        insert: "bottom",
        container: "bottom-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      const timerId = setTimeout(() => {
        history.push("/login");
        clearTimeout(timerId);
      }, 3000);
    }
    console.log("Logging location cookie", Cookies.get("location"));


    if( this.state.token &&
      Cookies.get("tokenExist") === "true" &&
      Cookies.get("fullname") !== undefined &&
      fromDashboard
      ) {
      history.push("/admin-portal/leave-request-review");
    }
    else if (
      this.state.token &&
      Cookies.get("tokenExist") === "true" &&
      Cookies.get("fullname") !== undefined
    ) {
      console.log("Account Name", Cookies.get("fullname"));
      history.push("/admin-portal/");
    }
  };



  render() {
    return (
      <div
        className="w-100 flex flex-v-center flex-h-center"
        style={{ minHeight: "105vh" }}
      >
        <LoadingSpinner />
      </div>
    );
  }
}

export default Home;
