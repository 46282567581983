import * as React from "react";
import Accordion from "./Accordion";
import CheckboxInput from "../inputs/CheckboxInput";
import FormSearchInput from "../inputs/FormSearchInput";
import TextInput from "../inputs/TextInput";
import TextareaInput from "../inputs/TextareaInput";
import UploadButton from "../inputs/UploadButton";
import MfilesDoc from "../inputs/MfilesDoc";
import { GiTrashCan } from "react-icons/gi";
import SuccessModal from "../layouts/SuccessModal";
import ErrorModal from "../layouts/ErrorModal";
import notification from "../../utility/notification";
import LoaderSrc from "../../assets/svg/loading.svg";
import Select from 'react-select'
import cookies from "js-cookie";
import axios from 'axios';
// Api
import { Get, Post, GetServices, GetwithQueryString, GetwithMultipleQueryString } from "../../utility/fetch";
import { URLAPI } from "../../utility/ApiMethods";

const BASE_API_URL = `https://edogoverp.com/services/api`;

const LeaveApply = ({getLeavePlan, ...props}) => {

  const [employeeName, setemployeeName] = React.useState("");
  const [approverId, setApproverId] = React.useState(null);
  const [approvers, setApprovers] = React.useState([]);
  const [recipientMda, setrecipientMda] = React.useState("");
  const [mdaList, setMdaList] = React.useState([]);
  const [employees, setEmployees] = React.useState([]);



  const [leavePlan, setLeavePlan] = React.useState({
    loading: true,
    data: [],
    error: null,
  });
  const [reliefOfficers, setReliefOfficers] = React.useState([]);
  const [reliefOfficerID, setReliefOfficerID] = React.useState(null);
  const [reliefOfficerPlan, setReliefOfficerPlan] = React.useState([]);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  let [count, setCount] = React.useState(0);
  const [mFilesArray, setMFilesArray] = React.useState([]);
  const [empty, setEmpty] = React.useState([]);
  const [mdaId, setMdaId] = React.useState(0);
  const [mdas, setMdas] = React.useState("");
  
  const [mdaDetails, setMdaDetails] = React.useState({
    mdaId:0,
    mdaName:""
  })
  const [loading, setLoading] = React.useState(false);
  const [docHolder, setDocHolder] = React.useState([]);
  const [leaveApply, setLeaveApply] = React.useState({
    reason: "",
    handOverNote: "",
    reliefOfficerName: "",
    payLeaveAllowance: false,
    document: "",
  });

  const getMdas = (data) => {
    console.log("logging getMdas", data);
    data ? setMdas(data) : setMdas([]);
  };

  const fetchEmployees = (id) => {
    console.log(mdaId)
    GetServices(
      `/api/Employees/employees_by_mda?mdaid=${id}`,
      getReliefOfficerNames
    );

  }

  const handleApproverId = (e) => {
    setApprovers(e);
    console.log(e);
  };

  const getEmployeesFromMda = (id) => {
    axios
      .get(`${BASE_API_URL}/Employees/employees_by_mda?mdaid=${id}`)
      .then((response) => {
        console.log("--employeesByMDAs--");
        console.log(response?.data);
        // this.setState({
        const roleList = response?.data.map((item) => ({
          name: `${item.name}`,
          value: item.id,
        }));
        setEmployees(
          roleList.filter((item) => {
            return !item.name.includes(cookies.get("fullname"));
          })
        );

        // setMdaList(roleList)

        // });
      });
  };


  React.useEffect(() => {
    getApproversMDAs();
    GetServices(
      "/api/Employees/mdas",
      getMdas
    );
  },[])

  React.useEffect(()=>{
    fetchEmployees(mdaDetails.value)
  }, [mdaDetails])

  const getApproversMDAs = () => {
    axios.get(`${BASE_API_URL}/Employees/mdas`).then((response) => {
      console.log("--mdas--");
      console.log(response?.data);
      // this.setState({
      const roleList = response?.data.map((item) => ({
        name: `${item.name}`,
        value: item.id,
      }));
      setMdaList(roleList);

      // });
    });
  };

  
  React.useEffect(() => {
    console.log(approverId);
    console.log(approvers);
    setApproverId(
      approvers.map((item) => ({
        id: 0,
        roleId: 0,
        approverId: item.value,
        sequence: 0,
        approverName: item.label
        
      }))
    );
  }, [approvers]);

  const getReliefOfficerNames = (data) => {
    console.log("logging getReliefOfficerNames", data);

    data && data
      ? setReliefOfficers(data)
      : setReliefOfficers([]);
  };
  const closeModals = () => {
    setShowSuccess(false);
    props.closeMyself(false);
    setShowError(false);
  };
  const submitLeave = (data) => {
    if (!leaveApply.reason) {
      notification({
        title: "Leave Apply Error!",
        message: `Please Add a Leave Reason`,
        type: "danger",
      });
    } else if (!leaveApply.reliefOfficerName) {
      notification({
        title: "Leave Apply Error!",
        message: `Please Select a Relief Officer`,
        type: "danger",
      });
    } else if (!leaveApply.handOverNote) {
      notification({
        title: "Leave Apply Error!",
        message: `Please input a hand over note`,
        type: "danger",
      });}
      else if (employeeName.length < 1) {
        notification({
          title: "Leave Apply Error!",
          message: `Please select one or more approvers`,
          type: "danger",
        });
    } else if (
      leaveApply.reason &&
      leaveApply.reliefOfficerName &&
      leaveApply.handOverNote
    ) {
      Post(
        {
          id: props.currentId,
          leaveStatus: 1,
          leaveReason: leaveApply.reason,
          handoverNote: leaveApply.handOverNote,
          reliefOfficerId: reliefOfficerID,
          approvers:approverId
        },
        URLAPI.LeavePlan.submit,
        (response) => {
          console.log("Approve response", response);

          setLeaveApply({
            reason: "",
            handOverNote: "",
            reliefOfficerName: "",
            payLeaveAllowance: false,
          });
          setemployeeName("");
          setrecipientMda("");
          if (response) {
            if (response.statusCode === "00") {
              setLoading(false);
              GetwithMultipleQueryString(URLAPI.LeavePlan.Get,{page:1, size:10}, getLeavePlan);
              Get(URLAPI.LeavePlan.Get, (data) => {
                if (data) {
                  if (data.result) {
                    if (data.result.length > 0) {
                      props.setLeaveRequest({
                        loading: false,
                        data: [...data.result],
                      });
                      props.setLeaveRequest({
                        ...props.leaveRequest,
                        data: props.leaveRequest.data.filter(
                          (req) => req.id !== props.currentId
                        ),
                      });
                    } else {
                      props.setLeaveRequest({
                        ...props.leaveRequest,
                        loading: false,
                        data: [],
                      });
                    }
                  }
                }
              });
              setShowSuccess(true);
            } else if (response.statusCode === "01") {
              setLoading(false);
              setErrorMsg(response.apiMessage);
              Get(URLAPI.LeavePlan.Get, (data) => {
                if (data) {
                  if (data.result) {
                    if (data.result.length > 0) {
                      props.setLeaveRequest({
                        ...props.leaveRequest,
                        loading: false,
                        data: [...data.result],
                      });
                    } else {
                      props.setLeaveRequest({
                        ...props.leaveRequest,
                        loading: false,
                        data: [],
                      });
                    }
                  }
                }
              });
              setShowError(true);
            }
          }
        }
      );
      setLoading(true);
    }
  };

  const selectReliefOfficer = (id) => {
    // setLeaveApply({ ...leaveApply, loading: true });
    GetwithQueryString(
      URLAPI.LeavePlan.selectReliefOfficer,
      { name: "ReliefOfficerid", value: id },
      (data) => {
        data && data.result
          ? setReliefOfficerPlan([...data.result])
          : setReliefOfficerPlan([]);
        // data && data.result
        //   ? setLeaveApply({ ...leaveApply, loading: false })
        //   : setLeaveApply({ ...leaveApply, loading: true });
      }
    );
  };

  const mFilesFunc = (val)=>{
    setDocHolder(val)
  }
  const uploadFilesFunc = (val)=>{
    console.log(val)
    holdValues(val.docName,val.docPath)
    // setMFilesArray([...mFilesArray,val])

    // uploadFiles(val)
  }

  const handleDeleteMFile = (y) => {
    let pp = mFilesArray?.filter((x,index)=>(
      x.key!==y))
     setMFilesArray(pp)
    
   };

 const  holdValues = (filename, fileurl)=>{
    let holder = {
      key:count,
      docPath:fileurl,
      docName:filename
    }
  
    setMFilesArray([...mFilesArray,holder])
      setCount(count++) 
      setDocHolder([])
    
    // this.setState({docList:[]})
    // console.log(this.state.imageArray)
    }

  React.useEffect(() => {}, []);
  return (
    <div className="w-100 flex m-t-5">
      <div
        className="col-4"
        style={{
          backgroundColor: "white",
          boxShadow: "0px 2px 6px #0000000A",
          padding: "1rem .7rem",
        }}
      >
        <form>
          {/* <CheckboxInput
            label="Pay Leave Allowance"
            name="payLeaveAllowance"
            className="m-b-10"
            value={leaveApply.payLeaveAllowance}
            onChange={(e) =>
              setLeaveApply({
                ...leaveApply,
                payLeaveAllowance: e.target.checked,
              })
            }
          /> */}
          <TextInput
            label="Reasons*"
            name="reason"
            value={leaveApply.reason}
            onChange={(e) =>
              setLeaveApply({
                ...leaveApply,
                reason: e.target.value,
              })
            }
          />

<div >
                    <FormSearchInput
                      label={"Relief Officer's MDA*"}
                      placeholder="Select MDA..."
                      value={mdaDetails.mdaName}
                      onChange={(e) => {
                        setMdaDetails({
                          ...mdaDetails,
                          mdaName: e.target.value
                        });
                        GetServices(
                          "/api/Employees/mdas",
                          getMdas
                        );
                      }}
                      displayType="roles"
                      setStateValue={setMdaDetails}
                      formState={mdaDetails}
                      stateName={"mdaName"}
                      searchValue={mdas}
                      setSuggestions={setMdas}
                      selectedId={setMdaId}
                      selectReliefOfficer={fetchEmployees}
                    />
                  </div>
                  <div >
                    <FormSearchInput
                      label={"Relief Officer*"}
                      placeholder="Relief Officer Name....."
                      value={leaveApply.reliefOfficerName}
                      onChange={(e) => {
                        setLeaveApply({
                          ...leaveApply,
                          reliefOfficerName: e.target.value,
                        });
                       
                      }}
                      displayType="roles"
                      setStateValue={setLeaveApply}
                      formState={leaveApply}
                      stateName={"reliefOfficerName"}
                      searchValue={reliefOfficers}
                      setSuggestions={setReliefOfficers}
                      selectedId={setReliefOfficerID}
                      selectReliefOfficer={selectReliefOfficer}
                    />
                  </div>
                  <div>Approver's MDA*</div>
                  <Select
                        placeholder="Select Approvers MDA"
                        // isMulti
                        className="basic-multi-select"
                        onChange={(value) => {
                          console.log(value);
                          // this.setState({ employeeName: e.target.value });
                          setrecipientMda(value);
                          getEmployeesFromMda(value.value);
                          // this.setState({recipientMda:value});
                        }}
                        value={recipientMda}
                        options={mdaList.map((m) => ({
                          label: m.name,
                          value: m.value,
                        }))}
                      />
                  <div>Approver's Name*</div>
                  <Select
                        placeholder="Select Approver"
                        // isMulti
                        className="basic-multi-select m-t-10 m-b-10"
                        onChange={(value) => {
                          console.log(value.value);
                          setemployeeName(value);
                          handleApproverId(value);
                          // handleApproverId(value)
                          // setEmployeeList(value.value);
                          // this.setState({ employeeList: value });

                          // this.getEmployeesFromMda(value.value)
                          // this.setState({recipientMda:value});
                        }}
                        // onChange={(e)=>{handleApproverId(e)}}
                        // onInputChange={(e) => {
                        //   handleApprovers(e);
                        // }}
                        isMulti
                        value={employeeName}
                        options={employees?.map((m) => ({
                          label: m.name,
                          value: m.value,
                        }))}
                      />
          {/* <FormSearchInput
            placeholder="Relief Officer Name....."
            label="Relief Officer"
            className="m-t-10"
            color="rgba(112,112,112,.7)"
            value={leaveApply.reliefOfficerName}
            onChange={(e) => {
              // handleChange(e);
              setLeaveApply({
                ...leaveApply,
                reliefOfficerName: e.target.value,
              });
              GetwithQueryString(
                URLAPI.LeavePlan.staffList,
                {
                  name: "name",
                  value: e.target.value,
                },
                getReliefOfficerNames
              );
            }}
            displayType="names"
            setStateValue={setLeaveApply}
            formState={leaveApply}
            stateName={"reliefOfficerName"}
            searchValue={reliefOfficers}
            setSuggestions={setReliefOfficers}
            selectedId={setReliefOfficerID}
            selectReliefOfficer={selectReliefOfficer}
          /> */}
          <TextareaInput
            className="m-t-10 m-b-20"
            label="HandOver Notes*"
            value={leaveApply.handOverNote}
            onChange={(e) =>
              setLeaveApply({
                ...leaveApply,
                handOverNote: e.target.value,
              })
            }
          />
          {/* <div>
            <UploadButton
              formState={leaveApply}
              setFormState={setLeaveApply}
              stateName={"document"}
              uploadFilesFunc = {uploadFilesFunc}
            />
          </div>
          <div>
            <MfilesDoc
            mFilesFunc = {mFilesFunc}
            setEmpty = {empty}
            />
          </div> */}


          {/* <div>
                        {docHolder?.map((x,index)=>(
                          <div className='deletezz' key ={x.key}  style={{padding:"5px", border:"2px solid  #c6c6c6", display:"flex", justifyContent:"space-between", alignItems:"center"}} onClick={()=>{

                            holdValues(x.title,x.fileurl)
                            setEmpty(!empty)
                            
                            }}>
                            <p>
                              {x.title}
                            </p>
                           </div>
                        ))}
                         <div >
                          <p style={{paddingTop:"10px"}}>Mfiles selected documents</p>
                          {mFilesArray?.map((x)=>(
                            <div className='deletez' key ={x.key} style={{padding:"5px", border:"2px solid #c6c6c6", display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:"5px"}} onClick={()=>{
  
                              
                              
                              }}>
                              <a href={x.docPath}>
                                {x.docName}
                              </a>
                              <div  onClick={()=>handleDeleteMFile(x.key)}><GiTrashCan style={{fontSize:"16px"}}  /></div>
                             </div>
                          ))}
                        </div> 
                      </div> */}



          <div className="row space-around m-t-20">
            {/* <button className="btn btn-large w-45" type="button">
              Save
            </button> */}
            <button
              className="flex btn btn-large w-98 flex  flex-v-center flex-h-center justify-content-center"
              type="button"
              disabled={loading}
              onClick={() => {
                console.log("Leave Apply", leaveApply);

                submitLeave();
              }}
              style={{ height: "43px" }}
            >
              <p style={{ margin: "auto 15px auto" }}>
                {loading ? "Submitting" : "Submit"}
              </p>
              {loading && (
                <img src={LoaderSrc} className="btn-loading" alt="Loading..." />
              )}
            </button>
          </div>
        </form>
      </div>
      <div className="col-8 m-b-10">
        {/* {leavePlan.map(({ id, title, content }) => (
          <Accordion title={title} key={id} content={content} />
        ))} */}

        <Accordion
          title="Leave Plan Details"
          content={
            reliefOfficerPlan.length > 0
              ? reliefOfficerPlan
              : "You Have no Leave Plan Yet"
          }
        />
      </div>

      {showSuccess && (
        <div
          className="overlay flex"
          style={{
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0px 2px 6px #0000000A",
          }}
        >
          <SuccessModal
            action={"Leave Request Submitted Successfully"}
            closeModal={() => closeModals()}
          />
        </div>
      )}
      {showError && (
        <div
          className="overlay flex"
          style={{
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0px 2px 6px #0000000A",
          }}
        >
          <ErrorModal
            action={errorMsg}
            closeModal={() => setShowError(false)}
          />
        </div>
      )}
    </div>
  );
};

export default LeaveApply;
