import React, { useEffect, useState, Component } from "react";
import { RiAddLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { GiTrashCan } from "react-icons/gi";
import LocationDetails from "../layouts/LocationDetails";
import RatingForm from "../layouts/RatingForm";
import Cookie from "js-cookie";
import { URLAPI } from "../../utility/ApiMethods";
import { Get } from "../../utility/fetch";

//const access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2ODJjOGM3Ni0wMGRiLTRmNjYtOGI1OS1mMjc5ZTc4NjJkNzkiLCJ2YWxpZCI6IjEiLCJ1c2VyaWQiOiIxIiwiZmlyc3RuYW1lIjoiQXBwcmFpc2FsIEZpcnN0bmFtZSIsImxhc3RuYW1lIjoiQXBwcmFpc2FsIExhc3RuYW1lIiwiZW1haWwiOiJBcHByYWlzYWwgRW1haWwiLCJleHAiOjE2MDc1MDkwMDUsImlzcyI6Imh0dHA6Ly8xOC4yMjUuMTUuMTc4LyIsImF1ZCI6Imh0dHA6Ly8xOC4yMjUuMTUuMTc4LyJ9.r9kJib-IYWqiyb0wnS-cLx1c7GLi3FSHwjzcKVGveg8'

class Rate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      AppraisalRatingSetup: "",
      visible: false,
      loading: false,
      loadingData: false,
      selectedRecord: "",
    };
  }

  /*
    showEditForm = (id, records) => {
      const selected = records.filter((item) => id === item.id);
      if (selected.length > 0) {
        this.setState({
          ShowFormModal: true,
          SelectedRecord: selected[0]
        })
      }
    };
  */
  openModal = (id) => {
    const selected = this.state.records.filter((item) => id === item.id);
    if (selected.length > 0) {
      this.setState({ visible: true, setSelectedRecord: selected[0] });
    }
  };

  closeModal = () => {
    this.setState({ visible: false, selectedRecord: [] });
  };

  fetchData = (data) => {
    let _records = data.data;
    this.setState({ records: _records, loadingData: false, visible: false });
    console.log(this.state.records);
  };

  componentDidMount() {
    this.setState({ loadingData: true, visible: false }, () => {
      Get(URLAPI.AppraisalRatingSetup.Get, this.fetchData);
    });
  }

  render() {
    return (
      <>
        <div className="w-100">
          <div className="w-100 flex space-between flex-v-center">
            {/* <LocationDetails /> */}
          </div>
          <div className="w-100 flex space-between flex-v-center m-t-20">
            <h4 className="san-sherif">Rating</h4>
          </div>
          <div className="table-view m-t-10">
            <div className="table-header">
              <div className="table-row">
                <div className="th">
                  <p className="san-sherif">Grade</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Score</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Last Updated</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Last Updated By</p>
                </div>
                <div className="th">
                  <button
                    className="add-button pointer"
                    type="button"
                    onClick={() => this.openModal(this.visible)}
                  >
                    <RiAddLine />
                    Add Rating
                  </button>
                </div>
              </div>
            </div>
            <div className="table-body">
              {this.state.records.map((m) => (
                <div className="table-row" key={m.id}>
                  <div className="td">{m.grade}</div>
                  <div className="td">{m.score}</div>
                  <div className="td">{m.modifiedBy}</div>
                  <div className="td">{m.date}</div>
                  <div className="td actions">
                    <button
                      type="button"
                      className="pointer"
                      onClick={() => this.openModal(m.id)}
                    >
                      <BiEditAlt />
                    </button>
                    <button type="button" className="m-l-10 del pointer">
                      <GiTrashCan />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {this.openModal && (
            <RatingForm
              details={this.state.selectedRecord}
              onClick={() => this.closeModal()}
            />
          )}
        </div>
      </>
    );
  }
}

export default Rate;
