import * as React from "react";
import moment from "moment";
import LocationDetails from "../layouts/LocationDetails";
import LeaveApply from "../layouts/LeaveApply";
import LoadingSpinner from "../../resources/loadingSpinner";
// import CommentModal from "../layouts/CommentModal";

// Api

import { URLAPI } from "../../utility/ApiMethods";

// Icons
// import { BiEditAlt } from "react-icons/bi";
import SuccessModal from "../layouts/SuccessModal";
import axios from "axios";

import SelectInput from "../inputs/SelectInput";
import FormDateInput from "../inputs/FormDateInput";
import TextAreaInput from "../inputs/TextareaInput";
import FormSearchInput from "../inputs/FormSearchInput";
import { BiEditAlt } from "react-icons/bi";
import { GiPaperClip, GiTrashCan } from "react-icons/gi";
import Accordion from "../layouts/Accordion";
import cookies from "js-cookie";
import AreYouSure from "../layouts/AreYouSure";
import Popup from "../layouts/Popup";

import notification from "../../utility/notification";
import ErrorModal from "../layouts/ErrorModal";
import Loader from "../../assets/svg/loading.svg";
import { getLeaveDays } from "../../utility/general";
import { Get, GetwithQueryString, Post, GetServices, Delete, GetwithMultipleQueryString } from "../../utility/fetch";
import Select from 'react-select';

import { IoMdClose } from 'react-icons/io';
import UploadButton from "../layouts/UploadButton";
import { HiPaperClip } from "react-icons/hi";


const BASE_API_URL = `https://edogoverp.com/services/api`;



const tableHead = {
  padding: "15px",
  textAlign: "left"
};

const tableContent = {
  whiteSpace: "nowrap",
  textAlign: "left"
};

const centerContent = {
  textAlign: "left"
};

const submitBtn = {
  backgroundColor: "#caddc1",
  padding: "0 0.8rem",
  cursor: "pointer",
  borderRadius: "70px",
  marginLeft: "auto"
};

const submitText = {
  display: "table-cell",
  fontSize: "13px",
  color: "#43425d"
};

const LeaveRequest = (props) => {
  const [leaveRequest, setLeaveRequest] = React.useState({
    loading: false,
    data: [],
    error: null
  });
  const [showLeaveApply, setShowLeaveApply] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(false);
  const [leaveSession, setLeaveSession] = React.useState({
    loading: false,
    data: [],
    error: null
  });
  const [leaveSessionCont, setLeaveSessionCont] = React.useState({
    loading: false,
    data: [],
    error: null
  });
  const [reliefOfficers, setReliefOfficers] = React.useState([]);
  const [leaveTypeID, setLeaveTypeID] = React.useState(null);
  const [reliefOfficerID, setReliefOfficerID] = React.useState(null);
  const [daysSelected, setDaysSelected] = React.useState(0);
  const [success, setSuccess] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('')
  const [modalOpen, setModalopen] = React.useState(false)
  const [action, setAction] = React.useState("successfully Submitted for Approval");
  const [leavePlan, setLeavePlan] = React.useState({
    loading: false,
    data: [],
    error: null
  });
  // const [accordionDetails, setAccordionDetails] = React.useState([]);
  const [leaveType, setLeaveType] = React.useState([]);
  const [leaveType2, setLeaveType2] = React.useState(props.leaveTypes);
  const [leaveTypeName, setLeaveTypeName] = React.useState("");
  const [officerPlan, setOfficerPlan] = React.useState({
    loading: false,
    data: []
  });
  const [dateselected, setDateselected] = React.useState({
    loading: false,
    data: [],
    error: null
  });
  const [createLeavePlan, setCreateLeavePlan] = React.useState({
    leaveName: "",
    startDate: "",
    endDate: "",
    reliefOfficerName: "",
    document: ""
  });
  const [reliefOfficerPlan, setReliefOfficerPlan] = React.useState([]);
  const [reliefOfficerTo, setReliefOfficerTo] = React.useState({
    loading: false,
    data: [],
    error: null
  });
  const [showPopup, setShowPopup] = React.useState(false);
  const [currentLeavePlan, setCurrentLeavePlan] = React.useState({});
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [handoverNote, setHandoverNote] = React.useState(null)
  const [leaveReason, setLeaveReason] = React.useState(null)
  const [employeeName, setemployeeName] = React.useState("");
  const [approverId, setApproverId] = React.useState(0);
  const [approvers, setApprovers] = React.useState([]);
  const [recipientMda, setrecipientMda] = React.useState("");
  const [mdaList, setMdaList] = React.useState([]);
  const [employees, setEmployees] = React.useState([]);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const closePopup = () => {
    setShowPopup(false);
    setSuccess(false);
  };
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const closeSuccessModal = () => setSuccess(false);
  const [mdaId, setMdaId] = React.useState(0);
  const [mdas, setMdas] = React.useState("");
  const [mdaDetails, setMdaDetails] = React.useState({
    mdaId: 0,
    mdaName: ""
  })



  // React.useEffect(() => {
  //   console.log(createLeavePlan.leaveName)
  //   console.log(leaveType2)
  //   console.log(createLeavePlan.endDate)
  // }, [createLeavePlan])


  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Leave Plan";

    Get(URLAPI.LeavePlan.leaveSession, getLeaveSession);



    GetwithQueryString(URLAPI.LeaveType.Get2, { name: 'empId', value: parseInt(cookies.get("userId")) }, getLeaveType2,);
    GetwithMultipleQueryString(URLAPI.LeavePlan.Get, { page: 1, size: 10 }, getLeavePlan);
    Get(URLAPI.LeavePlan.reliefTo, (data) => {
      console.log("Relief Officer to", data, data.result);
      data && data.result
        ? setReliefOfficerTo({
          ...reliefOfficerTo,
          data: [...data.result],
          loading: false,
          error: null
        })
        : data.response &&
        setReliefOfficerTo({
          ...reliefOfficerTo,
          data: [],
          loading: false,
          error: data.response.data.apiMessage
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const recImagg = (data) => {
    console.log(data);
    setCreateLeavePlan({
      ...createLeavePlan,
      document:data?.docPath
    }
      )
  }

  const getLeaveType2 = (data) => {
    const selectOption = [];
    console.log("Logging the Leave type data", data);
    data &&
      data.result &&
      data.result.filter(el => el.leaveName !== 'Annual').forEach(({ leaveName, leaveCategory, id }) => {
        selectOption.push({ name: leaveName, value: leaveCategory, id });
      });

    setLeaveType2(selectOption);
  };

  console.log("Logging the create leave plan", createLeavePlan);

  const getLeaveSession = (data) => {
    console.log("Leave Session Data", data);

    data && data.result
      ? setLeaveSession({
        ...leaveSession,
        loading: false,
        data: { ...data.result }
      })
      : setLeaveSession({
        ...leaveSession,
        loading: false,
        error: data.message
      });
  };


  const getLeaveSessionCont = (data) => {
    console.log("Leave SessionCont Data", data);

    if (data) {
      if (data.result) {
        data.result.result &&
          setLeaveSessionCont({
            ...leaveSessionCont,
            loading: false,
            data: { ...data.result.result }
          });
      } else {
        setLeaveSessionCont({
          ...leaveSession,
          loading: false,
          error: data.message
        });
      }
      setLeaveSession({ ...leaveSession, loading: false });
    }
  };

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const hanldleLeavePlanPaginationChange = (page, pageSize) => {
    GetwithMultipleQueryString(URLAPI.LeavePlan.Get, { page: page, size: 10 }, getLeavePlan);
  }

  // const hanldleLeaveRequestPaginationChange=(page , pageSize)=>{
  //   GetwithMultipleQueryString(URLAPI.LeaveRequest.Get,{page:page, size:10}, getLeaveRequest);
  // }


  const handleApproverId = (e) => {
    setApprovers(e);
    console.log(e);
  };

  React.useEffect(() => {
    console.log(approverId);
    console.log(approvers);
    setApproverId(
      approvers.map((item) => ({
        id: 0,
        createdAt: "2023-02-11T16:56:17.231Z",
        modifiedAt: "2023-02-11T16:56:17.231Z",
        status: 0,
        createdBy: 0,
        modifiedBy: 0,
        actionTaken: "string",
        roleId: 0,
        role: "string",
        approverId: item.value,
        approverName: "",
        approvalSequence: 0,
        leaveRequestId: 0,
        comment: "string"
      }))
    );
  }, [approvers]);


  React.useEffect(() => {
    getApproversMDAs();
  }, [])

  const getApproversMDAs = () => {
    axios.get(`${BASE_API_URL}/Employees/mdas`).then((response) => {
      console.log("--mdas--");
      console.log(response?.data);
      // this.setState({
      const roleList = response?.data.map((item) => ({
        name: `${item.name}`,
        value: item.id,
      }));
      setMdaList(roleList);

      // });
    });
  };

  const getEmployeesFromMda = (id) => {
    axios
      .get(`${BASE_API_URL}/Employees/employees_by_mda?mdaid=${id}`)
      .then((response) => {
        console.log("--employeesByMDAs--");
        console.log(response?.data);
        // this.setState({
        const roleList = response?.data.map((item) => ({
          name: `${item.name}`,
          value: item.id,
        }));
        setEmployees(
          roleList.filter((item) => {
            return !item.name.includes(cookies.get("fullname"));
          })
        );

        // setMdaList(roleList)

        // });
      });
  };






  const inputStyle = {
    margin: "10px 0"
  };

  const labelStyle = {
    color: "rgba(112,112,112,.8)"
  };

  const checkIsRequestSave = (data) => {
    const { statusCode } = data;
    console.log("Logging to check if request is saved", data);

    if (data && statusCode === "01") {
      GetwithMultipleQueryString(URLAPI.LeavePlan.Get, { page: 1, size: 10 }, getLeavePlan);
      setIsLoading(false);
      setAction("Leave Plan Saved Successfully!");
      setSuccess(false);
    }
  };

  const getMdas = (data) => {
    console.log("logging getMdas", data);
    data ? setMdas(data) : setMdas([]);
  };

  const clearForm = () => {
    setCreateLeavePlan({
      leaveName: "",
      startDate: "",
      endDate: "",
      reliefOfficerName: "",
      document: null
    })
    setMdaDetails({
      mdaName: '',
      mdaId: 0
    })
    setLeaveReason('')
    setReliefOfficerID(0)
    setHandoverNote('');
    setDaysSelected(0)
  }

  const getLeavePlan = (data) => {
    console.log("get leave Plan", data.data.result);

    data.data && data.data.result
      ? setLeavePlan({
        ...leavePlan,
        loading: false,
        data: [...data.data.result]
      })
      : setLeavePlan({
        ...leavePlan,
        loading: false,
        error: data.data.message
      });
  };

  React.useEffect(() => {
    const { startDate, endDate } = createLeavePlan;
    const { leavedaysleft } = leaveSessionCont.data;
    console.log(leaveSession.data.sessionYear)

    if (startDate && endDate && startDate <= endDate) {
      createLeavePlan.leaveName === 'Maternity' ?
        setDaysSelected(90) :
        setDaysSelected(getLeaveDays(startDate, endDate));
      console.log(getLeaveDays(startDate, endDate))
    }
  }, [createLeavePlan])



  const submitLeave = (id) => {
    setIsLoading(true);
    const { startDate, endDate, leaveName, reliefOfficerName, document } = createLeavePlan;

    if (!leaveTypeID) {
      notification({
        title: "Leave Type Error!",
        message: "Please Select a Leave Type!",
        type: "danger"
      });
      setIsLoading(false)
      return;
    } else if (!reliefOfficerID) {
      notification({
        title: "Relief Officer Error!",
        message: "Please Select a Relief Officer!",
        type: "danger"
      });
      setIsLoading(false)
      return;
    } else if (approverId?.length < 1) {
      notification({
        title: "Approver Error!",
        message: "Please Select an Approver!",
        type: "danger"
      });
      setIsLoading(false)
      return;
    } else if (!leaveReason) {
      notification({
        title: "Leave Reason Error!",
        message: "Please specify your reason for leave!",
        type: "danger"
      });
      setIsLoading(false)
      return;
    } else if (!handoverNote) {
      notification({
        title: "Hand Over Error!",
        message: "Please Enter your hand over note!",
        type: "danger"
      });
      setIsLoading(false)
      return;
    }
    else if (leaveName === "Maternity" && !document) {
      notification({
        title: "Maternity Document Error!",
        message: "Please Upload your maternity document!",
        type: "danger"
      });
      setIsLoading(false)
      return;
    }
    else if (startDate <= moment().format("YYYY-MM-DD")) {
      notification({
        title: "Date Error!",
        message: "Start date must be greater than today's date!",
        type: "danger"
      });
      setIsLoading(false)
      return;
    } else if (startDate >= endDate) {
      notification({
        title: "Date Error!",
        message: "End date must be greater than start date!",
        type: "danger"
      });
      setIsLoading(false)
      return;
    }
    else {
      setIsLoading(false)

      setLeaveSession({ ...leaveSession, loading: false });
      Post(
        {
          leaveReason: leaveReason,
          handoverNote: handoverNote,
          reliefOfficerId: reliefOfficerID,
          reliefOfficer: reliefOfficerName,
          leaveStatus: 1,
          leaveType: leaveName,
          leaveDuration: daysSelected,
          leaveTypefk: 0,
          document: document,
          leaveSession: leaveSession.data.sessionYear,
          startDate: startDate,
          endDate: endDate,
          approvers: approverId
        },

        URLAPI.Approver.submitOtherLeave, (data) => {
          console.log(leaveReason, handoverNote, reliefOfficerID, reliefOfficerName, leaveName)
          const { statusCode, apiMessage } = data;
          console.log(
            "Logging data after submitting leave request",
            data,
            typeof statusCode,
            apiMessage
          );

          if (data && statusCode === "00") {
            setSuccessMessage(apiMessage)
            setSuccess(true)
            clearForm();
            GetwithMultipleQueryString(URLAPI.LeavePlan.Get, { page: 1, size: 10 }, getLeavePlan);
            setIsLoading(false)
            setLeaveSession({ ...leaveSession, loading: false });
          } else if (data && statusCode === "01") {
            GetwithMultipleQueryString(URLAPI.LeavePlan.Get, { page: 1, size: 10 }, getLeavePlan);
            setErrorMsg(apiMessage);
            setShowErrorModal(true);
            setLeaveSession({ ...leaveSession, loading: false });
          }
        });
    }
  };

  const saveRequest = () => {
    const { startDate, endDate, leaveName, reliefOfficerName, document } = createLeavePlan;

    if (!leaveTypeID) {
      notification({
        title: "Leave Type Error!",
        message: "Please Select a Leave Type!",
        type: "danger"
      });
      return;
    } else if (!reliefOfficerID) {
      notification({
        title: "Releive Officer Error!",
        message: "Please Select a Relieve Officer!",
        type: "danger"
      });
      return;
    } else if (startDate <= moment().format("YYYY-MM-DD")) {
      notification({
        title: "Date Error!",
        message: "Start date must be greater than today's date!",
        type: "danger"
      });
      return;
    } else if (startDate >= endDate) {
      notification({
        title: "Date Error!",
        message: "End date must be greater than start date!",
        type: "danger"
      });
      return;
    } else {
      if (leaveSessionCont.data.leavedaysleft >= daysSelected) {
        Post(
          {
            reliefEmpfk: reliefOfficerID,
            reliefOfficer: reliefOfficerName,
            leaveTypefk: leaveTypeID,
            leaveType: leaveName,
            leaveDuration: daysSelected,
            startDate: startDate,
            endDate: endDate,
            approvers: approverId,
            document: document

          },
          URLAPI.LeavePlan.save,
          checkIsRequestSave
        );
        setIsLoading(false);
        GetwithMultipleQueryString(URLAPI.LeavePlan.Get, { page: 1, size: 10 }, getLeavePlan);
        setCreateLeavePlan({
          ...createLeavePlan,
          leaveName: "",
          startDate: "",
          endDate: "",
          reliefOfficerName: "",
          document: null
        });
      } else {
        notification({
          title: "Error!",
          message: "Days Selected is greater than leave days left",
          type: "danger"
        });
      }
    }
  };


  const setReliefOficerPlan = (response) => console.log("Relief Officer plan", response);



  const getReliefOfficer = (id) => Get(`${URLAPI.leaveType.Post}${id}`, setReliefOficerPlan);





  const selectReliefOfficer = (id) => {
    setLeaveSession({ ...leaveSession, loading: false });
    GetwithQueryString(
      URLAPI.LeavePlan.selectReliefOfficer,
      { name: "ReliefOfficerid", value: id },
      (data) => {
        data && data.result ? setReliefOfficerPlan([...data.result]) : setReliefOfficerPlan([]);
        data && data.result
          ? setLeaveSession({ ...leaveSession, loading: false })
          : setLeaveSession({ ...leaveSession, loading: false });
      }
    );
  };


  const fetchEmployees = (id) => {
    console.log(mdaId)
    GetServices(
      `/api/Employees/employees_by_mda?mdaid=${id}`,
      getReliefOfficerNames
    );

  }

  const getReliefOfficerNames = (data) => {
    console.log("logging getReliefOfficerNames", data);
    data && data ? setReliefOfficers(data) : setReliefOfficers([]);
  };

  const handleLeaveRequest = (data) => {
    console.log(data);
  }
  // const getLeavePlan = (data) => {
  //   console.log("get leave Request", data);

  //   data && data.result
  //     ? setLeaveRequest({
  //         ...leaveRequest,
  //         loading: false,
  //         data: [...data.result]
  //       })
  //     : setLeaveRequest({
  //         ...leaveRequest,
  //         loading: false,
  //         error: data.message
  //       });
  // };

  //where is the upload docu
  // const approveleave = (data) => {
  //   Put(data, URLAPI.Approver.approve, (response) => {
  //     console.log("Approve response", response);

  //     response && response.statusCode === "00" && setShowSuccess(false);
  //   });
  // };

  // const submitLeave = (data) => {
  //   console.log("Submitting Leave request data", data);
  // };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Leave Request";
    GetwithMultipleQueryString(URLAPI.LeavePlan.Get, { page: 1, size: 10 }, getLeavePlan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="w-100">
      {leaveRequest.loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="w-100 flex space-between flex-v-center">
            <h2>Leave Request</h2>
            {/* <LocationDetails /> */}
          </div>
          <div className="table-view m-t-10">
            {leavePlan.data.filter(
              (item) => item.leaveRequest === "No" && item.statusName.toLowerCase() === "approved"
            ).length < 1 ? null : (
              <div className="table-header">
                <div className="table-row">
                  <div className="th w-max-content" style={{ ...tableHead }}>
                    <p className="san-sherif">Session</p>
                  </div>
                  <div className="th" style={{ ...tableHead }}>
                    <p className="san-sherif">Start Date</p>
                  </div>
                  <div className="th" style={{ ...tableHead }}>
                    <p className="san-sherif">End Date</p>
                  </div>
                  <div className="th" style={{ ...tableHead }}>
                    <p className="san-sherif">Leave Duration</p>
                  </div>
                  <div className="th" style={{ ...tableHead }}>
                    <p className="san-sherif">Relief Officer</p>
                  </div>
                  <div className="th right-text" style={{ ...tableHead }}>
                    <p className="san-sherif">Action</p>
                  </div>
                </div>
              </div>
            )}
            <div className="table-body">
              {leavePlan.data.filter(
                (item) =>
                  item.leaveRequest.toLowerCase() === "no" &&
                  item.statusName.toLowerCase() === "approved"
              ).length === 0 ? (
                <div className="flex flex-h-center flex-v-center bolder-text">No Record(s)</div>
              ) : (
                leavePlan.data.map(
                  ({
                    id,
                    item,
                    leaveSession,
                    startDate,
                    endDate,
                    leaveDuration,
                    reliefOfficer,
                    statusName,
                    status,
                    leaveRequest
                  }) =>
                    // (statusName && statusName !== "Saved") ||
                    leaveRequest &&
                    leaveRequest.toLowerCase() === "no" &&
                    statusName.toLowerCase() === "approved" && (
                      <div className="table-row" key={id}>
                        <div className="td" style={{ ...tableContent }}>
                          {leaveSession}
                        </div>
                        <div className="td" style={{ ...tableContent }}>
                          {moment(startDate).format("MMMM Do, YYYY")}
                        </div>
                        <div className="td" style={{ ...tableContent }}>
                          {moment(endDate).format("MMMM Do, YYYY")}
                        </div>
                        <div className="td" style={{ ...tableContent }}>
                          {leaveDuration}
                        </div>
                        <div className="td" style={{ ...tableContent }}>
                          {reliefOfficer}
                        </div>
                        <div className="td actions flex m-l-auto">
                          <div
                            style={{ ...submitBtn, ...centerContent }}
                            onClick={() => {
                              setCurrentId(id);
                              setShowLeaveApply(!showLeaveApply);
                            }}>
                            <p style={{ ...submitText }}>{"Apply For Leave"}</p>
                          </div>
                        </div>
                      </div>
                    )
                )
              )}
            </div>
          </div>

          {showLeaveApply && (
            <LeaveApply
              currentId={currentId}
              leaveRequest={leaveRequest}
              setLeaveRequest={setLeavePlan}
              closeMyself={setShowLeaveApply}
              recipientMda={recipientMda}
              getLeavePlan={getLeavePlan}
              setrecipientMda={setrecipientMda}
              mdaList={mdaList}
              employeeName={employeeName}
              setemployeeName={setemployeeName}
              employees={employees}
              approverId={approverId}
              handleApproverId={handleApproverId}
              getEmployeesFromMda={getEmployeesFromMda}
            />
          )}
          {/*
          {showSuccess && (
            <SuccessModal closeModal={() => setShowSuccess(false)} />
          )} */}
          <>
            <div className="w-80">


              <div className='flex  flex-h-center flex-direction-column'>
                {/* <div
                className='modal-box'
                style={{
                  boxShadow: '0px 2px 6px #0000000A',
                  position: 'relative',
                  width: '650px',
                }}> */}

                <div className="bold-text m-t-20" style={{ margin: "10px" }}>
                  <h3 >Create Leave Request</h3>
                </div>

                <div className="row w-100" style={{ margin: ".6rem 0 0" }}>
                  <div
                    className="col-12"
                    style={{
                      backgroundColor: "white",
                      boxShadow: "0px 2px 6px #0000000A",
                      padding: "1rem .7rem",

                    }}>
                    <form>
                      <div style={{ ...inputStyle }}>
                        <SelectInput
                          label={"Leave Type*"}
                          options={leaveType2}
                          value={createLeavePlan.leaveName}
                          onChange={(e) => {
                            setCreateLeavePlan({
                              ...createLeavePlan,
                              leaveName: e.target.value,
                              document: null
                            });
                            e.target.children[e.target.selectedIndex].id &&
                              GetwithQueryString(
                                URLAPI.LeavePlan.leaveSessionCont,
                                {
                                  name: "leavetypeid",
                                  value: e.target.children[e.target.selectedIndex].id
                                },
                                getLeaveSessionCont
                              );
                            setLeaveSession({ ...leaveSession, loading: false });
                            e.target.children[e.target.selectedIndex].id &&
                              setLeaveTypeID(Number(e.target.children[e.target.selectedIndex].id));
                          }}
                          selectOption={getReliefOfficer}
                        />
                      </div>
                      <div style={{ ...inputStyle }}>
                        <FormSearchInput
                          label={"Relief Officer's MDA*"}
                          placeholder="Select MDA..."
                          value={mdaDetails.mdaName}
                          onChange={(e) => {
                            setMdaDetails({
                              ...mdaDetails,
                              mdaName: e.target.value
                            });
                            GetServices(
                              "/api/Employees/mdas",
                              getMdas
                            );
                          }}
                          displayType="roles"
                          setStateValue={setMdaDetails}
                          formState={mdaDetails}
                          stateName={"mdaName"}
                          searchValue={mdas}
                          setSuggestions={setMdas}
                          selectedId={setMdaId}
                          selectReliefOfficer={fetchEmployees}
                        />
                      </div>
                      <div style={{ ...inputStyle }}>
                        <FormSearchInput
                          label={"Relief Officer*"}
                          placeholder="Relief Officer Name....."
                          value={createLeavePlan.reliefOfficerName}
                          onChange={(e) => {
                            setCreateLeavePlan({
                              ...createLeavePlan,
                              reliefOfficerName: e.target.value
                            });
                            // GetwithQueryString(
                            //   URLAPI.LeavePlan.staffList,
                            //   {
                            //     name: "name",
                            //     value: e.target.value
                            //   },
                            //   getReliefOfficerNames
                            // );
                          }}
                          displayType="roles"
                          setStateValue={setCreateLeavePlan}
                          formState={createLeavePlan}
                          stateName={"reliefOfficerName"}
                          searchValue={reliefOfficers}
                          setSuggestions={setReliefOfficers}
                          selectedId={setReliefOfficerID}
                          selectReliefOfficer={selectReliefOfficer}
                        />
                      </div>
                      <div style={{ ...inputStyle }}>
                        <div>Approver's MDA*</div>
                        <Select
                          placeholder="Select Approvers MDA"
                          // isMulti
                          className="basic-multi-select"
                          onChange={(value) => {
                            console.log(value);
                            // this.setState({ employeeName: e.target.value });
                            setrecipientMda(value);
                            getEmployeesFromMda(value.value);
                            // this.setState({recipientMda:value});
                          }}
                          value={recipientMda}
                          options={mdaList.map((m) => ({
                            label: m.name,
                            value: m.value,
                          }))}
                        />
                      </div>

                      <div style={{ ...inputStyle }}>
                        <div>Approver's Name*</div>
                        <Select
                          placeholder="Select Approver"
                          // isMulti
                          className="basic-multi-select m-t-10 m-b-10"
                          onChange={(value) => {
                            console.log(value.value);
                            setemployeeName(value);
                            handleApproverId(value);
                            // handleApproverId(value)
                            // setEmployeeList(value.value);
                            // this.setState({ employeeList: value });

                            // this.getEmployeesFromMda(value.value)
                            // this.setState({recipientMda:value});
                          }}
                          // onChange={(e)=>{handleApproverId(e)}}
                          // onInputChange={(e) => {
                          //   handleApprovers(e);
                          // }}
                          isMulti
                          value={employeeName}
                          options={employees?.map((m) => ({
                            label: m.name,
                            value: m.value,
                          }))}
                        />
                      </div>
                      {/* <div style={{ ...inputStyle }}>
                    <FormSearchInput
                      label={"Select Relief Officer"}
                      placeholder="Relief Officer Name....."
                      value={createLeavePlan.reliefOfficerName}
                      onChange={(e) => {
                        setCreateLeavePlan({
                          ...createLeavePlan,
                          reliefOfficerName: e.target.value
                        });
                        GetwithQueryString(
                          URLAPI.LeavePlan.staffList,
                          {
                            name: "name",
                            value: e.target.value
                          },
                          getReliefOfficerNames
                        );
                      }}
                      displayType="names"
                      setStateValue={setCreateLeavePlan}
                      formState={createLeavePlan}
                      stateName={"reliefOfficerName"}
                      searchValue={reliefOfficers}
                      setSuggestions={setReliefOfficers}
                      selectedId={setReliefOfficerID}
                      selectReliefOfficer={selectReliefOfficer}
                    />
                  </div> */}
                      <div style={{ ...inputStyle }}>
                        <TextAreaInput
                          label={"Leave Reason*"}
                          name="leaveReason"
                          value={leaveReason}
                          onChange={(e) => { setLeaveReason(e.target.value) }}
                        />
                      </div>

                      <div style={{ ...inputStyle }}>
                        <TextAreaInput
                          label={"Handover Note*"}
                          name="handOverNote"
                          value={handoverNote}
                          onChange={(e) => { setHandoverNote(e.target.value) }}
                        />
                      </div>


                      <div style={{ ...inputStyle }}>
                        <FormDateInput
                          label={"Start Date*"}
                          name="startDate"
                          value={createLeavePlan.startDate}
                          onChange={(e) => {
                            if (moment(e.target.value).day() < 1 || moment(e.target.value).day() > 5) {
                              notification({
                                title: "Date Error!",
                                message: "Leave cannot start during the weekend",
                                type: "danger"
                              });
                            } else if (e.target.value <= moment().format("YYYY-MM-DD")) {
                              notification({
                                title: "Date Error!",
                                message: "Start date must be greater than today's date!",
                                type: "danger"
                              });
                            } else {
                              createLeavePlan.leaveName === 'Maternity' ?
                                setCreateLeavePlan({ ...createLeavePlan, startDate: e.target.value, endDate: addDays(e.target.value, 90).toISOString().split('T')[0], startDate: e.target.value })
                                :
                                setCreateLeavePlan({ ...createLeavePlan, startDate: e.target.value });

                            }
                          }}
                        />
                      </div>
                      <div style={{ ...inputStyle }}>
                        <FormDateInput
                          label={"End Date*"}
                          name="endDate"
                          disabled={createLeavePlan.leaveName === 'Maternity'}
                          value={createLeavePlan.endDate}
                          onChange={(e) => {
                            if (moment(e.target.value).day() < 1 || moment(e.target.value).day() > 5) {
                              notification({
                                title: "Date Error!",
                                message: "Leave cannot end during the weekend",
                                type: "danger"
                              });
                            } else if (
                              !createLeavePlan.startDate ||
                              createLeavePlan.startDate > e.target.value
                            ) {
                              notification({
                                title: "Date Error!",
                                message: "End date must be greater than start date!",
                                type: "danger"
                              });
                            } else {

                              setCreateLeavePlan({ ...createLeavePlan, endDate: e.target.value });
                            }
                          }}
                        />
                      </div>

                      <div style={{ margin: ".8rem 0", ...labelStyle }}>
                        Number of days selected: <span>{daysSelected}</span>
                      </div>

                      {success && (
                        <div
                          className="overlay flex"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            boxShadow: "0px 2px 6px #0000000A"
                          }}>
                          <SuccessModal action={action} closeModal={closeSuccessModal} />
                        </div>
                      )}


                      {
                        createLeavePlan.leaveName === "Maternity" &&
                        <div>
                          <UploadButton sendImagg={recImagg} />
                         {createLeavePlan?.document && <div className="m-t-10">
                            <GiPaperClip  className="m-r-10"/>
                            <a  href={createLeavePlan?.document} target="_blank" rel="noopener noreferrer">View Maternity Document</a>
                          </div>}
                          
                        </div>
                      }

                      <div className=" space-around w-100 m-t-20">
                        <button
                          className="btn btn-large p-r w-100 flex  flex-v-center flex-h-center justify-content-center"
                          style={{ textAlign: "center", height: "43px" }}
                          type="button"
                          onClick={submitLeave}
                          disabled={isLoading}>
                          <p style={{ margin: "auto 15px auto" }}>{isLoading ? "Submitting" : "Submit"}</p>
                          {isLoading && <img src={Loader} className="btn-loading" alt="Loading..." />}
                        </button>
                        {/* <button className="btn btn-large w-45" type="button">
                      Submit
                    </button> */}
                      </div>
                    </form>
                  </div>
                  {/* <div className="col-8" style={{ marginBottom: "10px" }}>
                <Accordion
                  title="Leave Plan Details"
                  content={
                    leavePlan.data.length > 0 ? leavePlan.data : "You Have no Leave Plan Yet"
                  }
                />
                <Accordion
                  title="Relief Officer Plan"
                  content={reliefOfficerPlan.length > 0 ? reliefOfficerPlan : "No Leave Plan"}
                />
                <Accordion
                  title="You are a Relief Officer to"
                  content={
                    reliefOfficerTo.data.length > 0
                      ? reliefOfficerTo.data
                      : "Not A Relief Officer to Anyone yet"
                  }
                />
              </div> */}
                </div>
              </div>
            </div>

            {/* </div> */}
          </>
        </>
      )}
    </div>
  );
};

export default LeaveRequest;
