/* eslint-disable import/no-anonymous-default-export */
import {React, useEffect} from "react";
import Cookies from "js-cookie";
import { RiBarChartFill, RiUserSettingsLine } from "react-icons/ri";
import { AiOutlineHome } from "react-icons/ai";
import { IoIosSettings, IoIosCreate } from "react-icons/io";
import { FaTree } from "react-icons/fa";
import { VscOpenPreview } from "react-icons/vsc";
import { BiLogOutCircle } from "react-icons/bi";
import { FiHelpCircle } from "react-icons/fi";

let sidebar = [];
// useEffect (()=>{

// },[Cookies.get("fullname")])

let name = Cookies.get("fullname");
console.log("Full name", name);



if (Cookies.get("fullname")=='EdoGov Admin') {
  sidebar = [
    {
      title: "User Manual",
      href: `${Cookies.get("_APIBaseURL")}${Cookies.get("helpLink")}`,
      icon: <FiHelpCircle className="icon" />
    },
    {
      title: "Home",
      href: "/home",
      icon: <AiOutlineHome className="icon" />
    },
    {
      title: "Dashboard",
      href: "/admin-portal",
      icon: <RiBarChartFill className="icon" />
    },
    {
      title: "Create Leave Plan",
      icon: <IoIosCreate className="icon" />,
      href: "/admin-portal/create-leave-plan"
    },
    {
      title: "Leave Request",
      icon: <FaTree className="icon" />,
      href: "/admin-portal/leave-request"
    },
    {
      title: "Leave Request Review",
      icon: <VscOpenPreview className="icon" />,
      href: "/admin-portal/leave-request-review"
    },
    {
      title: "Setup",
  
      icon: <IoIosSettings className="icon" />,
  
      children: [
        { title: "Create Leave Type", href: "/admin-portal/create-leave-type" },
        {
          title: "Create Leave Period",
          href: "/admin-portal/create-leave-period"
        },
        { title: "Holiday Setup", href: "/admin-portal/holiday-setup" },
        {
          title: "Leave Plan Approver",
          href: "/admin-portal/leave-plan-approver"
        },
        {
          title: "Leave Request Approver",
          href: "/admin-portal/leave-request-approver"
        }
      ]
    },
    {
      title: "Logout",
      icon: <BiLogOutCircle className="icon" />,
        href: "/home",
    
    }
  ];
 
}
else{
  sidebar = [
    {
      title: "User Manual",
      href: `${Cookies.get("_APIBaseURL")}${Cookies.get("helpLink")}`,
      icon: <FiHelpCircle className="icon" />
    },
    {
      title: "Home",
      href: "/home",
      icon: <AiOutlineHome className="icon" />
    },
    {
      title: "Dashboard",
      href: "/admin-portal",
      icon: <RiBarChartFill className="icon" />
    },
    {
      title: "Create Leave Plan",
      icon: <IoIosCreate className="icon" />,
      href: "/admin-portal/create-leave-plan"
    },
    {
      title: "Leave Request",
      icon: <FaTree className="icon" />,
      href: "/admin-portal/leave-request"
    },
    {
      title: "Leave Request Review",
      icon: <VscOpenPreview className="icon" />,
      href: "/admin-portal/leave-request-review"
    },
    {
      title: "Logout",
      icon: <BiLogOutCircle className="icon" />,
        href: "/home",
    
    }
  ];
}

sidebar.push();

export default sidebar;
