import React from "react";
import { Switch, Route } from "react-router";
import { pure } from "recompose";

import ProtectedRoute from "./ProtectedRoute";

import Dashboard from "../components/pages/Dashboard";
import DevApprover from "../components/pages/DevApprover";
import Competency from "../components/pages/Competency";
import Assessment from "../components/pages/Assessment";

import MdaSetup from "../components/pages/MdaSetup";
import EmployeeProfile from "../components/pages/EmployeeProfile";
import EmployeeDetails from "../components/pages/EmployeeDetails";
import SupervisorOrganogram from "../components/pages/SupervisorOrganogram";
import AppraisalApp from "../components/pages/AppraisalApp";
import PerfPlanning from "../components/pages/PerPlanning";
import AppraisalReview from "../components/pages/AppraisalReview";
import SetPages from "../components/pages/SetPeriod";
import Rate from "../components/pages/Rate";
import PerformanceApproval from "../components/pages/PerformanceApproval";
import CreateLeavePlan from "../components/pages/CreateLeavePlan";
import CreateLeaveType from "../components/pages/CreateLeaveType";
import CreateLeavePeriod from "../components/pages/CreateLeavePeriod";
import HolidaySetup from "../components/pages/HolidaySetup";
import LeavePlanApprover from "../components/pages/LeavePlanApprover";
import LeaveRequestApprover from "../components/pages/LeaveRequestApprover";
import LeaveRequest from "../components/pages/LeaveRequest";
import LeaveRequestReview from "../components/pages/LeaveRequestReview";

import SessionHOC from "../components/timeout/SessionHOC";

export default pure(() => (
  <Switch>
    <ProtectedRoute
      path="/admin-portal/"
      exact
      component={SessionHOC(Dashboard)}
    />
    <ProtectedRoute path="/admin-portal/mda-setup" exact component={MdaSetup} />
    <ProtectedRoute
      path="/admin-portal/performance-approval"
      exact
      component={PerformanceApproval}
    />

    <ProtectedRoute path="/admin-portal/set-pages" exact component={SetPages} />
    <ProtectedRoute
      path="/admin-portal/dev-approver"
      exact
      component={DevApprover}
    />
    <ProtectedRoute
      path="/admin-portal/create-competency"
      exact
      component={Competency}
    />

    <ProtectedRoute
      path="/admin-portal/create-leave-plan"
      exact
      component={SessionHOC(CreateLeavePlan)}
    />

    <ProtectedRoute
      path="/admin-portal/appraisal-review"
      exact
      component={AppraisalReview}
    />

    <ProtectedRoute
      path="/admin-portal/Qual-assessment"
      exact
      component={Assessment}
    />

    <ProtectedRoute
      path="/admin-portal/appraisal-approval"
      exact
      component={AppraisalApp}
    />

    <ProtectedRoute
      path="/admin-portal/perform-planning"
      exact
      component={PerfPlanning}
    />

    <ProtectedRoute
      path="/admin-portal/create-leave-type"
      exact
      component={SessionHOC(CreateLeaveType)}
    />
    <ProtectedRoute
      path="/admin-portal/create-leave-period"
      exact
      component={SessionHOC(CreateLeavePeriod)}
    />
    <ProtectedRoute
      path="/admin-portal/holiday-setup"
      exact
      component={SessionHOC(HolidaySetup)}
    />
    <ProtectedRoute
      path="/admin-portal/leave-plan-approver"
      exact
      component={SessionHOC(LeavePlanApprover)}
    />
    <ProtectedRoute
      path="/admin-portal/leave-request-approver"
      exact
      component={SessionHOC(LeaveRequestApprover)}
    />
    <ProtectedRoute
      path="/admin-portal/leave-request"
      exact
      component={SessionHOC(LeaveRequest)}
    />
    <ProtectedRoute
      path="/admin-portal/leave-request-review"
      exact
      component={SessionHOC(LeaveRequestReview)}
    />

    <ProtectedRoute
      path="/admin-portal/employee-profile"
      exact
      component={EmployeeProfile}
    />
    <ProtectedRoute
      path="/admin-portal/employee-details"
      exact
      component={EmployeeDetails}
    />

    <ProtectedRoute path="/admin-portal/rating" exact component={Rate} />
    <ProtectedRoute
      path="/admin-portal/supervisor-organogram"
      exact
      component={SupervisorOrganogram}
    />
    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));
