// import { Document, Page } from 'react-pdf';
import React, { Component, useRef, PropTypes } from 'react';
import logo from '../../assets/images/edsg-logo-250.png'
import { Get } from '../../utility/fetch';
import { IoMdClose } from 'react-icons/io';
// import PrintPDF from '../inputs/printPDF';
import moment from "moment";
import { PDFExport } from "@progress/kendo-react-pdf";
import ReactToPrint from 'react-to-print';
import Cookies from 'js-cookie'
import { URLAPI } from '../../utility/ApiMethods';
// download html2canvas and jsPDF and save the files in app/ext, or somewhere else
// the built versions are directly consumable
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


const tableBorder = {
  border: '1px solid black',
  borderRight: '1px solid black',
  borderBottom: '1px solid black',
};

let IDEE = 0

const MemoPDF = (props) => {

  const pdfExportComponent = React.useRef(null);


  const toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))


    React.useEffect(() => {
      const handleContextMenu = (event) => {
        event.preventDefault(); // Prevent the default context menu behavior
      };
  
      // Attach the event listener when the component mounts
  
      const images = document.querySelectorAll(".sectionToDisableRightClick");
  
      images.forEach((image) => {
        image.addEventListener("contextmenu", handleContextMenu);
      });
  
    
  
      // Remove the event listener when the component unmounts
      return () => {
        images.forEach((image) => {
          image.removeEventListener("contextmenu", handleContextMenu);
        });
      };
    }, [props?.cardDetails?.id]);


  // componentDidMount() {
  //   IDEE = props.data.id

  // }


  // printDocument() {
  //   console.log('clicked')
  //   const input = document.getElementById('divToPrint');
  //   html2canvas(input, { allowTaint: false })
  //     .then((canvas) => {
  //       const imgData = canvas.toDataURL('image/jpeg', 1.0);
  //       const pdf = new jsPDF('p', 'px', 'a4');
  //       const pageWidth = pdf.internal.pageSize.getWidth();
  //       const pageHeight = pdf.internal.pageSize.getHeight();

  //       const widthRatio = pageWidth / canvas.width;
  //       const heightRatio = pageHeight / canvas.height;
  //       const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

  //       const canvasWidth = canvas.width * ratio;
  //       const canvasHeight = canvas.height * ratio;

  //       const marginX = (pageWidth - canvasWidth) / 2;
  //       const marginY = (pageHeight - canvasHeight) / 2;
  //       pdf.addImage(imgData, 'JPEG', marginX, marginY, canvasWidth, canvasHeight);
  //       // pdf.output('dataurlnewwindow');
  //       pdf.save(`Leave Management ${IDEE}.pdf`);
  //     });
  // }


  return (
    <>
      <div
        className='overlay sectionToDisableRightClick'
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0px 2px 6px #0000000A',
        }}>
        <div className="mb5 flex justify-content-center m-b-20">
          <button style={{ width: "650px" }} className='btn btn ' onClick={() => {
            if (pdfExportComponent?.current) {
              pdfExportComponent?.current?.save();
            }
          }}>Download</button>
        </div>
        <div className='flex flex-v-center flex-h-center flex-direction-column'>
          <div
            className='modal-box'
            style={{
              boxShadow: '0px 2px 6px #0000000A',
              position: 'relative',

            }}>
            <IoMdClose
              className='pointer m-l-5'
              style={{
                position: 'absolute',
                top: '0',
                left: '100%',
                fontSize: '24px',
                color: '#fff',
                width: '40px',
                height: '40px',
                backgroundColor: '#F74F11',
                padding: '3px',
              }}
              onClick={props.closeModal}
            />

            <div className='content no-padding '>

              <PDFExport paperSize="A4" margin="8mm" fileName={props?.name === 'request' ? `${Cookies?.get('fullname')}  Leave Request ` : `${Cookies?.get('fullname')}  Leave Plan`} ref={pdfExportComponent}>
                <div id="divToPrint"  >

                  <div className='m-t-30'>
                    <div className='flex justify-content-center m-t-30'>
                      <img className=' m-t-40' src={logo} alt="Edo state logo" />
                    </div>

                  </div>
                  <div className='flex flex-h-center m-t-10 '><p> {Cookies?.get("mdaAddress")}</p></div>

                  <div className='flex flex-h-center m-t-10 '><strong>{Cookies?.get('mda') || 'MDA'}</strong></div>
                  <hr className='m-b-20 mx-40' />


                  <div className='flex flex-h-center  '><strong>EDSG Leave Management</strong></div>

                  <div className='flex flex-h-center  '><strong>{props?.data?.statusName}</strong></div>
                  <div className='flex space-between mx-40'><p>ID:</p> <p>{props?.data?.id}</p></div>
                  <hr className='mx-35' />
                  <div className='flex space-between m-t-20 mx-40'><p>Session:</p> <p>{props?.data?.leaveSession}</p></div>
                  <hr className='mx-35' />
                  <div className='flex space-between m-t-20 mx-40'><p>Leave Requestor:</p> <p>{props?.data?.requestor}</p></div>
                  <hr className='mx-35' />
                  <div className='flex space-between m-t-20 mx-40'><p>Leave Type:</p> <p>{props?.data?.leaveType}</p></div>
                  <hr className='mx-35' />
                  <div className='flex space-between m-t-20 mx-40 '><p>Leave Request Date:</p> <p>{moment(props?.data?.createdAt)?.format("MMMM Do, YYYY")}</p></div>
                  <hr className='mx-35' />
                  {
                    props.name === 'request' &&
                    <>
                      <div className='flex space-between m-t-20 mx-40'>
                        <p>Leave Reason:</p> <p>{props?.data?.leaveReason}</p>
                      </div>
                      <hr className='mx-35' />
                      <div className='flex space-between m-t-20 mx-40'>
                        <p>Handover Note:</p> <p>{props?.data?.handoverNote}</p>
                      </div>
                      <hr className='mx-35' />
                    </>
                  }
                  <div className='flex space-between m-t-20 mx-40'><p>Start Date:</p> <p>{moment(props?.data?.startDate)?.format("MMMM Do, YYYY")}</p></div>
                  <hr className='mx-35' />
                  <div className='flex space-between m-t-20 mx-40'><p>End Date:</p> <p>{moment(props?.data?.endDate)?.format("MMMM Do, YYYY")}</p></div>
                  <hr className='mx-35' />
                  <div className='flex space-between m-t-20 mx-40'><p>Duration:</p> <p>{`${props?.data?.leaveDuration} days`} </p></div>
                  <hr className='mx-35' />
                  <div className='flex space-between m-t-20 mx-40'><p>Relief Officer:</p> <p>{props?.data?.reliefOfficer}</p></div>
                  <hr className='mx-35' />


                  <div className='m-t-20 mx-40' >
                    <div
                      id='bordered-table'
                      className='table-view '
                      style={{
                        borderCollapse: 'collapse',
                        borderSpacing: '5px 15px',
                        // border: 'none',
                        // ...props.overrideTableStyles,
                      }}>

                      <div
                        className='table-header'
                        style={{
                          backgroundColor: `${props.headerBGColor || '#d5ffd5'}`,
                          color: `${props.headerColor || '#43425D'}`,
                        }}>
                        <div className='table-row'>
                          <div className='th case title w-25' style={{ ...tableBorder }}>
                            <p>Approver</p>
                          </div>
                          <div className='th case title w-25' style={{ ...tableBorder }}>
                            <p>Approval Date</p>
                          </div>
                          {/* <div className='th case title w-25' style={{ ...tableBorder }}>
                                <p>Status</p>
                              </div> */}
                          <div className='th case title w-25' style={{ ...tableBorder }}>
                            <p>Comment</p>
                          </div>
                          <div className='th case title w-25' style={{ ...tableBorder }}>
                            <p>Signature</p>
                          </div>
                        </div>
                      </div>
                      {Array.isArray(props?.comments) && props?.comments?.length === 0 ? (
                        <h4 className='m-t-10'>No approval yet for this request</h4>
                      ) : (
                        <div className='table-body no-border'>
                          {console.log(props.comments)}
                          {props?.comments?.map((item, idx) => (

                            <div className='table-row no-border' key={idx}>
                              <div
                                className='td font-sz-13'
                                style={{
                                  ...tableBorder,
                                }}>
                                {item?.commentor}
                              </div>
                              <div className='td font-sz-13' style={{ ...tableBorder }}>
                                {moment(item?.modifiedAt).format("MMMM Do, YYYY")}
                              </div>
                              <div className='td font-sz-13' style={{ ...tableBorder }}>
                                {item?.comment}
                              </div>
                              <div className='td font-sz-13' style={{ ...tableBorder }}>

                                {item?.signature64 ? (
                                  <img
                                    alt="signature"
                                    style={{ width: "160px", height: "70px" }}
                                    src={`data:image/png;base64, ${item?.signature64}`}
                                  />
                                ) : ""}
                              </div>
                              {/* <div className='td font-sz-13' style={{ ...tableBorder }}>
                                    {item?.comment}
                                  </div> */}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                </div>
              </PDFExport>

            </div>

          </div>

        </div>

      </div>


    </>
  );
}


export default MemoPDF;

