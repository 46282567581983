import React, { useEffect, useState } from "react";
import moment from "moment";
import Cookies from "js-cookie";
import { Pagination } from "antd";
import LocationDetails from "../layouts/LocationDetails";
import DashboardLeaveCard from "../layouts/DashboardLeaveCard";
import Accordion from "../layouts/Accordion";
import LoadingSpinner from "../../resources/loadingSpinner";
import TimeController from "../../controllers/TimeController";
import ErrorModal from "../layouts/ErrorModal";
import Modal from "../layouts/Modal";
import MemoPDF from "../inputs/MemoPDF"

// Icons
import { GiPalmTree } from "react-icons/gi";

// Api
import { URLAPI } from "../../utility/ApiMethods";
import { Get, GetwithMultipleQueryString, GetwithQueryString } from "../../utility/fetch";

const tableHeader = {
  // borderBottom: 'rgba(28,129,28,.5)'
  padding: "15px 0"
};

const centerContent = {
  textAlign: "left"
};

const rightAlign = {
  textAlign: "right"
};

const Dashboard = ({ history }) => {
  const [leavePlan, setLeavePlan] = React.useState({
    loading: true,
    data: [],
    error: null
  });
  const [commentsRequest, setCommentsRequest] = React.useState(false);
  const [po, setPo] = React.useState('');



  const [leaveRequest, setLeaveRequest] = React.useState({
    loading: true,
    data: [],
    error: null
  });

  const [details, setDetails] = React.useState({})

  const [annualLeave, setAnnualLeave] = React.useState({
    leavedaysentitlement: "",
    annualleavededuction: "",
    leavedaysleft: "",
    leavedaystaken: ""
  });
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [leaveType, setLeaveType] = React.useState(false);
  const [signature, setSignature] = React.useState([]);
  const [name, setName] = useState('')
  const [leavePlanPaginationLength, setLeavePlanPaginationLength] = useState(10)
  const [leaveRequestPaginationLength, setLeaveRequestPaginationLength] = useState(10)

  const getLeavePlan = (data) => {
    console.log(data);

    setLeavePlanPaginationLength(JSON?.parse(data?.headers['x-pagination'])?.TotalCount);
    console.log(data?.headers)
    console.log("Logging Date leave plane", data.data.result);

    data?.data && data.data.result
      ? setLeavePlan({
        ...leavePlan,
        loading: false,
        data: data.data.result
      })
      : setLeavePlan({
        ...leavePlan,
        loading: false,
        error: data.data.message
      });
  };

  const declinedApprover =(data)=>{
    console.log(data)
    let approver = data?.approvers?.filter((item)=>{
      return item?.status === 8
    })
    if(Array.isArray(approver) && approver.length > 0){
      return `${approver[0]?.approverName} ( ${approver[0]?.role})`
    }
    return ""
  }

  const close = () => {
    setModalShow(false)
  }

  const statusColor = (status) => {
    if (status === "Approved") {
      return "green"
    }
    else if (status === "Declined") {
      return "red"
    }
    else if(status === "Saved"){
      return "grey"
    }
    else {
      return "#e6d222"
    }
  }

  const getLeavePlanSignature = (data) => {
    console.log(data)
    setSignature(data?.result)
    console.log(signature)
  }

  const hanldleLeavePlanPaginationChange = (page, pageSize) => {
    GetwithMultipleQueryString(URLAPI.LeavePlan.Get, { page: page, size: 10 }, getLeavePlan);
  }

  const hanldleLeaveRequestPaginationChange = (page, pageSize) => {
    GetwithMultipleQueryString(URLAPI.LeaveRequest.Get, { page: page, size: 10 }, getLeaveRequest);
  }

  const getLeavePlanComment = (data) => {
    setPo('Leave Plan');
    console.log("get leave Plan comment", data);
    setCommentsRequest(data.result);
    setName('plan');
    setModalShow(true)
  };


  const getLeaveRequestComment = (data) => {
    setPo('Leave Request')
    console.log("get leave Request comment", data);
    setCommentsRequest(data.result)
    setName('request')
    setModalShow(true)
  };



  const getLeaveRequest = (data) => {
    console.log("get leave Request", data);
    console.log(JSON?.parse(data?.headers['x-pagination'])?.TotalCount)
    setLeaveRequestPaginationLength(JSON?.parse(data?.headers['x-pagination'])?.TotalCount)

    console.log("Logging Date leave Request", data.data);

    data && data.data.result
      ? setLeaveRequest({
        ...leaveRequest,
        loading: false,
        data: data?.data?.result
      })
      : setLeaveRequest({
        ...leaveRequest,
        loading: false,
        error: data?.data?.message
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Leave Management-Dashboard";

    Get(URLAPI.LeaveType.Get, (data) => {
      console.log("Logging leave plan leave type 1", leavePlan);
      // setLeavePlan({
      //   ...leavePlan,
      //   loading: true
      // });

      console.log("Logging leave plan leave type", leavePlan);
      if (data) {
        if (data.statusCode === "00") {
          console.log("Logging leave types", data);
          // setLeavePlan({
          //   ...leavePlan,
          //   loading: false
          // });
          if (data.result) {
            data.result.forEach(
              (item) =>
                item.leaveName.toLowerCase() === "annual" &&
                GetwithQueryString(
                  URLAPI.LeavePlan.leaveSessionCont,
                  {
                    name: "leavetypeid",
                    value: item.id
                  },
                  (data) => {
                    console.log("Logging annmual Leave dashboard details", data);
                    data &&
                      data.statusCode === "00" &&
                      data.result &&
                      
                      setAnnualLeave({ ...annualLeave, ...data.result });
                  }
                )
            );
          }
        } else if (data.statusCode === "01") {
          console.log("Logging leave types", data);
          // setLeavePlan({
          //   ...leavePlan,
          //   loading: false
          // });
          if (data.apiMessage) {
            setErrorMsg(data.apiMessage);
            setShowErrorModal(true);
          }
        } else {
          // setLeavePlan({
          //   ...leavePlan,
          //   loading: false
          // });
          setErrorMsg("Network Error");
          setShowErrorModal(true);
        }
      }
    });

    GetwithMultipleQueryString(URLAPI.LeavePlan.Get, { page: 1, size: 10 }, getLeavePlan);
    GetwithMultipleQueryString(URLAPI.LeaveRequest.Get, { page: 1, size: 10 }, getLeaveRequest);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-100">

      {modalShow === true &&
        <Modal
          data={details}
          signature={signature}
          close={close}
          comments={commentsRequest}
          name={name}
          p={po}
        />}

      {leavePlan.loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="w-100 flex flex-v-center space-between">
            <div>
              {/* <h5 className="m-b-3">{TimeController.greet() || "Unknown"}</h5> */}
              <h5 className="m-b-3">Good Day</h5>
              <h3 className="m-b-3">{Cookies.get("fullname") || "Osagie Osaigbovo; #79843"}</h3>
              <h5>{Cookies.get("role") || "Officer 1, Ministry of Education | Grade Level 8"}</h5>
            </div>
            {/* <LocationDetails /> */}
          </div>
          <div className="flex wrap">
            <div className="row">
              <div className="col-6 no-margin">
                <DashboardLeaveCard
                  title="Annual Leave Plan"
                  header="Leave Plan Setup"
                  icon={<GiPalmTree fontSize="30px" />}
                  cardDetail={[
                    {
                      detailName: "Leave Entitlement",
                      detailValue:
                        annualLeave.leavedaysentitlement && annualLeave.leavedaysentitlement
                    },
                    {
                      detailName: "Leave Deduction",
                      detailValue:
                        annualLeave.annualleavededuction && annualLeave.annualleavededuction
                    },
                    {
                      detailName: "Available Days",
                      detailValue: annualLeave.leavedaysleft && annualLeave.leavedaysleft
                    },
                    {
                      detailName: "Leave Days Taken",
                      detailValue: annualLeave.leavedaystaken && annualLeave.leavedaystaken
                    }
                    // { detailName: "Status", detailValue: "******" },
                  ]}
                />
              </div>
              {/* <div className="col-4 no-margin">
              <DashboardLeaveCard
                title="Annual Leave Requested"
                header="Request for a Leave"
                cardDetail={[
                  { detailName: "Available Days", detailValue: 0 },
                  { detailName: "Day Requested", detailValue: 0 },
                  { detailName: "Days Left", detailValue: 0 },
                  // { detailName: "Status", detailValue: "******" },
                ]}
              />
            </div> */}
              <div className="col-6 no-margin">
                <DashboardLeaveCard
                  title="Upcoming Leave"
                  header="Leave Resumption"
                  cardDetail={[
                    {
                      detailName: "Start Date",
                      detailValue: `${leavePlan.data.length > 0
                        ? moment(leavePlan.data[0].startDate).format("DD/MM/YYYY")
                        : "00/00/0000"
                        }`
                    },
                    {
                      detailName: "End Date",
                      detailValue: `${leavePlan.data.length > 0
                        ? moment(leavePlan.data[0].endDate).format("DD/MM/YYYY")
                        : "00/00/0000"
                        }`
                    },
                    {
                      detailName: "Duration",
                      detailValue: `${leavePlan.data.length > 0 ? leavePlan.data[0].leaveDuration : 0
                        }`
                    }
                    // { detailName: "Status", detailValue: "*****" },
                  ]}
                />
              </div>
              {/* <div className="col-3 no-margin">
              <DashboardLeaveCard
                title="Leave Allowance"
                header="Request for Leave Allowance"
                icon={<FaRegMoneyBillAlt fontSize="30px" />}
                cardDetail={[
                  { detailName: "Amount", detailValue: "**" },
                  { detailName: "Requested", detailValue: "**" },
                  { detailName: "Pay Status", detailValue: "***" },
                ]}
              />
            </div> */}
            </div>
          </div>
          {/* ******* */}
          <div>
            <div className="bold-text m-t-10">
              <h3>Leave Plan</h3>
              {console.log("Logging Leave Planll", leavePlan.data)}
            </div>
            <div className="table-view m-t-10 w-100">
              {leavePlan.data.length < 1 ? null : (
                <div className="table-header">
                  <div
                    className="table-row"
                    style={{ borderBottom: "1px solid gray", ...tableHeader }}>
                    <div className="th" style={{ width: "max-content", padding: "17px 10px" }}>
                      <p>Session</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>Start Date</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>End Date</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>Leave Type</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>Duration</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>Relief Officer</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>Approver Name</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>Status</p>
                    </div>

                  </div>
                </div>
              )}
              <div className="table-body">
                {leavePlan.data.length === 0 ? (
                  <div className="flex flex-h-center flex-v-center bolder-text">
                    There are no leave plan(s) yet
                  </div>
                ) : (
                  leavePlan.data.map(
                    (item) => (
                      <div className="table-row pointer"
                        onClick={() => {
                          setDetails(item);
                          Get(URLAPI.LeaveComments.planComment, getLeavePlanComment, item.id)
                        }}
                        key={item.id}>
                        {console.log("Logging mapping ", item.leaveSession, item.reliefOfficer)}
                        <div className="td">
                          {/* {item} */}
                          {item.leaveSession}
                        </div>
                        <div className="td" style={{ ...centerContent }}>
                          {moment(item.startDate).format("MMMM Do, YYYY")}
                        </div>
                        <div className="td" style={{ ...centerContent }}>
                          {moment(item.endDate).format("MMMM Do, YYYY")}
                        </div>
                        <div className="td" style={{ ...centerContent }}>
                          {item.leaveType}
                        </div>
                        <div className="td" style={{ ...centerContent }}>
                          {/* {daysEntitled} */}
                          {item.leaveDuration}
                        </div>
                        <div className="td" style={{ ...centerContent }}>
                          {item.reliefOfficer}
                        </div>
                        <div className="td" style={{ ...centerContent }}>
                          {item.statusName === "Declined" ? declinedApprover(item) : item.approverName}
                        </div>
                        <div className="td actions" style={{ ...centerContent }}>
                          <div className="flex">
                            <div
                              style={{
                                backgroundColor: statusColor(item?.statusName),
                                // padding: "0 0.8rem",
                                width: "16px",
                                height: "16px",
                                borderRadius: "50%",
                                alignContent:"center",
                                justifyContent:"center",
                                marginRight:"4px",
                                marginTop:"4px",
                                ...centerContent,
                              }}
                            >
                            </div>
                            <div>
                              {(item.statusName !== "Approved" && item.statusName !== "Declined") ? "Pending" : item.statusName}
                            </div>
                          </div>

                          {/*
                            <p
                              style={{
                                display: "table-cell",
                                fontSize: "13px",
                                color: "#43425d",
                              }}
                            >
                              Submit Request
                            </p>
                          </div> */}

                          <div>
                            {/* <button type="button" className="pointer">
                              <BiEditAlt />
                            </button> */}
                            {/* <button
                              type="button"
                              className="m-l-10 del pointer"
                            >
                              <GiTrashCan />
                            </button> */}
                          </div>
                        </div>
                      </div>
                    )
                  )
                )

                }
              </div>
              {leavePlanPaginationLength > 0 && <Pagination onChange={hanldleLeavePlanPaginationChange} defaultCurrent={1} pageSize={leavePlanPaginationLength} />}
            </div>
          </div>

          {/* ****************************************************************************************** */}
          <div>
            <div className="bold-text m-t-10">
              <h3>Leave Request</h3>
              {console.log("Logging Leave Planll", leaveRequest.data)}
            </div>
            <div className="table-view m-t-10 w-100">
              {leaveRequest.data.length < 1 ? null : (
                <div className="table-header">
                  <div
                    className="table-row"
                    style={{ borderBottom: "1px solid gray", ...tableHeader }}>
                    <div className="th" style={{ width: "max-content", padding: "17px 10px" }}>
                      <p>Session</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>Start Date</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>End Date</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>Leave Type</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>Duration</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>Relief Officer</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>Approver Name</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>Status</p>
                    </div>
                  </div>
                </div>
              )}
              <div className="table-body">
                {leaveRequest?.data?.length === 0 ? (
                  <div className="flex flex-h-center flex-v-center bolder-text">
                    There are no leave plan(s) yet
                  </div>
                ) : (
                  leaveRequest?.data?.map(
                    (
                      item
                    ) => (
                      <div className="table-row pointer"

                        onClick={() => {
                          setDetails(item);
                          Get(URLAPI?.LeaveComments?.RequestComment, getLeaveRequestComment, item.id)
                        }}
                        key={item.id}>
                        {console.log("Logging mapping ", item.leaveSession, item.reliefOfficer)}
                        <div className="td">
                          {/* {item} */}
                          {item.leaveSession}
                        </div>
                        <div className="td" style={{ ...centerContent }}>
                          {moment(item.startDate).format("MMMM Do, YYYY")}
                        </div>
                        <div className="td" style={{ ...centerContent }}>
                          {moment(item.endDate).format("MMMM Do, YYYY")}
                        </div>
                        <div className="td" style={{ ...centerContent }}>
                          {item.leaveType}
                        </div>
                        <div className="td" style={{ ...centerContent }}>
                          {/* {daysEntitled} */}
                          {item.leaveDuration}
                        </div>
                        <div className="td" style={{ ...centerContent }}>
                          {item.reliefOfficer}
                        </div>
                        <div className="td" style={{ ...centerContent }}>
                          {item.statusName === "Declined" ? declinedApprover(item) : item.approverName}
                        </div>
                        <div className="td actions" style={{ ...centerContent }}>
                        <div className="flex">
                            <div
                              style={{
                                backgroundColor: statusColor(item?.statusName),
                                // padding: "0 0.8rem",
                                width: "16px",
                                height: "16px",
                                borderRadius: "50%",
                                alignContent:"center",
                                justifyContent:"center",
                                marginRight:"4px",
                                marginTop:"4px",
                                ...centerContent,
                              }}
                            >
                            </div>
                            <div>
                              {(item.statusName !== "Approved" && item.statusName !== "Declined") ? "Pending" : item.statusName}
                            </div>
                          </div>
                          {/* <div
                            style={{
                              backgroundColor: "#caddc1",
                              padding: "0 0.8rem",
                              cursor: "pointer",
                              borderRadius: "70px",
                              ...centerContent,
                            }}
                          >
                            <p
                              style={{
                                display: "table-cell",
                                fontSize: "13px",
                                color: "#43425d",
                              }}
                            >
                              Submit Request
                            </p>
                          </div> */}
                          <div>
                            {/* <button type="button" className="pointer">
                              <BiEditAlt />
                            </button> */}
                            {/* <button
                              type="button"
                              className="m-l-10 del pointer"
                            >
                              <GiTrashCan />
                            </button> */}
                          </div>
                        </div>
                      </div>
                    )
                  )
                )}
              </div>
              {leaveRequestPaginationLength > 0 && <Pagination onChange={hanldleLeaveRequestPaginationChange} defaultCurrent={1} total={leaveRequestPaginationLength} />}
            </div>
          </div>






          {showErrorModal && (
            <div
              className="overlay flex"
              style={{
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0px 2px 6px #0000000A"
              }}>
              <ErrorModal action={errorMsg} closeModal={() => setShowErrorModal(false)} />
            </div>
          )}
          {/* <div className="m-t-20">
        {[
          {
            id: 1,
            title: "Relief Officer’s Leave Request",
            content: [
              {
                staffName: "Chukwunonso Ugochukwu",
                leaveType: "Annual",
                startDate: "Sept. 20, 2020",
                endDate: "Dec. 20, 2020",
                duration: 40,
                status: "Approved",
              },
            ],
          },
        ].map(({ id, title, content }) => (
          <Accordion title={title} key={id} content={content} />
        ))}
      </div>  */}
        </>
      )}

    </div>
  );
};

export default Dashboard;
