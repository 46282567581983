import * as React from "react";
import {useState} from 'react';
import moment from "moment";
import { IoMdClose } from "react-icons/io";
import MemoPDF from '../inputs/MemoPDF';
import PrintPDF from '../inputs/printPDF';
import Cookies from "js-cookie";
import LocationDetails from "../layouts/LocationDetails";
import DashboardLeaveCard from "../layouts/DashboardLeaveCard";
import Accordion from "../layouts/Accordion";
import LoadingSpinner from "../../resources/loadingSpinner";
import TimeController from "../../controllers/TimeController";
import ErrorModal from "../layouts/ErrorModal";
// import Modal from "../layouts/Modal";

// Icons
import { GiPalmTree } from "react-icons/gi";

// Api
import { URLAPI } from "../../utility/ApiMethods";
import { Get, GetwithQueryString } from "../../utility/fetch";

const Modal = (props) => {

  const [show, setShow] = useState(false);
  
  console.log(props.data)
  const tableHeader = {
    // borderBottom: 'rgba(28,129,28,.5)'
    // padding: "15px 0"
  };

  const closeModal =()=>{
    setShow(false);
  }

 const openModal =()=>{
    setShow(true)
    
  }
  
  const centerContent = {
    textAlign: "left"
  };
  return (
    <div className="bg-modal" >
      <div className="modal-content flex" style={{maxWidth:"900px", minWidth:"800px"}}>
      <div className='w-100'>
            <div className="bold-text m-t-10" >
              <h3>Approvers Comment ({props.p})</h3>
              {/* {console.log("Logging Leave Planll", comments.data)} */}
            </div>
            <div className="table-view m-t-10 w-100">
              {props?.comments?.length < 1? null : (
                <div className="table-header">
                  <div
                    className="table-row"
                    style={{ borderBottom: "1px solid gray", ...tableHeader }}>
                    <div className="th" style={{ width: "max-content", padding: "17px 10px", maxWidth:"30%" }}>
                      <p>Approver</p>
                    </div>
                    <div className="th" style={{ ...centerContent, maxWidth:"30%" }}>
                      <p>Approval Date</p>
                    </div>
                    {/* <div className="th" style={{ ...centerContent }}>
                      <p>End Date</p>
                    </div> */}
                    <div className="th" style={{ ...centerContent }}>
                      <p>Comment</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>Signature</p>
                    </div>
                    {/* <div className="th" style={{ ...centerContent }}>
                      <p>Relief Officer</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>Approver Name</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>Status</p>
                    </div> */}
                  </div>
                </div>
              )}
              <div className="table-body">
                {props?.comments?.length === 0 ? (
                  <div className="flex flex-h-center flex-v-center bolder-text">
                    There are no Comments on this request(s) yet
                  </div>
                ) : (
                  props?.comments?.map(
                    ({
                      id,
                      comment,
                      commentor,
                      modifiedAt,
                      signature64,
                      // startDate,
                      // endDate,
                      // leaveDuration,
                      // daysEntitled,
                      // reliefOfficer,
                      // approverName,
                      ...rest
                    }) => (
                      <div className="table-row"
                      
                      // onClick={()=>{
                      //   Get(URLAPI.LeaveComments.RequestComment, getLeavePlanComment, id)
                                            
                      //                     }}                  
                      key={id}>
                        {/* {console.log("Logging mapping ", leaveSession, reliefOfficer)} */}
                        <div className="td">
                          {/* {item} */}
                          {commentor}
                        </div>
                        <div className="td" style={{ ...centerContent, maxWidth:"30%" }}>
                          {moment(modifiedAt).format("MMMM Do, YYYY")}
                        </div>
                       
                        <div className="td" style={{ ...centerContent }}>
                          {comment}
                          
                        </div>

                        
                        <img 
                                    alt="signature"
                                    className="td" 
                                    style={{ width: "160px", height: "70px", textAlign: "left" }}
                                    
                                    src={`data:image/png;base64, ${signature64}`}
                                  />
                        
             
                      </div>
                    )
                  )
                )}
              </div>
              
            </div>
            {
               (props.data.statusName === 'Approved') &&
                  <div className="mb5 flex justify-content-center m-b-20">
                    <button style={{ width: "100%" }} className='btn btn ' onClick={openModal}>View Document</button>
                  </div>
            }
            
          </div>
        {/* <div onClick = {props.close} className="close pointer">+</div> */}
        <IoMdClose
          className="pointer btn-round"
          style={{
            fontSize: "24px",
            color: "#fff",
            backgroundColor: "red",
            padding: "3px",
          }}
          onClick={props.close}
        />
      </div>
      {
       show  &&
        <MemoPDF data = {props?.data} closeModal = {closeModal} comments = {props?.comments} name = {props.name} />
      }
   

   {/* {
       show  &&  
   <PrintPDF/>

      } */}




    </div>
  );
};

export default Modal;
