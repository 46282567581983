import * as React from "react";
import { IoMdClose } from "react-icons/io";
import TextInput from "../inputs/TextInput";
import FormDateInput from "../inputs/FormDateInput";
import SuccessModal from "./SuccessModal";
import CheckboxInput from "../inputs/CheckboxInput";
import moment from "moment";
import notification from "../../utility/notification";

// Api
import { Post, Get } from "../../utility/fetch";
import { URLAPI } from "../../utility/ApiMethods";

const EditHoliday = ({
  closeModal,
  leaveDetails,
  setLeaveDetails,
  editLeave,
  ...props
}) => {
  const [modalValues, setModalValues] = React.useState({
    holidayName: "",
    holidayDate: "",
    isDeductible: false,
    daysDeducted: 0,
    createdAt: moment().format("YYYY-MM-DDTHH:mm"),
  });
  const [success, setSuccess] = React.useState(false);
  const [modalError, setModalError] = React.useState("");

  //   console.log("Logging Current Leave", editLeave);

  const getLeaveDetails = (data) => {
    console.log("Logging leave PERIOD gotten", data);

    data && data.result
      ? setLeaveDetails({
          ...leaveDetails,
          loading: false,
          holiday: [...data.result],
        })
      : setLeaveDetails({
          ...leaveDetails,
          loading: false,
          error: data.message,
        });
  };

  const getResponse = (response) => {
    console.log("Response of posting leave period", response);
    response && response.statusCode === "00" && setSuccess(true);
    response &&
      response.statusCode === "00" &&
      Get(URLAPI.HolidaySetup.Get, getLeaveDetails);
  };

  const submitForm = (e) => {
    e.preventDefault();
    console.log("Work in Progress", modalValues);
    if (
      modalValues.holidayDate < moment(`${new Date()}`).format("YYYY-MM-DD") ||
      moment().format("YYYY-MM-DD") > modalValues.holidayDate
    ) {
      setModalError("Invalid holiday Date");
      console.log("error true");
    } else {
      Post(modalValues, URLAPI.HolidaySetup.Post, getResponse);
      setModalError("");
    }
    // setSuccess(true);
  };

  const closeSuccessModal = () => {
    setSuccess(false);
    closeModal();
  };

  React.useEffect(() => {
    console.log("Logging Current Leave", editLeave);
    setModalValues({
      ...editLeave,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="overlay flex"
      style={{
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 2px 6px #0000000A",
      }}
    >
      {!success ? (
        <div className="w-100">
          <IoMdClose className="close-btn pointer" onClick={closeModal} />
          <div className="modal-box max-w-700">
            <div className="content">
              <form className="w-100" onSubmit={(e) => submitForm(e)}>
                <TextInput
                  className="w-100 m-b-10"
                  label="Holiday Name"
                  value={modalValues.holidayName}
                  onChange={(e) =>
                    setModalValues({
                      ...modalValues,
                      holidayName: e.target.value,
                    })
                  }
                  required
                />
                <div className="flex my-10 w-100 space-between">
                  <FormDateInput
                    name="HolidayDate"
                    label="Holiday Date"
                    className="w-100"
                    value={modalValues.holidayDate}
                    onChange={(e) =>
                      setModalValues({
                        ...modalValues,
                        holidayDate: e.target.value,
                      })
                    }
                    required
                  />
                </div>
                <CheckboxInput
                  name="deductible"
                  label="Deductible"
                  value={modalValues.isDeductible}
                  onChange={(e) =>
                    setModalValues({
                      ...modalValues,
                      isDeductible: e.target.checked,
                    })
                  }
                  labelMargin="2px  0 0px 7px"
                  required
                />
                {modalError &&
                  notification({
                    title: "Modal Error!",
                    message: `${modalError}`,
                    type: "danger",
                  })}
                {modalError &&
                  setTimeout(() => {
                    setModalError("");
                  }, 5000)}
                <button
                  type="submit"
                  className="btn btn-large w-100 m-t-5"
                  onClick={() => {
                    setModalValues({
                      ...modalValues,
                      daysDeducted: modalValues.isDeductible
                        ? Number(
                            `${moment(`${modalValues.holidayDate}`).diff(
                              moment().format("YYYY-MM-DD"),
                              "days"
                            )}`
                          )
                        : 0,
                    });
                  }}
                >
                  Edit Holiday
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <SuccessModal closeModal={closeSuccessModal} />
      )}
    </div>
  );
};

export default EditHoliday;
