import * as React from "react";
import TextareaInput from "../inputs/TextareaInput";
import { IoMdClose } from "react-icons/io";
import LoaderSrc from "../../assets/svg/loading.svg";
import LocationDetails from "./LocationDetails";
import notification from "../../utility/notification";

const CommentModal = ({ closeModal, ...props }) => {
  const [loading, setLoading] = React.useState(false);
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    props.setState({ ...props.state, comment: "" });
  }, []);
  return (
    <div
      className="overlay flex w-100"
      style={{
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 2px 6px #0000000A",
        // 
      }}
    >
      <div>
        <IoMdClose className="close-btn pointer" onClick={closeModal} />
        <div className="modal-box  pd-10" style={{ width: "550px", }}>
          <TextareaInput
            label={"Add Comment"}
            value={props.state.comment}
            rows={5}
            onChange={(e) => {
              props.setState({ ...props.state, comment: e.target.value });
              //   setComment(e.target.value);
            }}

          />
          {props.details?.handoverNote && <div>
            <p onClick={() => { setShow(!show) }} style={{ padding: "5px", cursor: "pointer", textAlign: "center", backgroundColor: "#caddc1", margin: "0 auto", width: "60%", }}>View Handover Note</p>
            {show && <div style={{ margin: "0 auto", padding: "20px" }}>
              <p><span className='boldz'>{props.details?.userName}</span> is embarking on <span className='boldz'>{props.details?.leaveType}</span> Leave. </p>
              <p>This is expected to last <span className='boldz'>{props.details?.leaveDuration}</span> day(s) from <span className="boldz">{props.details?.startDate}</span> to <span className="boldz">{props.details?.endDate}</span>. </p>
              <br />
              <p>The following hand over note was attached for your reading:  </p>
              <br />
              <p> From: <span className="boldz" style={{ fontSize: "17px", color: "blue" }}>{props.details?.userName}</span>. </p>

              <p className="flex" style={{ width: "100%", alignItems: "center", }}>
                <span style={{ width: "10%", }}> Note:</span>
                <span className="boldz" style={{ width: "100%", marginLeft: "3px", }}>{props.details?.handoverNote}</span>. </p>
            </div>}


          </div>}
          <button
            className="btn btn-large p-r w-100 flex m-t-10 flex-v-center flex-h-center justify-content-center m-t-20"
            onClick={() => {
              if (props.state.comment === "") {
                notification({
                  title: "Validation Warning",
                  message: "Please input your comment",
                  type: "warning",
                })
              }
              else {
                setLoading(true);
                props.action();
              }

            }}
            disabled={loading}
            style={{ height: "43px" }}
          >
            <p style={{ margin: "auto 15px auto" }}>{props.btnText}</p>
            {loading && (
              <img src={LoaderSrc} className="btn-loading" alt="Loading..." />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CommentModal;
