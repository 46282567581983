import React, { useState, useEffect } from "react";
import moment from "moment";
import LeaveApply from "../layouts/LeaveApply";
import Modal from '../layouts/Modal';
import LoadingSpinner from "../../resources/loadingSpinner";
import CommentModal from "../layouts/CommentModal";
import SearchInput from "../inputs/SearchInput";
import "../../index.css";
// Api
import { Get, Put } from "../../utility/fetch";
import { URLAPI } from "../../utility/ApiMethods";

// Icons
import SuccessModal from "../layouts/SuccessModal";
import Cookies from "js-cookie";
import notification from "../../utility/notification";


const pageTitle = {
  fontSize: "1.3rem"
};

const tableHead = {
  padding: "15px",
  textAlign: "left"
};

const tableContent = {
  // whiteSpace: "nowrap",
  textAlign: "left"
};

const centerContent = {
  textAlign: "left"
};

const submitBtn = {
  backgroundColor: "#caddc1",
  padding: "0 0.8rem",
  cursor: "pointer",
  borderRadius: "70px",
  marginLeft: "auto"
};

const submitText = {
  display: "table-cell",
  fontSize: "13px",
  color: "#43425d"
};

export default function LeaveRequestReview(props) {
  const [On, setOn] = useState(false);
  const [showLeaveApply] = useState(false);
  const [actionMsg, setActionMsg] = useState("");
  const [showComment, setShowComment] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);
  const [showDeleteComment, setShowDeleteComment] = useState(false);
  const [showRequestComment, setShowRequestComment] = useState(false);
  const [leavePlan, setLeavePlan] = useState({ loading: false, data: null });
  const [OriLeavePlan, setOriLeavePlan] = useState({ loading: false, data: null });
  const [leavePlanApprove, setLeavePlanApproved] = useState([]);
  const [leaveRequestApprove, setLeaveRequestApproved] = useState([]);

  const [declineRequestComment, setDeclineRequestComment] = useState(false);
  const [leaveRequest, setLeaveRequest] = useState({ loading: false, data: null });
  const [OriLeaveRequest, setOriLeaveRequest] = useState({ loading: false, data: null });

  const [requestDetails, setRequestDetails] = useState({});
  const [searchText, setSearchText] = useState('')
  const [approvePlan, setApprovePlan] = useState({
    comment: "",
    leaveId: null,
    leaveStatus: 0,
    approvalStatus: 1
  });
  const [approveRequest, setApproveRequest] = useState({
    comment: "",
    leaveId: null,
    leaveStatus: 1
  });



  const searchTable = (data) => {
    setSearchText(data);
  }

  const updateLeavePlan = (data) => {
    console.log(data)
    setOriLeavePlan(data?.result)
    Cookies.get('fromDashboard') === 'true' ?
      setLeavePlan({
        loading: false,
        data: data.result.filter(req => req.id === parseInt(Cookies.get('requestid'))) || [],
        error: data && data.result ? null : data.message
      })
      :
      setLeavePlan({
        loading: false,
        data: data.result || [],
        error: data && data.result ? null : data.message
      });
    Cookies.remove("fromDashboard")

    Cookies.remove("type")

    Cookies.remove("requestid")
  };
  const updateLeaveRequest = (data) => {
    console.log(data)

    setOriLeaveRequest(data?.result)
    Cookies.get('fromDashboard') === 'true' ?
      setLeaveRequest({
        loading: false,
        data: data.result.filter(req => req.id === parseInt(Cookies.get('requestid'))) || [],
        error: data && data.result ? null : data.message
      })
      :
      setLeaveRequest({
        loading: false,
        data: data.result || [],
        error: data && data.result ? null : data.message
      });
    Cookies.remove("fromDashboard")

    Cookies.remove("type")

    Cookies.remove("requestid")
  };

  const leaveRequestApproved = (data) => {
    console.log(data)
    setLeaveRequestApproved(data?.result)
  };

  const leavePlanApproved = (data) => {
    console.log(data)
    setLeavePlanApproved(data?.result)
  };



  const approveleave = () => {

    Put(approvePlan, URLAPI.Approver.approve, (response) => {
      console.log("Approve response", response);
      response &&
        response.statusCode === "00" &&
        Get(`${URLAPI.Approver.getPending}0`, updateLeavePlan);
      response && response.statusCode === "00" && setActionMsg("Leave Plan Approved Succesfully!!");
      response && response.statusCode === "00" && setShowComment(false);
      response && response.statusCode === "00" && setShowSuccess(true);
    });
  };

  const approveleaveRequest = () => {
    Put(approveRequest, URLAPI.Approver.approve, (response) => {
      console.log("Approve response", response);
      response &&
        response.statusCode === "00" &&
        Get(`${URLAPI.Approver.getPending}1`, updateLeaveRequest);
      response &&
        response.statusCode === "00" &&
        setActionMsg("Leave Request Approved Succesfully!!");
      response && response.statusCode === "00" && setShowRequestComment(false);
      response && response.statusCode === "00" && setShowSuccess(true);
    });
  };

  const declinedLeave = () => {
    Put(approvePlan, URLAPI.Approver.approve, (response) => {
      console.log("Approve response", response);
      response.statusCode === "00" && Get(`${URLAPI.Approver.getPending}0`, updateLeavePlan);
      response && response.statusCode === "00" && setActionMsg("Leave Plan Declined Succesfully!!");
      response && response.statusCode === "00" && setShowDeleteComment(false);
      response && response.statusCode === "00" && setShowSuccess(true);
    });
  };

  const declinedLeaveRequest = () => {
    Put(approveRequest, URLAPI.Approver.approve, (response) => {
      console.log("Approve response", response);
      response.statusCode === "00" && Get(`${URLAPI.Approver.getPending}1`, updateLeaveRequest);
      response &&
        response.statusCode === "00" &&
        setActionMsg("Leave Request Declined Succesfully!!");
      response && response.statusCode === "00" && setDeclineRequestComment(false);
      response && response.statusCode === "00" && setShowSuccess(true);
    });
  };

  const handleApproved = () => {
    console.log(leavePlanApprove)
    setOn(true)
    console.log(leaveRequestApprove)
    setLeavePlan({
      loading: false,
      data: leavePlanApprove || [],
      // error: data && data.result ? null : data.message
    });
    // setLeavePlan();
    setLeaveRequest({
      loading: false,
      data: leaveRequestApprove || [],
      // error: data && data.result ? null : data.message
    });
    // setLeavePlan();
    // setLeaveRequest();
  }
  const handleAPending = () => {
    setOn(false)

    console.log(OriLeavePlan)
    console.log(OriLeaveRequest)
    setLeavePlan({
      loading: false,
      data: OriLeavePlan || [],
      // error: data && data.result ? null : data.message
    });
    // setLeavePlan();
    setLeaveRequest({
      loading: false,
      data: OriLeaveRequest || [],
      // error: data && data.result ? null : data.message
    });
    // setLeaveRequest();
  }



  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Leave Management";

    if (Cookies.get("fromDashboard") === 'true' && Cookies.get("type") === 'leaveplan') {
      console.log(Cookies.get("fromDashboard") + Cookies.get("type"))
      Get(`${URLAPI.Approver.getPending}0`, updateLeavePlan);
    }
    else if (Cookies.get("fromDashboard") === 'true') {
      Get(`${URLAPI.Approver.getPending}1`, updateLeaveRequest);
    }
    else {
      Get(`${URLAPI.Approver.getPending}0`, updateLeavePlan);
      Get(`${URLAPI.Approver.getPending}1`, updateLeaveRequest);
      Get(URLAPI.LeaveRequest.GetApproval, leaveRequestApproved);
      Get(URLAPI.LeavePlan.GetApproval, leavePlanApproved);
    }

    // Get(`${URLAPI.Approver.getPending}, updateLeaveRequest);
  }, []);

  return (
    <div className="w-100">
      <div className="flex flex-v-center space-between">
        <div className="bold-text" style={pageTitle}>
          Leave Requests Review
        </div>


        {/* <LocationDetails /> */}
      </div>
      <div className="w-100 m-t-20 flex space-between flex-v-center">

        <div className='flex w-100 m-b-20' >
          <div className='flex '>
            <button onClick={handleAPending} className={On == true ? 'approvePending btn m-r-10' : "approvePending2 btn m-r-10"}>Untreated Requests</button>
            <button onClick={handleApproved} className={On == false ? 'approvePending btn' : "approvePending2 btn"}>Treated Requests</button>
          </div>
          <form style={{ marginLeft: "auto" }}>
            <SearchInput onChange={(e) => searchTable(e.target.value)} />
          </form>
        </div>
      </div>

      <div className="w-100">
        {leavePlan.loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {/* Leave Plan  */}
            {On === false &&
              <div>

                <LeaveRequestTableView
                  data={leavePlan.data || []}
                  title="Leave Plan"
                  searchText={searchText}
                  errorText="You do not have any leave plan record"
                  approveLeaveCallback={(value) => {
                    setRequestDetails(value)
                    setApprovePlan({ ...approvePlan, leaveId: value.id, approvalStatus: 1 });
                    setShowComment(true);
                  }}
                  declineLeaveCallback={(value) => {
                    setRequestDetails(value)
                    setApprovePlan({ ...approvePlan, leaveId: value.id, approvalStatus: 0 });
                    setShowDeleteComment(true);
                  }}
                />

                {/* Leave Request  */}
                <LeaveRequestTableView
                  data={leaveRequest.data || []}
                  searchText={searchText}
                  title="Leave Request"
                  errorText="You do not have any leave request record"
                  approveLeaveCallback={(value) => {
                    setApproveRequest({ ...approveRequest, leaveId: value.id, approvalStatus: 1 });
                    setRequestDetails(value)
                    setShowRequestComment(true);
                  }}
                  declineLeaveCallback={(value) => {
                    setApproveRequest({ ...approveRequest, leaveId: value.id, approvalStatus: 0 });
                    setRequestDetails(value)
                    setDeclineRequestComment(true);
                  }}
                />
              </div>
            }

            {(On === true) && <div>
              <LeaveApproveTableView
                data={leavePlan.data || []}
                title="Treated Leave Plan"
                errorText="You do not have any leave plan record"
                searchText={searchText}
              />

              <LeaveApproveTableView
                data={leaveRequest.data || []}
                title="Treated Leave Request"
                errorText="You do not have any leave request record"
                searchText={searchText}
              />
            </div>}

            {showLeaveApply && <LeaveApply />}

            {showComment && (
              <CommentModal
                closeModal={() => setShowComment(false)}
                state={approvePlan}
                setState={setApprovePlan}
                action={approveleave}
                // details ={requestDetails}
                btnText={"Approve Plan"}
              />
            )}

            {showDeleteComment && (
              <CommentModal
                closeModal={() => setShowDeleteComment(false)}
                state={approvePlan}
                setState={setApprovePlan}
                action={declinedLeave}
                // details ={requestDetails}
                btnText={"Decline Plan"}
              />
            )}

            {showRequestComment && (
              <CommentModal
                closeModal={() => setShowRequestComment(false)}
                state={approveRequest}
                setState={setApproveRequest}
                action={approveleaveRequest}
                btnText={"Approve Request"}

                details={requestDetails}
              />
            )}

            {declineRequestComment && (
              <CommentModal
                closeModal={() => setDeclineRequestComment(false)}
                state={approveRequest}
                setState={setApproveRequest}
                action={declinedLeaveRequest}
                details={requestDetails}
                btnText={"Decline Request"}
              />
            )}



            {showSuccess && (
              <div
                className="overlay flex"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0px 2px 6px #0000000A"
                }}>
                <SuccessModal action={actionMsg} closeModal={() => setShowSuccess(false)} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function LeaveRequestTableView({ approveLeaveCallback, searchText, declineLeaveCallback, ...props }) {

  const [modalShow, setModalShow] = React.useState(false);
  const [details, setDetails] = React.useState({});
  const [name, setName] = useState('')
  const [commentsRequest, setCommentsRequest] = React.useState(false);
  const [po, setPo] = React.useState('');

  const getLeavePlanComment = (data) => {
    setPo('Leave Plan')
    console.log("get leave Plan comment", data);
    setCommentsRequest(data.result)
    setName('plan');

    setModalShow(true)
  };

  const closeModal = () => {
    setModalShow(false)

  }

  const getLeaveRequestComment = (data) => {
    setPo('Leave Request')
    console.log("get leave Request comment", data);
    setCommentsRequest(data.result)
    setName('request')
    setModalShow(true)
  };

  console.log(searchText)

  return (
    <>
      <p style={{ fontSize: "16px", fontWeight: "700" }}>{props.title}</p>
      <div className="table-view m-t-10">
        {Array.isArray(props.data) && props.data.filter((item) => item?.status === 3).length === 0 ? (
          <div className="flex flex-h-center flex-v-center">{props.errorText}</div>
        ) : (
          <>
            <div className="table-header">
              <div className="table-row">

                <div className="th w-max-content" style={tableHead}>
                  <p className="san-sherif">Session</p>
                </div>
                <div className="th" style={tableHead}>
                  <p className="san-sherif">Initiator</p>
                </div>
                <div className="th" style={tableHead}>
                  <p className="san-sherif">Start Date</p>
                </div>
                <div className="th" style={tableHead}>
                  <p className="san-sherif">End Date</p>
                </div>
                <div className="th" style={tableHead}>
                  <p className="san-sherif">Leave Type</p>
                </div>
                <div className="th" style={tableHead}>
                  <p className="san-sherif">Leave Duration</p>
                </div>
                <div className="th" style={tableHead}>
                  <p className="san-sherif">Relief Officer</p>
                </div>

                <div className="th right-text" style={tableHead}>
                  <p className="san-sherif">Action</p>
                </div>
              </div>
            </div>

            <div className="table-body">
              {Array.isArray(props.data) && props.data.filter((data) => {
                if (
                  data?.reliefOfficer?.toLowerCase().includes(searchText?.toLowerCase()) ||
                  data?.userName?.toLowerCase().includes(searchText?.toLowerCase()) ||
                  data?.leaveType?.toLowerCase().includes(searchText?.toLowerCase())
                ) {
                  console.log(data)
                  return data;
                }
              }).map((value) => (
                <div onClick={() => {
                  setDetails(value);
                  value.resumptionDate ? Get(URLAPI.LeaveComments.RequestComment, getLeaveRequestComment, value.id) :
                    Get(URLAPI.LeaveComments.planComment, getLeavePlanComment, value.id)

                }} className="table-row pointer" key={value.id}>

                  <div className="td" style={tableContent}>
                    {value.leaveSession}
                  </div>
                  <div className="td" style={tableContent}>
                    {value.userName}
                  </div>
                  <div className="td" style={tableContent}>
                    {moment(value.startDate).format("MMMM Do, YYYY")}
                  </div>
                  <div className="td" style={tableContent}>
                    {moment(value.endDate).format("MMMM Do, YYYY")}
                  </div>
                  <div className="td" style={tableContent}>
                    {value.leaveType}
                  </div>
                  <div className="td" style={tableContent}>
                    {value.leaveDuration}
                  </div>
                  <div className="td" style={tableContent}>
                    {value.reliefOfficer}
                  </div>
                  <div className="td actions flex m-l-auto">
                    <div

                      style={{ ...submitBtn, ...centerContent }}
                      onClick={(e) => {
                        e.stopPropagation()
                        approveLeaveCallback(value);
                      }}>
                      <p style={submitText}>{"Approve Leave"}</p>
                    </div>
                    <div


                      style={{
                        ...submitBtn,
                        ...centerContent,
                        backgroundColor: "rgba(200, 111, 111,.8)"
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        declineLeaveCallback(value);
                      }}>
                      <p style={submitText}>{"Decline Leave"}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {modalShow === true &&
        <Modal
          data={details}
          close={closeModal}
          comments={commentsRequest}
          name={name}
          p={po}

        />}
    </>
  );
}

function LeaveApproveTableView(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [details, setDetails] = React.useState({});
  const [name, setName] = useState('')
  const [commentsRequest, setCommentsRequest] = React.useState(false);
  const [po, setPo] = React.useState('');

  const getLeavePlanComment = (data) => {
    setPo('Leave Plan')
    console.log("get leave Plan comment", data);
    setCommentsRequest(data.result)
    setName('plan');

    setModalShow(true)
  };

  const closeModal = () => {
    setModalShow(false)

  }

  const getLeaveRequestComment = (data) => {
    setPo('Leave Request')
    console.log("get leave Request comment", data);
    setCommentsRequest(data.result)
    setName('request')
    setModalShow(true)
  };

  return (
    <>
      <p style={{ fontSize: "16px", fontWeight: "700" }}>{props.title}</p>
      <div className="table-view m-t-10">
        {props.data.length === 0 ? (
          <div className="flex flex-h-center flex-v-center">{props.errorText}</div>
        ) : (
          <>
            <div className="table-header">
              <div className="table-row">

                <div className="th w-max-content" style={tableHead}>
                  <p className="san-sherif">Initiator's Name</p>
                </div>
                <div className="th" style={tableHead}>
                  <p className="san-sherif">Leave Type</p>
                </div>
                <div className="th" style={tableHead}>
                  <p className="san-sherif">Initiation Date</p>
                </div>
                {/* <div className="th" style={tableHead}>
                  <p className="san-sherif">End Date</p>
                </div> */}
                <div className="th" style={tableHead}>
                  <p className="san-sherif">Leave Duration</p>
                </div>
                <div className="th" style={tableHead}>
                  <p className="san-sherif">Approver</p>
                </div>

                <div className="th left-text" style={tableHead}>
                  <p className="san-sherif">Status</p>
                </div>
              </div>
            </div>

            <div className="table-body">
              {Array.isArray(props.data) && props.data.filter((data) => {
                if (
                  data?.firstName?.toLowerCase().includes(props.searchText?.toLowerCase()) ||
                  data?.lastName?.toLowerCase().includes(props.searchText?.toLowerCase()) ||
                  data?.leaveType?.toLowerCase().includes(props.searchText?.toLowerCase()) ||
                  data?.approvalStatus?.toLowerCase().includes(props.searchText?.toLowerCase()) ||
                  data?.approverName?.toLowerCase().includes(props.searchText?.toLowerCase())
                ) {
                  console.log(data)
                  return data;
                }
              }).map((value) => (
                <div onClick={() => {
                  setDetails(value);
                  value.resumptionDate ? Get(URLAPI.LeaveComments.RequestComment, getLeaveRequestComment, value.id) :
                    Get(URLAPI.LeaveComments.planComment, getLeavePlanComment, value.id)
                }
                } className="table-row pointer" key={value.id}>

                  <div className="td" style={tableContent}>
                    {`${value.firstName} ${value.lastName}`}
                  </div>
                  <div className="td" style={tableContent}>
                    {value.leaveType}
                  </div>
                  <div className="td" style={tableContent}>
                    {moment(value.startDate).format("MMM Do, YYYY")}
                  </div>
                  {/* <div className="td" style={tableContent}>
                    {moment(value.endDate).format("MMMM Do, YYYY")}
                  </div> */}
                  <div className="td" style={{ textAlign: "center" }}>
                    {value.leaveDuration} days
                  </div>
                  <div className="td" style={tableContent}>
                    {value.approverName}
                  </div>
                  <div className="td actions flex m-l-auto">
                    <div

                    // onClick={() => approveLeaveCallback(value)}
                    >
                      <p style={submitText}>{value.approvalStatus}</p>
                    </div>
                    {/* <div
                      style={{
                        ...submitBtn,
                        ...centerContent,
                        backgroundColor: "rgba(200, 111, 111,.8)"
                      }}
                      // onClick={() => declineLeaveCallback(value)}
                      >
                      <p style={submitText}>{"Decline Leave"}</p>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {modalShow === true &&
        <Modal
          data={details}
          close={closeModal}
          comments={commentsRequest}
          name={name}
          p={po}

        />}
    </>
  );
}
// eslint-disable-next-line no-lone-blocks
{
  /* <div>
        {transactionInfo.data.map(
          ({ id, status, leaveRequest, lastTreated }) => (
            <TransactionCard
              key={id}
              status={status}
              leaveRequest={leaveRequest}
              lastTreated={lastTreated}
            />
          )
        )}
      </div>
      <div className="m-t-20 flex space-between">
        <div />
        <button
          className="btn btn-large"
          style={{
            padding: "14px 65px",
            borderRadius: "3px",
            whiteSpace: "nowrap",
          }}
          type="button"
        >
          Show more transactions
        </button>
        <div className="flex flex-v-center">
          <p className="m-r-5">Page</p>
          <div
            style={{
              border: "1px solid #1C801C",
              backgroundColor: "#D5FFD5",
              color: "#606775",
              padding: "0.9rem 1.2rem",
            }}
          >
            1
          </div>
          <p className="bolder-text m-l-5 m-r-5">Of 50</p>
          <button className="btn" style={{ height: "100%" }}>
            <BsChevronLeft color="#fff" fontSize="40px" />
          </button>
          <button className="btn" style={{ height: "100%" }}>
            <BsChevronRight color="#fff" fontSize="40px" />
          </button>
        </div>
      </div> */
}
