import React, { Component } from "react";
import { IoMdClose } from "react-icons/io";
import { IoIosSettings } from "react-icons/io";
//import TextareaInput from '../inputs/TextareaInput';
import TextInput from "../inputs/TextInput";
import FormDateInput from "../inputs/FormDateInput";
import { URLAPI } from "../../utility/ApiMethods";
import { Post } from "../../utility/fetch";
import { LoadingSpinner } from "../../resources/loadingSpinner";
import moment from "moment";
/*
const Form = ({ closeModal, details }) => {
  const [role, setRole] = useState('');
  const [description, setDescription] = useState('');
  const [formType, setFormType] = useState('');

  useEffect(() => {
    if (details.id && details.id) {
      setFormType('edit');
      setRole(details.role);
      setDescription(details.description);
    } else {
      setFormType('create');
    }
  }, [details]);
*/

const date = moment().format("YYYY-MM-dd");

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      errorMessage: "",
      button: "Save",
      className: "",
      class: "btn btn-large w-100",
      loading: false,
      visible: false,
      records: [],
      period: "",
      visible: false,
      start_date: moment().format("YYYY-MM-dd"),
      end_date: moment().format("YYYY-MM-dd"),
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  openModal = (id) => {
    const selected = this.state.records.filter((item) => id === item.id);
    if (selected.length > 0) {
      this.setState({ visible: true });
    }
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  submitForm = () => {
    console.log("Work in Progress...");
    this.closeModal();
  };

  onSubmit = (e) => {
    const { start_date, end_date } = this.state;
    let formErrors = "";

    if (!start_date) {
      formErrors = "select start date";
    }
    if (!end_date) {
      formErrors = "select end_date";
    }

    if (formErrors != "") {
      this.setState({ errorMessage: formErrors });
    } else if (formErrors == "") {
      let ratings = {
        Id: this.state.setPeriodId,
        StartDate: this.state.start_date,
        EndDate: this.state.end_date,
      };

      //check if it is one edit or on save
      if (this.state.button === "Save") {
        this.onSubmitSave(ratings);
      } else if (this.state.button === "Update") {
        this.onSubmitUpdate(ratings);
      }
    }
  };

  onSubmitSave = (data) => {
    this.setState({ loading: true }, () => {
      Post(data, URLAPI.AppraisalPeriodPost, this.onSubmitAfterSave);
    });
  };
  onSubmitAfterSave = (data) => {
    console.log(data);

    if (data.code === 1) {
      alert("Record Saved");

      //create array of data submitted
      let period = {
        id: data.id,
        startDate: this.state.start_date,
        endDate: this.state.end_date,
        modifiedBy: data.modifiedBy,
      };

      //clone the records in array
      let _records = [...this.state.records];

      //update the new record into the array
      _records.push(period);

      //commit the new record into array
      this.setState({ records: _records, loading: false });
    } else if (data.code === 0) {
      alert(data.message);
      this.setState({ loading: false });
    } else {
      alert("Error saving data");
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state.loading;
    return (
      <>
        <div className="overlay">
          <IoMdClose className="close-btn pointer" onClick={this.closeModal} />
          <div className="modal-box max-w-400">
            <div className="content">
              <TextInput
                name="period"
                value={this.state.period}
                onChange={(e) => this.handleChange(e)}
                className="w-100 m-b-10"
                label="period"
              />
              <div className="row">
                <div className="col-6 no-margin">
                  <input
                    name="start_date"
                    type="date"
                    value={this.state.start_date}
                    onChange={(newDate) =>
                      this.setState({
                        start_date: moment(newDate).format("yyyy-MM-dd"),
                      })
                    }
                    className="w-100 m-b-10"
                    label="Start Date"
                    format="yyyy-mm-dd"
                  />
                </div>

                <div className="col-6 no-margin">
                  <input
                    name="end_date"
                    value={this.state.end_date}
                    onChange={(newDate) =>
                      this.setState({
                        end_date: moment(newDate).format("yyyy-MM-dd"),
                      })
                    }
                    className="w-100 m-b-10"
                    label="End Date"
                    type="date"
                    format="yyyy-mm-dd"
                  />
                </div>
              </div>
              {loading ? (
                <LoadingSpinner />
              ) : (
                <button
                  type="button"
                  className={this.state.class}
                  onClick={(e) => this.onSubmit(e)}
                >
                  {this.state.button}
                </button>
              )}
              <div className="form-group">
                <div className="text-center" style={{ color: "Red" }}>
                  {" "}
                  {this.state.errorMessage}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Form;
