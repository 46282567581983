import * as React from "react";
import moment from "moment";
import { IoMdClose } from "react-icons/io";
import TextInput from "../inputs/TextInput";
import CheckboxInput from "../inputs/CheckboxInput";
import SelectInput from "../inputs/SelectInput";
import SuccessModal from "./SuccessModal";
import LoaderSrc from "../../assets/svg/loading.svg";

// Api
import { Post, Get } from "../../utility/fetch";
import { URLAPI } from "../../utility/ApiMethods";

const LeaveTypeModal = ({
  closeModal,
  leaveDetails,
  setLeaveDetails,
  ...props
}) => {
  const [modalValues, setModalValues] = React.useState({
    LeaveName: "",
    maxLeaveDays: 0,
    IsDeductible: 0,
    // RequestReason: "false",
    leaveCategory: "",
    CreatedAt: moment().format("YYYY-MM-DDTHH:mm"),
  });
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const getLeaveDetails = (data) => {
    console.log("Logging leave details gotten", data.message);

    data && data.result
      ? setLeaveDetails({
          ...leaveDetails,
          loading: false,
          data: [...data.result],
        })
      : setLeaveDetails({
          ...leaveDetails,
          loading: false,
          error: data.message,
        });
  };

  const getResponse = (response) => {
    console.log("Response of posting leave type", response);
    setLoading(false);
    response &&
      response.statusCode === "00" &&
      Get(URLAPI.LeaveTypeSetup.Get, getLeaveDetails);
    response && response.statusCode === "00" && setSuccess(true);
  };

  const submitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("Work in Progress", modalValues);
    Post({ ...modalValues }, URLAPI.LeaveTypeSetup.Post, getResponse);
  };

  const closeSuccessModal = () => {
    setSuccess(false);
    closeModal();
  };

  return (
    <div
      className="overlay flex"
      style={{
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 2px 6px #0000000A",
      }}
    >
      {!success ? (
        <div>
          <IoMdClose className="close-btn pointer" onClick={closeModal} />
          <div className="modal-box max-w-400">
            <div className="content">
              {/* {details && Object.keys(details).length > 0 ? 
              
            
            } */}
              <form onSubmit={(e) => submitForm(e)}>
                <TextInput
                  className="w-100 m-b-10"
                  label="Leave Name"
                  value={modalValues.LeaveName}
                  onChange={(e) =>
                    setModalValues({
                      ...modalValues,
                      LeaveName: e.target.value,
                    })
                  }
                  required
                />
                <TextInput
                  className="w-100 m-b-10"
                  type="number"
                  label="Number Of Days"
                  value={modalValues.maxLeaveDays}
                  onChange={(e) =>
                    setModalValues({
                      ...modalValues,
                      maxLeaveDays: Number(e.target.value),
                    })
                  }
                  minDate={0}
                  required
                />
                <SelectInput
                  label="Leave Category"
                  options={[
                    { name: "None", value: "None" },
                    { name: "Annual", value: "Annual" },
                  ]}
                  value={modalValues.leaveCategory}
                  onChange={(e) => {
                    console.log("select Value", modalValues.leaveCategory);
                    setModalValues({
                      ...modalValues,
                      leaveCategory: e.target.value,
                    });
                  }}
                  required
                />
                <div className="flex my-10">
                  <CheckboxInput
                    name={"Deductible"}
                    label={"Deductible"}
                    value={modalValues.IsDeductible}
                    onChange={(e) =>
                      setModalValues({
                        ...modalValues,
                        IsDeductible: e.target.checked ? 1 : 0,
                      })
                    }
                  />
                  {/* <CheckboxInput
                    name={"Request Reason(s)"}
                    label={"Request Reason(s)"}
                    overrideStyles={{ marginLeft: "2rem" }}
                    value={modalValues.RequestReason}
                    onChange={(e) =>
                      setModalValues({
                        ...modalValues,
                        RequestReason: `${e.target.checked}`,
                      })
                    }
                  /> */}
                </div>
                <button
                  type="submit"
                  className="btn btn-large p-r w-100 flex  flex-v-center flex-h-center justify-content-center"
                  disabled={loading}
                  style={{ height: "43px" }}
                >
                  <p style={{ margin: "auto 15px auto" }}>
                    {loading ? "Creating Leave Type" : "Create Leave Type"}
                  </p>
                  {loading && (
                    <img
                      src={LoaderSrc}
                      className="btn-loading"
                      alt="Loading..."
                    />
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <SuccessModal
          action={"Leave Type Created Successfully!"}
          closeModal={closeSuccessModal}
        />
      )}
    </div>
  );
};

export default LeaveTypeModal;
