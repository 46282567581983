import * as React from "react";
import { MdCardTravel } from "react-icons/md";

const DashboardLeaveCard = (props) => {
  return (
    <div className="flex flex-v-center m-t-20">
      <div
        className="w-100"
        style={{
          margin: "0",
          padding: "0",
          boxShadow: "0px 2px 6px #0000000A",
          fontSize: "13px",
        }}
      >
        <div
          className=" w-100 flex space-between m-b-10 bg-white"
          style={{ padding: props.headerPadding || "10px" }}
        >
          <h3
            className="my-auto bolder-text"
            style={{ fontSize: props.headerFontSize || "0.9rem" }}
          >
            {props.header}
          </h3>
          <div>{props.icon || <MdCardTravel fontSize="30px" />}</div>
        </div>
        <div className="flex flex-direction-column">
          <div
            className="border-top-green-4 border-bottom-gray-1 w-100"
            style={{
              padding: "10px",
              backgroundColor: props.titleColor || "transparent",
            }}
          >
            {props.title || "Annual Leave Plan"}
          </div>
          {props.cardDetail.map(({ detailName, detailValue }, idx) => (
            <div
              key={idx}
              className="flex space-between pd-y-7 pd-l-7 pd-r-0 border-bottom-gray-1"
              style={{
                backgroundColor: props.bodyColor || "white",
                paddingRight: "0",
              }}
            >
              <p className="  no-margin">{detailName}</p>
              <p
                className=" no-margin bolder-text"
                style={{ fontSize: "13px", paddingRight: "7px" }}
              >
                {detailValue}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardLeaveCard;
