import * as React from "react";
import { IoMdClose } from "react-icons/io";
import TextInput from "../inputs/TextInput";
import FormSearchInput from "../inputs/FormSearchInput";
import SuccessModal from "./SuccessModal";

import LoaderSrc from "../../assets/svg/loading.svg";

// Api
import { Get, Post } from "../../utility/fetch";
import { URLAPI } from "../../utility/ApiMethods";
import ErrorModal from "./ErrorModal";

const LeaveRequestApproverModal = ({
  closeModal,
  leaveDetails,
  setLeaveDetails,
  ...props
}) => {
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [modalValues, setModalValues] = React.useState({
    users:'',
    role: "",
    approvalSequence: "",
    roleId: 0,
  });
  const [roles, setRoles] = React.useState([]);
  const [sugestedRoles, setSuggestedRoles] = React.useState([]);
  const [approverId, setApprovalId] = React.useState(0);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [user, setUser] = React.useState([]);
  const [sugestedUser, setSuggestedUser] = React.useState([]);
  const [userId, setUserId] = React.useState(0);

  const getApproverDetails = (data) => {
    console.log("Logging leave details gotten", data.message);

    data && data.result
      ? setLeaveDetails({
          ...leaveDetails,
          loading: false,
          data: [...data.result],
        })
      : setLeaveDetails({
          ...leaveDetails,
          loading: false,
          error: data.message,
        });
  };

  const getResponse = (response) => {
    console.log("Response of posting leave type", response);

    if (response) {
      if (response.statusCode === "00") {
        setSuccess(true);
        Get(URLAPI.LeaveRequestApprover.Get, getApproverDetails);
      } else if (response.statusCode === "01") {
        console.log(
          "create Leave Request Approval failed",
          response.apiMessage
        );
        setSuccess(false);
        setErrorMsg(response.apiMessage);
        setShowErrorModal(true);
      }
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("Work in Progress...", modalValues);
    Post(
      // {
      //   role: modalValues.role,
      //   approvalSequence: modalValues.approvalSequence,
      //   roleId: approverId,
      // },
      {
        // role: modalValues.role,
        // user: modalValues.users,
        approvalSequence: modalValues.approvalSequence,
        mdaId: approverId,
        approverID: userId
      
  
 
      },
      URLAPI.LeaveRequestApprover.Post,
      getResponse
    );
  };

  const closeSuccessModal = () => {
    setSuccess(false);
    closeModal();
  };

  React.useEffect(() => {
    Get(URLAPI.Mdas.Get, (data) => {
      console.log("Roles are ", data);
      if (data) {
        if (data.statusCode === "00") {
          if (data.result && data.result.length > 0) {
            setRoles([...data.result]);

          }
        } else if (data.statusCode === "01") {
          setRoles("No Available Mdas");
        }
      }
    });
  }, []);

  React.useEffect(() => {
    // GetwithQueryString(URLAPI.Mdas.GetUser,{name:'id', value:approverId}, (data) => {
      modalValues.users =''
      Get(URLAPI.Mdas.GetUser, (data) => {
      console.log("USers are ", data);
      if (data) {
        if (data.statusCode === "00") {
          if (data.result) {
            setUser([...data.result]);
          }
        } else if (data.statusCode === "01") {
          setUser("No Available Approver");
        }
      }
    }, approverId);
  }, [approverId]);


  return (
    <div
      className="overlay flex"
      style={{ alignItems: "center", justifyContent: "center" }}
    >
      {!success && !showErrorModal ? (
        <div style={{ boxShadow: "0px 2px 6px #0000000A" }}>
          <IoMdClose className="close-btn pointer" onClick={closeModal} />
          <div className="modal-box max-w-400">
            <div className="content">
              <form onSubmit={(e) => submitForm(e)}>
                <FormSearchInput
                  className="w-100 m-b-10"
                  name="sequence"
                  label="Select MDA name"
                  color="rgba(112,112,112,.6)"
                  value={modalValues.role}
                  onChange={(e) => {
                    setModalValues({
                      ...modalValues,
                      role: e.target.value,
                    });
                    !e.target.value
                      ? setSuggestedRoles([])
                      : setSuggestedRoles(
                          roles.filter(
                            (role) =>
                              role.name &&
                              role.name
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase())
                          )
                        );
                  }}
                  displayType="roles"
                  searchValue={sugestedRoles}
                  setStateValue={setModalValues}
                  formState={modalValues}
                  stateName={"role"}
                  setSuggestions={setSuggestedRoles}
                  selectedId={setApprovalId}
                  required
                />
                 <FormSearchInput
                  className="w-100 m-b-10"
                  name="user"
                  label="Select Approver"
                  color="rgba(112,112,112,.6)"
                  value={modalValues.users}
                  onChange={(e) => {
                    setModalValues({
                      ...modalValues,
                      users: e.target.value,
                    });
                    !e.target.value
                      ? setSuggestedUser([])
                      : setSuggestedUser(
                          user.filter(
                            (user) =>
                              user.fullName &&
                              user.fullName
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase())
                          )
                        );
                  }}
                  displayType="user"
                  // searchValue={roles}
                  searchValue={sugestedUser}
                  setStateValue={setModalValues}
                  formState={modalValues}
                  stateName={"users"}
                  setSuggestions={setSuggestedUser}
                  selectedId={setUserId}
                  required
                />
                <TextInput
                  className="w-100 m-b-10"
                  type="number"
                  label="Sequence"
                  value={modalValues.approvalSequence}
                  onChange={(e) =>
                    setModalValues({
                      ...modalValues,
                      approvalSequence: Number(e.target.value),
                      roleId: Number(e.target.value),
                    })
                  }
                  minDate={0}
                  required
                />
                <button
                  type="submit"
                  className="btn btn-large p-r w-100 flex  flex-v-center flex-h-center justify-content-center m-t-20"
                  disabled={loading}
                  style={{ height: "43px" }}
                >
                  <p style={{ margin: "auto 15px auto" }}>
                    {loading ? "Adding To Sequence" : "Add To Sequence"}
                  </p>
                  {loading && (
                    <img
                      src={LoaderSrc}
                      className="btn-loading"
                      alt="Loading..."
                    />
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : showErrorModal ? (
        <ErrorModal
          action={errorMsg}
          closeModal={() => {
            setShowErrorModal(false);
            closeSuccessModal();
          }}
        />
      ) : (
        <SuccessModal
          action={"Approver Created Successfully!"}
          closeModal={closeSuccessModal}
        />
      )}
    </div>
  );
};

export default LeaveRequestApproverModal;
