import * as React from "react";
import LocationDetails from "../layouts/LocationDetails";
import SearchInput from "../inputs/SearchInput";
import LeavePlanApproverModal from "../layouts/LeavePlanApproverModal";
import moment from "moment";
import AreYouSure from "../layouts/AreYouSure";
import EditPlanApprover from "../layouts/EditPlanApprover";
import Cookies from "js-cookie";

// icons
import { RiAddLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { GiTrashCan } from "react-icons/gi";

// Api
import { Get, Delete } from "../../utility/fetch";
import { URLAPI } from "../../utility/ApiMethods";
import { LoadingSpinner } from "../../resources/loadingSpinner";

const tableHead = {
  padding: "8px"
};

const tableContent = {
  whiteSpace: "nowrap",
  textAlign: "left"
};

const LeavePlanApprover = (props) => {
  const [leavePeriod, setLeavePeriod] = React.useState({
    loading: true,
    data: [],
    error: null
  });
  const [showModal, setShowModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [currentLeave, setCurrentLeave] = React.useState({});

  const closeModal = () => {
    setShowModal(false);
    setEditModal(false);
    setDeleteModal(false);
  };

  const deleteLeaveApprover = (data) => {
    console.log("Logging the data of the leave to delete", data);
    setConfirmDelete(false);
    closeModal();
    Get(URLAPI.LeavePlanApprover.Get, getApproverDetails);
  };

  const handleDeleteBtn = (id) => {
    confirmDelete === true && Delete(`${URLAPI.LeavePlanApprover.Get}/${id}`, deleteLeaveApprover);
  };

  const getApproverDetails = (data) => {
    console.log("Logging leave details gotten", data.message);

    data && data.result
      ? setLeavePeriod({
          ...leavePeriod,
          loading: false,
          data: [...data.result]
        })
      : setLeavePeriod({
          ...leavePeriod,
          loading: false,
          error: data.message
        });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Leave Management";
    // setLeavePeriod({...leavePeriod, data["modifiedBy"]: })

    Get(URLAPI.LeavePlanApprover.Get, getApproverDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="w-100">
      {leavePeriod.loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {!leavePeriod.error ? (
            <>
              <div className="w-100 flex space-between flex-v-center">
                <h2>Leave Plan Approver</h2>
                {/* <LocationDetails /> */}
              </div>
              <div className="w-100 m-t-20 flex space-between flex-v-center">
                {/* <h4 className="san-sherif">Leave Plan Approver</h4> */}
                <h4 className="san-sherif"></h4>
                <div>
                  <form>
                    <SearchInput />
                  </form>
                </div>
              </div>
              <div className="table-view m-t-10">
                <div className="table-header">
                  <div className="table-row">
                    <div className="th" style={{ width: "25%", ...tableHead }}>
                      <p className="san-sherif">MDA</p>
                    </div>
                    <div className="th" style={{ width: "25%", ...tableHead }}>
                      <p className="san-sherif">Approver</p>
                    </div>
                    <div className="th" style={{ ...tableHead }}>
                      <p className="san-sherif">Approval Sequence</p>
                    </div>
                    <div className="th" style={{ ...tableHead }}>
                      <p className="san-sherif">Last Updated</p>
                    </div>
                    <div className="th" style={{ ...tableHead }}>
                      <p className="san-sherif">Last Updated By:</p>
                    </div>
                    <div className="th" style={{ ...tableHead }}>
                      <button
                        className="add-button pointer white-space-nowrap"
                        type="button"
                        onClick={() => setShowModal(true)}>
                        <RiAddLine />
                        Add Approver
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-body">
                  {leavePeriod.data.map(
                    ({ role, approvalSequence, startDate,mdaName,name, roleId, modifiedAt, modifiedBy, id }) => (
                      <div className="table-row" key={id}>
                        <div className="td" style={{ ...tableContent }}>
                          {mdaName}
                        </div>
                        <div className="td" style={{ ...tableContent }}>
                          {name}
                        </div>
                        <div className="td" style={{ textAlign: "center", ...tableContent }}>
                          {approvalSequence}
                        </div>
                        <div className="td" style={{ ...tableContent }}>
                          {moment(`${modifiedAt}`).format("DD:MM:YYYY/ hh:mm")}
                        </div>
                        <div className="td" style={{ ...tableContent }}>
                          {Cookies.get("fullname")}
                        </div>
                        <div className="td actions">
                          <button
                            type="button"
                            className="pointer tooltip"
                            onClick={() => {
                              setCurrentLeave({
                                role,
                                approvalSequence,
                                roleId,
                                modifiedAt,
                                modifiedBy,
                                id
                              });
                              setEditModal(true);
                            }}>
                            <BiEditAlt />
                            <span className="tooltiptext">Edit</span>
                          </button>
                          {/* <button
                            type="button"
                            className="m-l-10 pointer del"
                            onClick={() => {
                              setCurrentLeave({
                                title: "Leave Plan Approver",
                                name: role,
                                id,
                              });
                              setDeleteModal(true);
                            }}
                          >
                            <GiTrashCan />
                          </button> */}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
              {/* <div className="flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-large m-t-10"
                  style={{ padding: "14px 50px" }}
                >
                  Publish Approver Sequence
                </button>
              </div> */}
              {showModal ? (
                <LeavePlanApproverModal
                  closeModal={closeModal}
                  leaveDetails={leavePeriod}
                  setLeaveDetails={setLeavePeriod}
                />
              ) : editModal ? (
                <EditPlanApprover
                  closeModal={closeModal}
                  setLeaveDetails={setLeavePeriod}
                  leaveDetails={leavePeriod}
                  editLeave={currentLeave}
                />
              ) : deleteModal ? (
                <AreYouSure
                  closeModal={closeModal}
                  currentPeriod={currentLeave}
                  setConfirmDelete={setConfirmDelete}
                  handleDeleteBtn={handleDeleteBtn}
                  confirmDelete={confirmDelete}
                />
              ) : null}
            </>
          ) : (
            <div
              className="flex flex-v-center flex-h-center bolder-text"
              style={{ color: "red", fontSize: "1.2rem" }}>
              {leavePeriod.error}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LeavePlanApprover;
