import React, { useEffect, useState } from "react";
import SearchInput from "../inputs/SearchInput";
import LocationDetails from "../layouts/LocationDetails";
import ProfileForm from "../layouts/ProfileForm";

const AppraisalReview = ({ history, details = {} }) => {
  const [showFormModal, setShowFormModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG Appraisal Review ";
  }, []);

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">{/* <LocationDetails /> */}</div>
      <div className="w-100 flex centerflex-v-center m-t-20">
        <h4 className="san-sherif">Appraisal Review Request</h4>
      </div>
      <div className="wow">
        <p>Filter Items by</p>
        <button>Hello</button>
        <SearchInput placeholder="Search transactions, invoices or help." />
      </div>
      <div className="table-view m-t-10">
        <div className="table-body">
          {[{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }].map((item) => (
            <div className="table-row" key={item.id}>
              <div className="td">
                Chukwunonso Ugochukwucurrently with Ajalla currently with Ajallaa
              </div>

              <div className="vd">
                <div className="circle"></div>currently with Ajalla
              </div>

              <div className="td">Last Treated</div>
            </div>
          ))}
        </div>
      </div>
      <div className="bt1">
        <button className="btn btn-large w-10" type="button">
          Show more transactions
        </button>
        <div className="bt2">
          {" "}
          <p>Page</p>
          <button className="btn btn-large w-1" type="button">
            Show more transactions
          </button>
        </div>
      </div>

      <div className="bt2"></div>
      {showFormModal && <ProfileForm closeModal={() => setShowFormModal(false)} />}
    </div>
  );
};

export default AppraisalReview;
