/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from "react";

import notification from "../../utility/notification";
import { logout } from "../../utility/fetch";

const SessionHOC = (ProtectedComponent) =>
  class AutoLogout extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        warningTime: 1000 * 60 * 3,
        signoutTime: 1000 * 60 * 5,
      };
    }

    componentDidMount() {
      const events = [
        "load",
        "mousedown",
        "mousemove",
        "click",
        "scroll",
        "keypress",
      ];

      events.forEach((event) => {
        window.addEventListener(event, this.resetTimeout);
      });

      this.setTimeout();
    }

    componentWillUnmount() {
      const events = [
        "load",
        "mousedown",
        "mousemove",
        "click",
        "scroll",
        "keypress",
      ];

      events.forEach((event) => {
        window.removeEventListener(event, this.resetTimeout);
      });
      clearTimeout(this.warnTimeout);
      clearTimeout(this.logoutTimeout);
    }

    clearTimeoutFunc = () => {
      if (this.warnTimeout) {
        clearTimeout(this.warnTimeout);
        // this.setState({ warnTimeout: null });
      }
      if (this.logoutTimeout) {
        clearTimeout(this.logoutTimeout);
        // this.setState({ logoutTimeout: null });
      }
    };

    setTimeout = () => {
      const { warningTime, signoutTime } = this.state;
      this.warnTimeout = setTimeout(this.warn, warningTime);
      this.logoutTimeout = setTimeout(() => logout(), signoutTime);
    };

    resetTimeout = () => {
      this.clearTimeoutFunc();
      this.setTimeout();
    };

    warn = () => {
      notification({
        title: "SESSION TIMEOUT WARNING",
        message:
          "Due to inactivity on the portal, you will be logged out automatically in the next 2 minutes. To avoid being logged out, do something on the portal.",
        type: "warning",
      });
    };

    render() {
      console.log("IN SESSION HOC");
      return (
        <>
          <ProtectedComponent {...this.props} />
        </>
      );
    }
  };

export default SessionHOC;
