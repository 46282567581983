import React, { Component } from "react";
import { IoMdClose } from "react-icons/io";
import { IoIosSettings } from "react-icons/io";
//import TextareaInput from '../inputs/TextareaInput';
import SelectInput from "../inputs/SelectInput";
import TextInput from "../inputs/TextInput";
import { LoadingSpinner } from "../../resources/loadingSpinner";
import { URLAPI } from "../../utility/ApiMethods";
import { Get, Post } from "../../utility/fetch";
/*
const RoleForm = ({ closeModal, details }) => {
  const [role, setRole] = useState('');
  const [description, setDescription] = useState('');
  const [formType, setFormType] = useState('');

  useEffect(() => {
    if (details.id && details.id) {
      setFormType('edit');
      setRole(details.role);
      setDescription(details.description);
    } else {
      setFormType('create');
    }
  }, [details]);

  const submitForm = () => {
    console.log('Work in Progress...');
    closeModal();
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-400">
        <div className="content">
          <SelectInput className="w-100 m-b-10" label="Select Role" options={['', '']} />
          <TextInput
            name="role"      
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="w-100 m-b-10"
            label="Role List"
          />
         
          <TextInput
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-100 m-b-10"
            label="Priority"
          />
          
          <button
            type="button"
            className="btn btn-large w-100"
            onClick={submitForm}
          >
            {`${formType === 'create' ? 'Add to' : 'Update'} `}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoleForm;
*/
class PerformanceApprovalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleId: [],
      staffId: [],
      priority: 0,
      role: [],
      staff: [],
      records: [],
      button: "Save",
      className: "",
      class: "btn btn-large w-100",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  openModal = (id) => {
    const selected = this.state.records.filter((item) => id === item.id);
    if (selected.length > 0) {
      this.setState({ visible: false, setSelectedRecord: selected[0] });
    }
  };

  closeModal = () => {
    this.setState({ visible: false, selectedRecord: [] });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    //check if the on change is the RolesDropdown so as to populate and get the saved staffs
    if (e.target.name === "roleId") {
      this.setState({ loadingData: true }, () => {
        //get all the staffs name by the role id
        Get(
          URLAPI.PerformancePlanningApprovalSetupStaffs +
            "/" +
            this.state.roleId,
          this.fetchStaff
        );
      });
    }
  };

  getSavedRole = (data) => {
    let _role = data.data;
    this.setState({ role: _role, loadingData: false, visible: false });
  };

  fetchStaff = (data) => {
    let _staff = data.data;
    this.setState({ staff: _staff, loadingData: false, visible: false });
  };

  componentDidMount() {
    Get(URLAPI.PerformancePlanningApprovalSetupRoles, this.getSavedRole);
  }

  onSubmit = (e) => {
    let error_data = "";

    if (this.state.roleId === "") {
      error_data = "Select a role";
    } else if (this.state.staffId === "") {
      error_data = "Select staff";
    } else if (this.state.priority === "") {
      error_data = "Enter priority";
    }

    if (error_data !== "") {
      alert(error_data);
    } else if (error_data === "") {
      let perf = {
        id: this.state.roleId,
        approverID: this.state.staffId,

        priority: this.state.priority,
      };

      if (this.state.button === "Save") {
        this.onSubmitSave(perf);
      } else if (this.state.button === "Update") {
        this.onSubmitUpdate(perf);
      }
    }
  };

  onSubmitSave = (perf) => {
    this.setState({ loading: true }, () => {
      Post(
        perf,
        URLAPI.PerformancePlanningApprovalSetupPost,
        this.onSubmitAfterSave
      );
      console.log(perf);
    });
  };

  onSubmitAfterSave = (data) => {
    if (data.code === 1) {
      alert("Record Saved");
      //create array of data submitted
      let ratings = {
        id: data.roleId,
        role: this.state.staffId,
        priority: this.state.priority,
      };

      //clone the records in array
      let _records = [...this.state.records];

      //update the new record into the array
      _records.push(ratings);

      //commit the new record into array
      this.setState({
        records: _records,
        grade: "",
        score: "",
        loading: false,
      });
    } else if (data.code === 0) {
      alert(data.message);
      this.setState({ loading: false });
    } else {
      alert("Error saving data");
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        <div className="overlay">
          <IoMdClose className="close-btn pointer" onClick={this.closeModal} />
          <div className="modal-box max-w-400">
            <div className="content">
              <select
                label="select-role"
                className="w-100 m-b-10"
                name="roleId"
                value={this.state.roleId}
                onChange={(e) => this.handleChange(e)}
              >
                <option>Select Role</option>
                {this.state.role.map((m) => (
                  <option key={m.id} value={m.id}>
                    {m.name}
                  </option>
                ))}
              </select>
              <select
                label="select-staff"
                className="w-100 m-b-10"
                name="staffId"
                value={this.state.staffId}
                onChange={(e) => this.handleChange(e)}
              >
                <option>Select Staff</option>
                {this.state.staff.map((m) => (
                  <option key={m.id} value={m.id}>
                    {m.name}
                  </option>
                ))}
              </select>

              <TextInput
                name="priority"
                value={this.state.priority}
                onChange={(e) => this.handleChange(e)}
                className="w-100 m-b-10"
                label="Priority"
              />

              {loading ? (
                <LoadingSpinner />
              ) : (
                <button
                  type="button"
                  className={this.state.class}
                  onClick={(e) => this.onSubmit(e)}
                >
                  {this.state.button}
                </button>
              )}
              <div className="form-group">
                <div className="text-center" style={{ color: "Red" }}>
                  {" "}
                  {this.state.errorMessage}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PerformanceApprovalForm;
