import moment from "moment";

export const toggleScroll = () => {
  const overlays = document.querySelectorAll(".overlay");

  if (overlays.length > 0) {
    document.body.classList.add("no-scroll");
  } else {
    document.body.classList.remove("no-scroll");
  }
};

export const convertImgToBase64 = (inputFile) => {
  if (inputFile === undefined) return "";
  const file = new FileReader();

  return new Promise((resolve, reject) => {
    file.onerror = () => {
      file.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    file.onload = () => {
      resolve(file.result);
    };
    file.readAsDataURL(inputFile);
  });
};

export const formatFileUrl = (path) => `${process.env.REACT_APP_BACKEND_URL}/${path}`;

export const getLeaveDays = (startDate, endDate) => {
  startDate = moment(startDate);
  endDate = moment(endDate);
  var totalDays = Number(endDate.diff(startDate, "days"));

  if (totalDays === 7) totalDays -= 2;
  else if (totalDays < 7 && startDate.day() + totalDays - 1 > 5) {
    /** Leave days less than a week and falls within the weekend */
    totalDays -= 2;
  } else if (totalDays > 7) {
    /** Leave days greater than a week*/
    // let _floor = Math.floor(totalDays / 7);
    // let _rem = totalDays % 7;
    // totalDays = 7 * _floor - 2 * _floor + _rem;
    totalDays = (totalDays * 5 - (startDate.day() - endDate.day()) * 2) / 7;
  }

  return totalDays + 1;
};
