import * as React from "react";
import moment from "moment";
import LocationDetails from "../layouts/LocationDetails";
import axios from 'axios';
import SelectInput from "../inputs/SelectInput";
import FormDateInput from "../inputs/FormDateInput";
import FormSearchInput from "../inputs/FormSearchInput";
import Select from 'react-select';
import { BiEditAlt } from "react-icons/bi";
import { GiTrashCan } from "react-icons/gi";
import Accordion from "../layouts/Accordion";
import LeaveRequestModal from "../layouts/LeaveRequestModal"
import LoadingSpinner from "../../resources/loadingSpinner";
import AreYouSure from "../layouts/AreYouSure";
import Popup from "../layouts/Popup";
import SuccessModal from "../layouts/SuccessModal";
import notification from "../../utility/notification";
import ErrorModal from "../layouts/ErrorModal";
import Loader from "../../assets/svg/loading.svg";
import { getLeaveDays } from "../../utility/general";
import cookies from "js-cookie";
import MemoPDF from "../inputs/MemoPDF";

// Api
import { Get, GetwithQueryString, Post, Delete, Put, GetwithMultipleQueryString, GetServices } from "../../utility/fetch";
import { URLAPI } from "../../utility/ApiMethods";


const BASE_API_URL = `https://edogoverp.com/services/api`;

const pageTitle = {
  fontSize: "1.3rem"
};

const tableHeader = {
  // borderBottom: 'rgba(28,129,28,.5)'
  padding: "8px"
};

const centerContent = {
  textAlign: "left"
};

const inputStyle = {
  margin: "10px 0"
};

const labelStyle = {
  color: "rgba(112,112,112,.8)"
};

const CreateLeavePlan = () => {
  const [leaveSession, setLeaveSession] = React.useState({
    loading: true,
    data: [],
    error: null
  });
  const [leaveSessionCont, setLeaveSessionCont] = React.useState({
    loading: true,
    data: [],
    error: null
  });
  const [reliefOfficers, setReliefOfficers] = React.useState([]);
  const [leaveTypeID, setLeaveTypeID] = React.useState(null);
  const [reliefOfficerID, setReliefOfficerID] = React.useState(null);
  const [daysSelected, setDaysSelected] = React.useState(0);
  const [success, setSuccess] = React.useState(false);
  const [modalOpen, setModalopen] = React.useState(false)
  const [mdaId, setMdaId] = React.useState(0);
  const [mdas, setMdas] = React.useState("");
  const [mdaDetails, setMdaDetails] = React.useState({
    mdaId: 0,
    mdaName: ""
  })

  

  const [action, setAction] = React.useState("successfully Saved");
  const [leavePlan, setLeavePlan] = React.useState({
    loading: true,
    data: [],
    error: null
  });
  // const [accordionDetails, setAccordionDetails] = React.useState([]);
  const [leaveType, setLeaveType] = React.useState([]);
  const [leaveType2, setLeaveType2] = React.useState([]);
  const [reliefDetails, setReliefDetails]= React.useState([]);
  const [leaveTypeName, setLeaveTypeName] = React.useState("");
  const [officerPlan, setOfficerPlan] = React.useState({
    loading: true,
    data: []
  });
  const [dateselected, setDateselected] = React.useState({
    loading: true,
    data: [],
    error: null
  });
  const [createLeavePlan, setCreateLeavePlan] = React.useState({
    leaveName: "",
    startDate: "",
    endDate: "",
    reliefOfficerName: ""
  });
  const [reliefOfficerPlan, setReliefOfficerPlan] = React.useState([]);
  const [reliefOfficerTo, setReliefOfficerTo] = React.useState({
    loading: true,
    data: [],
    error: null
  });
  const [showPopup, setShowPopup] = React.useState(true);
  const [currentLeavePlan, setCurrentLeavePlan] = React.useState({});
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [specificLeave, setSpecificLeave] = React.useState([]);


  const [isLoading, setIsLoading] = React.useState(false);

  const [employeeName, setemployeeName] = React.useState("");
  const [approverId, setApproverId] = React.useState(null);
  const [approvers, setApprovers] = React.useState([]);
  const [recipientMda, setrecipientMda] = React.useState("");
  const [mdaList, setMdaList] = React.useState([]);
  const [employees, setEmployees] = React.useState([]);



  const [isUpdate, setIsUpdate] = React.useState(false)
  const closePopup = () => {
    setShowPopup(false);
    setSuccess(false);
  };
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const closeSuccessModal = () => setSuccess(false);

  const getLeaveType = (data) => {
    const selectOption = [];
    console.log("Logging the Leave type data", data);
    data &&
      data.result &&
      data.result.filter(el => el.leaveName === 'Annual').forEach(({ leaveName, leaveCategory, id }) => {
        selectOption.push({ name: leaveName, value: leaveCategory, id });
      });

    setLeaveType(selectOption);
  };


  const getLeaveType2 = (data) => {
    const selectOption = [];
    console.log("Logging the Leave type data", data);
    data &&
      data.result &&
      data.result.forEach(({ leaveName, leaveCategory, id }) => {
        selectOption.push({ name: leaveName, value: leaveCategory, id });
      });

    setLeaveType2(selectOption);
  };

  const handleApproverId = (e) => {
    setApprovers(e);
    console.log(e);
  };

  React.useEffect(() => {
    console.log(approverId);
    console.log(approvers);
    setApproverId(
      approvers.map((item) => ({
        id: 0,
        roleId: 0,
        approverId: item.value,
        sequence: 0,
        approverName: item.label
        
      }))
    );
  }, [approvers]);


  const getLeaveSession = (data) => {
    console.log("Leave Session Data", data);

    data && data.result
      ? setLeaveSession({
        ...leaveSession,
        loading: false,
        data: { ...data.result }
      })
      : setLeaveSession({
        ...leaveSession,
        loading: false,
        error: data.message
      });
  };

  const openModal = () => {
    setModalopen(true);
  }

  const closeModal = () => {
    setModalopen(false);
  }

  React.useEffect(() => {
    getApproversMDAs();
    GetServices(
      "/api/Employees/mdas",
      getMdas
    );
  },[])

  React.useEffect(()=>{
    fetchEmployees(mdaDetails.value)
  }, [mdaDetails])

  const getApproversMDAs = () => {
    axios.get(`${BASE_API_URL}/Employees/mdas`).then((response) => {
      console.log("--mdas--");
      console.log(response?.data);
      // this.setState({
      const roleList = response?.data.map((item) => ({
        name: `${item.name}`,
        value: item.id,
      }));
      setMdaList(roleList);

      // });
    });
  };

  const getEmployeesFromMda = (id) => {
    axios
      .get(`${BASE_API_URL}/Employees/employees_by_mda?mdaid=${id}`)
      .then((response) => {
        console.log("--employeesByMDAs--");
        console.log(response?.data);
        // this.setState({
        const roleList = response?.data.map((item) => ({
          name: `${item.name}`,
          value: item.id,
        }));
        setEmployees(
          roleList.filter((item) => {
            return !item.name.includes(cookies.get("fullname"));
          })
        );

        // setMdaList(roleList)

        // });
      });
  };



  const getLeaveSessionCont = (data) => {
    console.log("Leave SessionCont Data", data);

    if (data) {
      if (data.result) {
        data.result &&
          setLeaveSessionCont({
            ...leaveSessionCont,
            loading: false,
            data: { ...data.result }
          });
      } else {
        setLeaveSessionCont({
          ...leaveSession,
          loading: false,
          error: data.message
        });
      }
      setLeaveSession({ ...leaveSession, loading: false });
    }
  };

  const fetchEmployees = (id) => {
    console.log(mdaId)
    GetServices(
      `/api/Employees/employees_by_mda?mdaid=${id}`,

      getReliefOfficerNames
    );

  }



  const runRequest = () => {
    if (isUpdate) {
      updateRequest();
    }
    else {
      saveRequest();
    }
  }

  const setReliefOficerPlan = (response) => console.log("Relief Officer plan", response);

  const getLeavePlan = (data) => {
    console.log("get leave Plan", data);

    data.data && data.data.result
      ? setLeavePlan({
        ...leavePlan,
        loading: false,
        data: [...data?.data?.result]
      })
      : setLeavePlan({
        ...leavePlan,
        loading: false,
        error: data.message
      });
  };

  const getReliefOfficerNames = (data) => {
    console.log("logging getReliefOfficerNames", data);
    data ? setReliefOfficers(data) : setReliefOfficers([]);
  };

  const getMdas = (data) => {
    console.log("logging getMdas", data);
    data ? setMdas(data) : setMdas([]);
  };

  const getReliefOfficer = (id) => Get(`${URLAPI.leaveType.Post}${id}`, setReliefOficerPlan);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCheckDateSelected = (data) => {
    const { result, response } = data;
    data && result
      ? setDateselected({ ...dateselected, loading: false, error: null })
      : result &&
      result.data &&
      setDateselected({ ...dateselected, loading: false, error: `${response.data.apiMessage}` });
  };

  const checkIsRequestSave = (data) => {
    const { statusCode } = data;
    console.log("Logging to check if request is saved", data);

    if (data && statusCode === "01") {
      GetwithMultipleQueryString(URLAPI.LeavePlan.Get, { page: 1, size: 10 }, getLeavePlan);
      setIsLoading(false);
      setAction("Leave Plan Saved Successfully!");
      setSuccess(true);
    }
  };

  const checkIsRequestUpdated = (data) => {
    const { statusCode } = data;
    console.log("Logging to check if request is updated", data);

    if (data && statusCode === "00") {
      GetwithMultipleQueryString(URLAPI.LeavePlan.Get, { page: 1, size: 10 }, getLeavePlan);
      setIsLoading(false);
      setIsUpdate(false)
      setAction("Leave Plan Updated Successfully!");
      setSuccess(true);
    }
  };

  const getSpecificLeavePlan = (data) => {
    console.log(data);
    console.log(data.result[0].startDate)
    setCreateLeavePlan({
      leaveName: data.result[0].leaveType,
      startDate: data.result[0].startDate.split('T')[0],
      endDate: data.result[0].endDate.split('T')[0],
      reliefOfficerName: data.result[0].reliefOfficer
    })
    setReliefOfficerID(data.result[0].reliefEmpfk)
    setLeaveTypeID(data.result[0].leaveTypefk)



    setLeaveSession({
      ...leaveSession,
      loading: false,
      data: data.result[0].leaveSession,
    })
  }


  const getSpecificLeave = (id) => {
    setIsUpdate(true);
    GetwithQueryString(URLAPI.LeavePlan.getSpecificPlan, { name: 'leave_plan_Id', value: id }, getSpecificLeavePlan);

  }

  const saveRequest = () => {
    console.log("leave remaining" + leaveSessionCont.data.leavedaysleft + approverId + reliefOfficerID)
    console.log(approverId)
    const { startDate, endDate, leaveName, reliefOfficerName } = createLeavePlan;
    setIsUpdate(false);
    if (!leaveTypeID) {
      notification({
        title: "Leave Type Error!",
        message: "Please Select a Leave Type!",
        type: "danger"
      });
      return;
    } else if (!reliefOfficerID) {
      notification({
        title: "Relief Officer Error!",
        message: "Please Select a Relief Officer!",
        type: "danger"
      });
      return;
    } else if (approverId?.length < 1) {
      notification({
        title: "Approver Error!",
        message: "Please Select an Approver!",
        type: "danger"
      });
      return;
    } else if (startDate <= moment().format("YYYY-MM-DD")) {
      notification({
        title: "Date Error!",
        message: "Start date must be greater than today's date!",
        type: "danger"
      });
      return;
    } else if (startDate > endDate) {
      notification({
        title: "Date Error!",
        message: "End date must be greater than start date!",
        type: "danger"
      });
      return;
    } else {
      if (leaveSessionCont.data.leavedaysleft >= daysSelected) {
        Post(
          {
            reliefEmpfk: reliefOfficerID,
            reliefOfficer: reliefOfficerName,
            leaveTypefk: leaveTypeID,
            leaveType: leaveName,
            leaveDuration: daysSelected,
            startDate: startDate,
            endDate: endDate,
            approvers: approverId
          },
          URLAPI.LeavePlan.save,
          checkIsRequestSave
        );
        setIsLoading(true);
        GetwithMultipleQueryString(URLAPI.LeavePlan.Get, { page: 1, size: 10 }, getLeavePlan);
        setemployeeName("")
        setrecipientMda("")
        setReliefDetails("")
        setCreateLeavePlan({
          ...createLeavePlan,
          leaveName: "",
          startDate: "",
          endDate: "",
          reliefOfficerName: ""
        });
        setDaysSelected(0);
        setMdaDetails({
          mdaId: 0,
          mdaName: ""
        })
      } else {
        notification({
          title: "Error!",
          message: "Days Selected is greater than leave days left",
          type: "danger"
        });
      }
    }
  };



  const updateRequest = () => {
    const { startDate, endDate, leaveName, reliefOfficerName } = createLeavePlan;

    if (!leaveTypeID) {
      notification({
        title: "Leave Type Error!",
        message: "Please Select a Leave Type!",
        type: "danger"
      });
      return;
    } else if (!reliefOfficerID) {
      notification({
        title: "Releif Officer Error!",
        message: "Please Select a Relief Officer!",
        type: "danger"
      });
      return;
    } else if (startDate <= moment().format("YYYY-MM-DD")) {
      notification({
        title: "Date Error!",
        message: "Start date must be greater than today's date!",
        type: "danger"
      });
      return;
    } else if (startDate >= endDate) {
      notification({
        title: "Date Error!",
        message: "End date must be greater than start date!",
        type: "danger"
      });
      return;
    } else {
      if (leaveSessionCont.data.leavedaysleft >= daysSelected) {
        Put(
          {
            reliefEmpfk: reliefOfficerID,
            reliefOfficer: reliefOfficerName,
            leaveTypefk: leaveTypeID,
            leaveType: leaveName,
            leaveDuration: daysSelected,
            startDate: startDate,
            endDate: endDate
          },
          URLAPI.LeavePlan.updateSpecificPlan,
          checkIsRequestUpdated
        );
        setIsUpdate(false);
        setIsLoading(true);
        GetwithMultipleQueryString(URLAPI.LeavePlan.Get, { page: 1, size: 10 }, getLeavePlan);
        setReliefDetails("")
        setCreateLeavePlan({
          ...createLeavePlan,
          leaveName: "",
          startDate: "",
          endDate: "",
          reliefOfficerName: ""
        });
      } else {
        notification({
          title: "Error!",
          message: "Days Selected is greater than leave days left",
          type: "danger"
        });
      }
    }
  };




  const selectReliefOfficer = (id) => {
    setLeaveSession({ ...leaveSession, loading: true });
    GetwithQueryString(
      URLAPI.LeavePlan.selectReliefOfficer,
      { name: "ReliefOfficerid", value: id },
      (data) => {
        data && data.result ? setReliefOfficerPlan([...data.result]) : setReliefOfficerPlan([]);
        data && data.result
          ? setLeaveSession({ ...leaveSession, loading: false })
          : setLeaveSession({ ...leaveSession, loading: true });
      }
    );
  };

  const handleDeleteBtn = (id) => {
    confirmDelete === true &&
      Delete(`${URLAPI.LeavePlan.Delete}${id}`, () => {
        GetwithMultipleQueryString(URLAPI.LeavePlan.Get, { page: 1, size: 10 }, getLeavePlan);
        setAction("Leave Plan Deleted Successfully!");
        setShowDeleteModal(false);
        setConfirmDelete(false);
        setSuccess(true);
      });
  };

  const submitOtherLeave = (id) => {
    setLeaveSession({ ...leaveSession, loading: true });
    Post({ id, leaveStatus: 0 }, URLAPI.Approver.submitOtherLeave, (data) => {
      const { statusCode, apiMessage } = data;
      console.log(
        "Logging data after submitting leave request",
        data,
        typeof statusCode,
        apiMessage
      );

      if (data && statusCode === "00") {
        GetwithMultipleQueryString(URLAPI.LeavePlan.Get, { page: 1, size: 10 }, getLeavePlan);
        setLeaveSession({ ...leaveSession, loading: false });
      } else if (data && statusCode === "01") {
        GetwithMultipleQueryString(URLAPI.LeavePlan.Get, { page: 1, size: 10 }, getLeavePlan);
        setErrorMsg(apiMessage);
        setShowErrorModal(true);
        setLeaveSession({ ...leaveSession, loading: false });
      }
    });
  }

  const submitLeave = (id) => {
    setLeaveSession({ ...leaveSession, loading: true });
    Post({ id, leaveStatus: 0 }, URLAPI.Approver.submit, (data) => {
      const { statusCode, apiMessage } = data;
      console.log(
        "Logging data after submitting leave request",
        data,
        typeof statusCode,
        apiMessage
      );

      if (data && statusCode === "00") {
        GetwithMultipleQueryString(URLAPI.LeavePlan.Get, { page: 1, size: 10 }, getLeavePlan);
        setLeaveSession({ ...leaveSession, loading: false });
        setAction("Leave Plan Submitted Successfully!");
      setSuccess(true);
      } else if (data && statusCode === "01") {
        GetwithMultipleQueryString(URLAPI.LeavePlan.GetwithMultipleQueryString, { page: 1, size: 10 }, getLeavePlan);
        setErrorMsg(apiMessage);
        setShowErrorModal(true);
        setLeaveSession({ ...leaveSession, loading: false });
      }
    });
  };

  React.useEffect(() => {
    const { startDate, endDate } = createLeavePlan;
    const { leavedaysleft } = leaveSessionCont.data;

    if (startDate && endDate && startDate <= endDate) {
      setDaysSelected(getLeaveDays(startDate, endDate));
      console.log(getLeaveDays(startDate, endDate))

      console.log("Logging leaveTypeID", leaveTypeID);
      console.log("Logging reliefOfficerID", reliefOfficerID);

      if (leaveTypeID && reliefOfficerID && leavedaysleft) {
        Post(
          {
            leaveTypeId: leaveTypeID,
            reliefOfficerId: reliefOfficerID,
            leaveDaysLeft: leavedaysleft,
            startDate: startDate,
            endDate: endDate
          },
          URLAPI.LeavePlan.checkdateselected,
          getCheckDateSelected
        );
      }
    }
  }, [createLeavePlan, leaveTypeID, reliefOfficerID, leaveSessionCont.data, getCheckDateSelected,]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Leave Plan";

    Get(URLAPI.LeavePlan.leaveSession, getLeaveSession);
    Get(URLAPI.LeaveType.Get, getLeaveType);
    GetwithQueryString(URLAPI.LeaveType.Get2, { name: 'empId', value: parseInt(cookies.get("userId")) }, getLeaveType2,);
    GetwithMultipleQueryString(URLAPI.LeavePlan.Get, { page: 1, size: 10 }, getLeavePlan);
    Get(URLAPI.LeavePlan.reliefTo, (data) => {
      console.log("Relief Officer to", data, data.result);
      data && data.result
        ? setReliefOfficerTo({
          ...reliefOfficerTo,
          data: [...data.result],
          loading: false,
          error: null
        })
        : data.response &&
        setReliefOfficerTo({
          ...reliefOfficerTo,
          data: [],
          loading: false,
          error: data.response.data.apiMessage
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-100">
      {leaveSession.loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="flex flex-v-center space-between">
            <div className="bold-text">
              <h3>Leave Plan</h3>
            </div>
            {/* <LocationDetails /> */}
          </div>

          <div
            className="table-view m-t-10 w-100"
            style={{ borderSpacing: "0", borderCollapse: "collapse" }}>
            <div className="table-header">
              <div className="table-row" style={{ boxShadow: "0px 2px 6px #0000000A" }}>
                <div className="th w-max-content" style={{ ...centerContent, ...tableHeader }}>
                  <p>Leave Session</p>
                </div>
                <div className="th" style={{ ...centerContent, ...tableHeader }}>
                  <p>Start Date</p>
                </div>
                <div className="th" style={{ ...centerContent, ...tableHeader }}>
                  <p>End Date</p>
                </div>
                <div className="th" style={{ ...centerContent, ...tableHeader }}>
                  <p>Days Entitled</p>
                </div>
                <div className="th" style={{ ...centerContent, ...tableHeader }}>
                  <p>Annual Deductions</p>
                </div>
                <div className="th" style={{ ...centerContent, ...tableHeader }}>
                  <p>Available Days</p>
                </div>
                <div className="th" style={{ ...centerContent, tableHeader }}>
                  <p>Days Left</p>
                </div>
              </div>
            </div>
            <div className="table-body" style={{ backgroundColor: "#F0F0F0" }}>
              {
                <div
                  className="table-row"
                  key={leaveSession.data.id}
                  style={{
                    backgroundColor: "transparent",
                    boxShadow: "0px 2px 6px #0000000A"
                  }}>
                  <div className="td" style={{ ...centerContent }}>
                    {leaveSession.data.sessionYear}
                  </div>
                  <div className="td" style={{ ...centerContent }}>
                    {moment(`${leaveSession.data.startDate}`).format("Do MMMM, YYYY")}
                  </div>
                  <div className="td" style={{ ...centerContent }}>
                    {moment(`${leaveSession.data.endDate}`).format("Do MMMM, YYYY")}
                  </div>
                  <div className="td" style={{ ...centerContent }}>
                    {leaveSessionCont.data.leavedaysentitlement}
                  </div>
                  <div className="td" style={{ ...centerContent }}>
                    {leaveSessionCont.data.Annualleavededuction}
                  </div>
                  <div className="td" style={{ ...centerContent }}>
                    {leaveSessionCont.data.Avaliableleavedays}
                  </div>
                  <div className="td" style={{ ...centerContent }}>
                    {leaveSessionCont.data.leavedaysleft}
                  </div>
                </div>
              }
            </div>
          </div>

          {/* <div className="w-100">
            <div className="bold-text m-t-20" style={{ marginBottom: "8px" }}>
                <h3>Create Leave Request</h3>
            </div>
            <button
                      className="btn btn-large p-r w-50 flex  flex-v-center flex-h-center justify-content-center"
                      style={{ textAlign: "center", height: "43px" }}
                      type="button"
                      onClick={openModal}
                      disabled={isLoading}>
                      <p style={{ margin: "auto 15px auto" }}>Create Leave Request</p>
                      {isLoading && <img src={Loader} className="btn-loading" alt="Loading..." />}
                    </button>
          </div> */}


          <div className="w-100">
            <div className="bold-text m-t-20">
              <h3>Create Leave Plan</h3>
            </div>
            <div className="row w-100" style={{ margin: ".6rem 0 0" }}>
              <div
                className="col-4"
                style={{
                  backgroundColor: isUpdate ? "lightgreen" : "white",
                  boxShadow: "0px 2px 6px #0000000A",
                  padding: "1rem .7rem"
                }}>
                <form>
                  <div style={{ ...inputStyle }}>
                    <SelectInput
                      label={"Leave Type*"}
                      options={leaveType}
                      value={createLeavePlan.leaveName}
                      onChange={(e) => {
                        setCreateLeavePlan({
                          ...createLeavePlan,
                          leaveName: e.target.value
                        });
                        e.target.children[e.target.selectedIndex].id &&
                          GetwithQueryString(
                            URLAPI.LeavePlan.leaveSessionCont,
                            {
                              name: "leavetypeid",
                              value: e.target.children[e.target.selectedIndex].id
                            },
                            getLeaveSessionCont
                          );
                        setLeaveSession({ ...leaveSession, loading: true });
                        e.target.children[e.target.selectedIndex].id &&
                          setLeaveTypeID(Number(e.target.children[e.target.selectedIndex].id));
                      }}
                      selectOption={getReliefOfficer}
                    />
                  </div>
                  <div style={{ ...inputStyle }}>
                      <div>Relief Officer's MDA*</div>
                      <Select
                        placeholder="Select Relief Officer's MDA..."
                        // isMulti
                        className="basic-multi-select"
                        onChange={(value) => {
                          console.log(value);
                          setMdaDetails(value);
                          // this.setState({ employeeName: e.target.value });
                          // setrecipientMda(value);
                          // getEmployeesFromMda(value.value);
                          // this.setState({recipientMda:value});
                        }}
                        value={mdaDetails}
                        options={Array.isArray(mdas) && mdas?.map((m) => ({
                          label: m?.name,
                          value: m?.id,
                        }))}
                      />
                    </div>

                    <div style={{ ...inputStyle }}>
                      <div>Relief Officer's Name*</div>
                      <Select
                        placeholder="Select Relief Officer's Name..."
                        // isMulti
                        className="basic-multi-select"
                        onChange={(value) => {
                          console.log(value);
                          setReliefDetails(value);
                          setReliefOfficerID(value.value)
                          selectReliefOfficer(value?.value)
                          setCreateLeavePlan({
                            ...createLeavePlan,
                            reliefOfficerName: value.label
                          });
                          // this.setState({ employeeName: e.target.value });
                          // setrecipientMda(value);
                          // getEmployeesFromMda(value.value);
                          // this.setState({recipientMda:value});
                        }}
                        value={reliefDetails}
                        options={Array.isArray(reliefOfficers) && reliefOfficers?.map((m) => ({
                          label: m?.name,
                          value: m?.id,
                        })) || []}
                      />
                    </div>
                  {/* <div style={{ ...inputStyle }}>
                    <FormSearchInput
                      label={"Relief Officer's MDA*"}
                      placeholder="Select Relief Officer's MDA..."
                      value={mdaDetails.mdaName}
                      onChange={(e) => {
                        setMdaDetails({
                          ...mdaDetails,
                          mdaName: e.target.value
                        });
                        GetServices(
                          "/api/Employees/mdas",
                          getMdas
                        );
                      }}
                      displayType="roles"
                      setStateValue={setMdaDetails}
                      formState={mdaDetails}
                      stateName={"mdaName"}
                      searchValue={mdas}
                      setSuggestions={setMdas}
                      selectedId={setMdaId}
                      selectReliefOfficer={fetchEmployees}
                    />
                  </div> */}
                  {/* <div style={{ ...inputStyle }}>
                    <FormSearchInput
                      label={"Relief Officer*"}
                      placeholder="Relief Officer Name....."
                      value={createLeavePlan.reliefOfficerName}
                      onChange={(e) => {
                        setCreateLeavePlan({
                          ...createLeavePlan,
                          reliefOfficerName: e.target.value
                        });
                        console.log(createLeavePlan)
                        // GetwithQueryString(
                        //   URLAPI.LeavePlan.staffList,
                        //   {
                        //     name: "name",
                        //     value: e.target.value
                        //   },
                        //   getReliefOfficerNames
                        // );
                      }}
                      displayType="roles"
                      setStateValue={setCreateLeavePlan}
                      formState={createLeavePlan}
                      stateName={"reliefOfficerName"}
                      searchValue={reliefOfficers}
                      setSuggestions={setReliefOfficers}
                      selectedId={setReliefOfficerID}
                      selectReliefOfficer={selectReliefOfficer}
                    />
                  </div> */}
                  
                    <div style={{ ...inputStyle }}>
                      <div>Approver's MDA*</div>
                      <Select
                        placeholder="Approvers' MDA"
                        // isMulti
                        className="basic-multi-select"
                        onChange={(value) => {
                          console.log(value);
                          // this.setState({ employeeName: e.target.value });
                          setrecipientMda(value);
                          getEmployeesFromMda(value.value);
                          // this.setState({recipientMda:value});
                        }}
                        value={recipientMda}
                        options={mdaList?.map((m) => ({
                          label: m.name,
                          value: m.value,
                        }))}
                      />
                    </div>

                    <div style={{ ...inputStyle }}>
                    <div>Approvers' Names*</div>
                      <Select
                        placeholder="Select Approvers"
                        // isMulti
                        className="basic-multi-select m-t-10 m-b-10"
                        onChange={(value) => {
                          console.log(value.value);
                          setemployeeName(value);
                          handleApproverId(value);
                          // handleApproverId(value)
                          // setEmployeeList(value.value);
                          // this.setState({ employeeList: value });

                          // this.getEmployeesFromMda(value.value)
                          // this.setState({recipientMda:value});
                        }}
                        // onChange={(e)=>{handleApproverId(e)}}
                        // onInputChange={(e) => {
                        //   handleApprovers(e);
                        // }}
                        isMulti
                        value={employeeName}
                        options={employees?.map((m) => ({
                          label: m.name,
                          value: m.value,
                        }))}
                      />
                    </div>




                    <div style={{ ...inputStyle }}>
                      <FormDateInput
                        label={"Start Date*"}
                        name="startDate"
                        value={createLeavePlan.startDate}
                        onChange={(e) => {
                          if (moment(e.target.value).day() < 1 || moment(e.target.value).day() > 5) {
                            notification({
                              title: "Date Error!",
                              message: "Leave cannot start during the weekend",
                              type: "danger"
                            });
                          } else if (e.target.value <= moment().format("YYYY-MM-DD")) {
                            notification({
                              title: "Date Error!",
                              message: "Start date must be greater than today's date!",
                              type: "danger"
                            });
                          } else {
                            setCreateLeavePlan({ ...createLeavePlan, startDate: e.target.value });
                          }
                        }}
                      />
                    </div>
                    <div style={{ ...inputStyle }}>
                      <FormDateInput
                        label={"End Date*"}
                        name="endDate"
                        value={createLeavePlan.endDate}
                        onChange={(e) => {
                          if (moment(e.target.value).day() < 1 || moment(e.target.value).day() > 5) {
                            notification({
                              title: "Date Error!",
                              message: "Leave cannot end during the weekend",
                              type: "danger"
                            });
                          } else if (
                            !createLeavePlan.startDate ||
                            createLeavePlan.startDate > e.target.value
                          ) {
                            notification({
                              title: "Date Error!",
                              message: "End date must be greater than start date!",
                              type: "danger"
                            });
                          } else {
                            setCreateLeavePlan({ ...createLeavePlan, endDate: e.target.value });
                          }
                        }}
                      />
                    </div>

                    <div style={{ margin: ".8rem 0", ...labelStyle }}>
                      Number of days selected: <span>{daysSelected}</span>
                    </div>
                    <div className=" space-around w-100">
                      <button
                        className="btn btn-large p-r w-100 flex  flex-v-center flex-h-center justify-content-center"
                        style={{ textAlign: "center", height: "43px" }}
                        type="button"
                        onClick={() => {runRequest()}}
                        disabled={isLoading}>
                        <p style={{ margin: "auto 15px auto" }}>{isLoading ? "Saving" : "Save"}</p>
                        {isLoading && <img src={Loader} className="btn-loading" alt="Loading..." />}
                      </button>
                      {/* <button className="btn btn-large w-45" type="button">
                      Submit
                    </button> */}
                    </div>
                </form>
              </div>
              <div className="col-8" style={{ marginBottom: "10px" }}>
                <Accordion
                  title="Leave Plan Details"
                  content={
                    leavePlan.data.length > 0 ? leavePlan.data : "You Have no Leave Plan Yet"
                  }
                />
                <Accordion
                  title="Relief Officer Plan"
                  content={reliefOfficerPlan.length > 0 ? reliefOfficerPlan : "No Leave Plan"}
                />
                <Accordion
                  title="You are a Relief Officer to"
                  content={
                    reliefOfficerTo.data.length > 0
                      ? reliefOfficerTo.data
                      : "Not A Relief Officer to Anyone yet"
                  }
                />
              </div>
            </div>
          </div>

          <div>
            <div className="bold-text m-t-10">
              <h3>Leave Plan</h3>
            </div>
            <div className="table-view m-t-10 w-100">
              {leavePlan.data.length === 0 ? null : leavePlan.data.filter(
                (item) => item.status === 4
              ).length === 0 ? null : (
                <div className="table-header">
                  <div
                    className="table-row"
                    style={{ borderBottom: "1ps solid gray", ...tableHeader }}>
                    <div className="th w-max-content" style={{ padding: "15px", ...centerContent }}>
                      <p>Items</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>Start Date</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>End Date</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      {/* <p>Days Entitled</p> */}
                      <p>Leave Duration</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>Relief Officer</p>
                    </div>
                    <div className="th" style={{ ...centerContent }}>
                      <p>Action</p>
                    </div>
                  </div>
                </div>
              )}
              <div className="table-body">
                
                {leavePlan.data.length < 1 ? (
                  <div className="flex flex-h-center flex-v-center bolder-text">
                    There are no saved leave plan(s) yet
                  </div>
                )
                  // : leavePlan.data.filter((item) => item.status === 4).length === 0 ? (
                  : leavePlan.data.filter((item) => item.statusName === 'Saved').length === 0 ? (
                    <div className="flex flex-h-center flex-v-center bolder-text">
                      There are no saved leave plan(s) yet
                    </div>
                  )
                    : (
                      leavePlan.data.map(
                        ({
                          id,
                          leaveSession,
                          leaveDuration,
                          leaveType,
                          startDate,
                          endDate,
                          daysEntitled,
                          reliefOfficer,
                          status,
                          statusName,
                          ...rest
                        }) => (
                          <>
                            {/* {status && statusName === 'saved' && ( */}
                            {statusName === 'Saved' && (
                              <>
                                <div className="table-row" key={id}>
                                  <div className="td" style={{ ...centerContent }}>
                                    {leaveSession}
                                  </div>
                                  <div className="td" style={{ ...centerContent }}>
                                    {moment(`${startDate}`).format("Do MMMM, YYYY")}
                                  </div>
                                  <div className="td" style={{ ...centerContent }}>
                                    {moment(`${endDate}`).format("Do MMMM, YYYY")}
                                  </div>
                                  <div className="td" style={{ ...centerContent }}>
                                    {/* {daysEntitled} */}
                                    {leaveDuration}
                                  </div>
                                  <div className="td" style={{ ...centerContent }}>
                                    {reliefOfficer}
                                  </div>
                                  <div
                                    className="row td actions space-around"
                                    style={{ display: "flex", ...centerContent }}>
                                    <div
                                      style={{
                                        backgroundColor: "#caddc1",
                                        padding: "0 0.8rem",
                                        cursor: "pointer",
                                        borderRadius: "70px",
                                        ...centerContent
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault(e);
                                        console.log("Logging Status, and id", status, id);
                                        leaveType === 'Casual' ?
                                          submitOtherLeave(id)
                                          :
                                          submitLeave(id);
                                      }}>
                                      <p
                                        style={{
                                          display: "table-cell",
                                          fontSize: "13px",
                                          color: "#43425d"
                                        }}>
                                        Submit Request
                                      </p>
                                    </div>

                                    <div>
                                      <div className="tooltip">
                                        <button
                                          type="button"
                                          className="m-l-10 del pointer"
                                          onClick={() => {
                                            getSpecificLeave(id);
                                          }}>
                                          <BiEditAlt />
                                          <span className="tooltiptext">Edit</span>
                                        </button>
                                      </div>
                                    </div>


                                    <div>
                                      <div className="tooltip">
                                        <button
                                          type="button"
                                          className="m-l-10 del pointer"
                                          onClick={() => {
                                            setCurrentLeavePlan({
                                              title: "leave request with start date of",
                                              name: moment(startDate).format("MMMM Do, YYYY"),
                                              id
                                            });
                                            setShowDeleteModal(true);
                                          }}>
                                          <GiTrashCan />
                                          <span className="tooltiptext">Delete</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )
                      )
                    )}
              </div>
            </div>
          </div>
          {showPopup && dateselected.error && (
            <Popup msg={dateselected.error} closeModal={closePopup} />
          )}
          {success && (
            <div
              className="overlay flex"
              style={{
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0px 2px 6px #0000000A"
              }}>
              <SuccessModal action={action} closeModal={closeSuccessModal} />
            </div>
          )}
          {showErrorModal && (
            <div
              className="overlay flex"
              style={{
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0px 2px 6px #0000000A"
              }}>
              <ErrorModal action={errorMsg} closeModal={() => setShowErrorModal(false)} />
            </div>
          )}
          {showDeleteModal && (
            <AreYouSure
              confirmDelete={confirmDelete}
              setConfirmDelete={setConfirmDelete}
              handleDeleteBtn={handleDeleteBtn}
              currentPeriod={currentLeavePlan}
              closeModal={() => setShowDeleteModal(false)}
            />
          )}
          {
            modalOpen && (
              <LeaveRequestModal
                closeModal={closeModal}
                leaveTypes={leaveType2}

              />


            )
          }
        </>
      )}

      {/* <MemoPDF /> */}
    </div>
  );
};

export default CreateLeavePlan;
