/* eslint-disable no-console */
/* eslint-disable quotes */
import * as React from 'react';
import { HiOutlineUpload } from 'react-icons/hi';
import notification from '../../utility/notification';
import axios from 'axios';
// import Cookies from 'js-cookie';
// import { FileUpload } from '../../utility/fetches';


// var baseURL='http://edogoverp.com'
const UploadButton = (props) => {
  const [file, setFile] = React.useState('');
  const [filename, setFilename] = React.useState('');
  const [imagg, setImagg] = React.useState('');
  const [namm, setNamm] = React.useState('');
  const [imagess, setImagess] = React.useState([]);
  const [uploadProgress, setUploadProgress] = React.useState(0)


  const FileUpload = async (data, callbackfunction) => {
    let fileProgress;
    let Access_token = localStorage.getItem('token');
    const response = axios.post(
      `${process.env.REACT_APP_BASE_URL}/services/api/documents/leave`,
      data,
      {
        headers: {
          Authorization: `Bearer ${Access_token}`,
          "content-type": "multipart/form-data",
          'content-type': undefined,
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(progress);
        },

      },
    );
    await response
      .then((response) => {
        let result = response.data;
        callbackfunction(result);
        setUploadProgress(0);
        console.log(response);
      })
      .catch((error) => {
        callbackfunction(error);

      });
  };

  const onChange = (e) => {
    // setFile(e.target.files[0]);
    // setFilename(e.target.files[0].name);
    console.log(props?.id)
    const data = new FormData();

    const supportedTypes = [
      'jpeg',
      'png',
      'gif',
      'pdf',
      "msword",
      "ppt", "pptx", "vnd.ms-excel", "xls", "xlsx",
      'vnd.openxmlformats-officedocument.wordprocessingml.document',
      'doc',
      'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    const { type, size } = e.target.files[0] ?? '';

    let doc = e.target.files[0];

    const fileType = type?.slice(type?.indexOf('/') + 1);


    let defObj = {
      docTitle: '',
      docPath: ''
    };

    if (!supportedTypes.includes(fileType)) {
      notification({
        title: "Upload Error",
        message: "Unsopported file type",
        type: "danger"
      })
    }



    if (supportedTypes.includes(fileType) && size / 1024 < 500000) {
      setFile(e.target.files[0]);
      setFilename(e.target.files[0].name);
      data.append('files', doc);
      FileUpload(data, (response) => {
        if (response.code === 1) {
          setImagg(response.doclink);
          setNamm(doc.name);
          defObj.docTitle = doc.name;
          defObj.docPath = response.doclink;
          setImagess([...imagess, defObj]);
          props.sendImagg && props?.sendImagg(defObj);
          if (props.formState && props.setFormState && props.stateName) {
            props.setFormState({
              ...props.formState,
              [props.stateName]: response.doclink,
              [props?.stateName2]: e.target.files[0].name,
            });
          }

        } else if (response.code === 0) {
          setFile('');
          setFilename('Choose File');
          notification({
            title: "Upload Error",
            message: response?.message,
            type: "danger"
          })

        } else {
          setFile('');
          setFilename('Choose File');
          notification({
            title: "Upload Error",
            message: response?.message,
            type: "danger"
          })

        }
      });
    }
  };

  // React.useEffect(() => {
  //   setFilename('');
  //   setFile('');
  // }, [props.reload]);

  console.log(props.id)
 

  // React.useEffect(() => {
  //   if (props?.fileName) {
  //     setFilename(props?.fileName);
  //   } else {
  //     setFilename('');
  //   }
  // }, [props?.fileName]);

  return (
    <div className={`${props?.className}`}>
      <input type='file' id='file' hidden onChange={props.onChange || onChange} />
      <label
        //className='flex space-between'
        htmlFor='file'

        style={{
          display: 'flex',
          color: props.color || '#43425D',
          backgroundColor: props.bgColor || '#EFD66BF2',
          padding: props.padding || '8px 6px',
          width: props.width || '40%',
          borderRadius: props.borderRadius || '20px',
          fontSize: props.fontSize || '14px',
          marginTop: props.marginTop || '16px',
          marginBottom: props.marginBottom || '0px',
          minWidth: props.minWidth || "200px",
          alignItems: "center",
          justifyContent: "center"

        }}>
        {props.icon || (
          <HiOutlineUpload
            color={props.iconColor || '#1C811C'}
            fontSize={props.iconSize || '20px'}
            style={{ marginLeft: '10px' }}
          />
        )}
        <span style={{ paddingLeft: '8px' }}>{props.btnText || 'Upload Document(s)'}</span>
      </label>

      {uploadProgress > 0 && (
        <div className='m-t-10 '>
          {/* <p>Upload Progress: {uploadProgress}%</p> */}
          <div className='' style={{ width: '100%', border: '1px solid #ccc' }}>
            <div
              style={{
                width: `${uploadProgress}%`,
                height: '12px',
                backgroundColor: '#4caf50',
                color: '#fff',
                textAlign: 'center',
                lineHeight: '16px',
              }}
              className='p-t-0 p-b-10'
            >
              {uploadProgress}%
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadButton;
