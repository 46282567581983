import React, { Component } from "react";
import { RiAddLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { GiTrashCan } from "react-icons/gi";
import SearchInput from "../inputs/SearchInput";
import LocationDetails from "../layouts/LocationDetails";
import RoleForm from "../layouts/PerformanceApprovalForm";
import { URLAPI } from "../../utility/ApiMethods";
import { Get } from "../../utility/fetch";

/*
const RolesSetup = ({ history }) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [details, setDetails] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Performance Approver';

    setDetails([
      {
        id: 1,
        role: 'Director, Ministry of Works and Housing',
        description: 'This role for you to direct the affairs of your ministry. Thank you',
        sequence: 1,
        lastUpdated: '20:11:2020 / 14:05:23PM',
        updatedBy: 'Ugochukwu Ajalla'
      },
      {
        id: 2,
        role: 'Director, Ministry of Finance',
        description: 'This role for you to direct the affairs of your ministry. Thank you',
        sequence: 2,
        lastUpdated: '20:11:2020 / 14:05:23PM',
        updatedBy: 'Ugochukwu Ajalla'
      },
      {
        id: 3,
        role: 'Director, Ministry of Youth and Sports',
        description: 'This role for you to direct the affairs of your ministry. Thank you',
        sequence: 3,
        lastUpdated: '20:11:2020 / 14:05:23PM',
        updatedBy: 'Ugochukwu Ajalla'
      },
      {
        id: 4,
        role: 'Director, Ministry of Digital Infrastructure',
        description: 'This role for you to direct the affairs of your ministry. Thank you',
        sequence: 4,
        lastUpdated: '20:11:2020 / 14:05:23PM',
        updatedBy: 'Ugochukwu Ajalla'
      },
      {
        id: 5,
        role: 'Director, Ministry of Digital Infrastructure',
        description: 'This role for you to direct the affairs of your ministry. Thank you',
        sequence: 5,
        lastUpdated: '20:11:2020 / 14:05:23PM',
        updatedBy: 'Ugochukwu Ajalla'
      }
    ]);
  }, []);

  const showEditForm = (id) => {
    const selected = details.filter((item) => id === item.id);

    if (selected.length > 0) {
      setSelectedRecord(selected[0]);
      setShowFormModal(true);
    }
  };

  const closeModal = () => {
    setSelectedRecord([]);
    setShowFormModal(false);
  };

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">
        <h2></h2>
        <LocationDetails />
      </div>
      <div className="w-100 flex space-between flex-v-center m-t-20">
        <h4 className="san-sherif">Approver Route</h4>
        <SearchInput placeholder="Add Approver" />
      </div>
      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            <div className="th"><p className="san-sherif">Staff Name</p></div>
            <div className="th"><p className="san-sherif">Role</p></div>
            <div className="th"><p className="san-sherif">Priority</p></div>
            <div className="th"><p className="san-sherif">Last Updated</p></div>
            <div className="th"><p className="san-sherif">Last Updated</p></div>
            <div className="th">
              <button className="add-button pointer" type="button" onClick={() => setShowFormModal(true)}>
                <RiAddLine />
                Add Approver
              </button>
            </div>
          </div>
        </div>
        <div className="table-body">
          {details.map((item) => (
            <div className="table-row" key={item.id}>
              <div className="td">{item.role}</div>
              <div className="td">{item.sequence}</div>
              <div className="td">{item.priority}</div>
              <div className="td">{item.lastUpdated}</div>
              <div className="td">{item.updatedBy}</div>
              <div className="td actions">
                <button type="button" className="pointer" onClick={() => showEditForm(item.id)}>
                  <BiEditAlt />
                </button>
                <button type="button" className="m-l-10 del pointer">
                  <GiTrashCan />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {showFormModal && (
        <RoleForm details={selectedRecord} closeModal={closeModal} />
      )}
      <button style={{ width: 20, height: 20, color: "white", background: "#f4f4f4", justifyContent: "right", alignItems: "flex-end"}}>Publish Sequence</button>
    </div>
  );
};

export default RolesSetup;
*/
class PerformanceApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: []
    };
  }

  openModal = (id) => {
    const selected = this.state.records.filter((item) => id === item.id);
    if (selected.length > 0) {
      this.setState({ visible: true });
    }
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  fetchData = (data) => {
    let _records = data.data;
    this.setState({ records: _records, loadingData: false });
    console.log(data);
  };

  componentDidMount() {
    this.setState({ loadingData: true }, () => {
      Get(URLAPI.PerformancePlanningApprovalSetup, this.fetchData);
    });
  }

  render() {
    return (
      <>
        <div className="w-100">
          <div className="w-100 flex space-between flex-v-center">{/* <LocationDetails /> */}</div>
          <div className="w-100 flex space-between flex-v-center m-t-20">
            <h4 className="san-sherif">Approver Route</h4>
            <SearchInput placeholder="Add Approver" />
          </div>

          <div className="table-view m-t-10">
            <div className="table-header">
              <div className="table-row">
                <div className="th">
                  <p className="san-sherif">Staff Name</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Role</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Priority</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Last Updated</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Last Updated</p>
                </div>
                <div className="th">
                  <button
                    className="add-button pointer"
                    type="button"
                    onClick={() => this.openModal(true)}>
                    <RiAddLine />
                    Add Approver
                  </button>
                </div>
              </div>
            </div>
            <div className="table-body">
              {this.state.records.map((item) => (
                <div className="table-row" key={item.id}>
                  <div className="td">{item.role}</div>
                  <div className="td">{item.approver}</div>
                  <div className="td">{item.priority}</div>
                  <div className="td">{item.lastUpdated}</div>
                  <div className="td">{item.updatedBy}</div>
                  <div className="td actions">
                    <button
                      type="button"
                      className="pointer"
                      onClick={() => this.openModal(item.id)}>
                      <BiEditAlt />
                    </button>
                    <button type="button" className="m-l-10 del pointer">
                      <GiTrashCan />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {this.openModal && (
            <RoleForm details={this.state.selectedRecord} closeModal={this.closeModal} />
          )}
          <button
            style={{
              width: 20,
              height: 20,
              color: "white",
              background: "#f4f4f4",
              justifyContent: "right",
              alignItems: "flex-end"
            }}>
            Publish Sequence
          </button>
        </div>
      </>
    );
  }
}

export default PerformanceApproval;
