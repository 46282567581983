import * as React from "react";
import { IoMdClose } from "react-icons/io";
import TextInput from "../inputs/TextInput";
import FormDateInput from "../inputs/FormDateInput";
import SuccessModal from "./SuccessModal";
import moment from "moment";
import notification from "../../utility/notification";
import LoaderSrc from "../../assets/svg/loading.svg";

// Api
import { Post, Get } from "../../utility/fetch";
import { URLAPI } from "../../utility/ApiMethods";

const LeavePeriodModal = ({
  closeModal,
  setLeavePeriod,
  leavePeriod,
  ...props
}) => {
  const [success, setSuccess] = React.useState(false);
  const [modalValues, setModalValues] = React.useState({
    leaveSession: "",
    startDate: "",
    endDate: "",
    // leavePeriodInDays: 0,
  });
  const [modalError, setModalError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const getLeavePeriod = (data) => {
    console.log("Logging leave periods gotten", data);

    data && data.result
      ? setLeavePeriod({
          ...leavePeriod,
          loading: false,
          period: [...data.result],
        })
      : setLeavePeriod({
          ...leavePeriod,
          loading: false,
          error: data.message,
        });
  };

  const getResponse = (response) => {
    console.log("REsponse of posting leave type", response);
    response && response.statusCode === "00" && setSuccess(true);
    response &&
      response.statusCode === "00" &&
      Get(URLAPI.LeavePeriodSetup.Get, getLeavePeriod);
    if (response) {
      if (response.statusCode === "01") {
        setLoading(false);
        setModalError(response.apiMessage);
      }
    }
  };

  const submitForm = (e) => {
    e.preventDefault();

    console.log("Work in Progress...", modalValues);
    if (
      modalValues.startDate < moment(`${new Date()}`) ||
      moment().format("YYYY-MM-DD") > modalValues.startDate
    ) {
      notification({
        title: "Modal Error!",
        message: "The start Date must be greater than the today's Date",
        type: "danger",
      });
      return;
    } else if (
      modalValues.startDate >= modalValues.endDate ||
      modalValues.endDate <= modalValues.startDate
    ) {
      notification({
        title: "Modal Error!",
        message: "The End Date must be greater than the Start Date",
        type: "danger",
      });
      return;
    } else {
      setLoading(true);
      Post(modalValues, URLAPI.LeavePeriodSetup.Post, getResponse);
    }
  };

  const closeSuccessModal = () => {
    setSuccess(false);
    closeModal();
  };

  return (
    <div
      className="overlay flex"
      style={{
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 2px 6px #0000000A",
      }}
    >
      {!success ? (
        <div className="w-100">
          <IoMdClose className="close-btn pointer" onClick={closeModal} />
          <div className="modal-box max-w-700">
            <div className="content">
              <form
                className="w-100"
                onSubmit={(e) => {
                  submitForm(e);
                }}
              >
                <TextInput
                  className="w-100 m-b-10"
                  label="Leave Session"
                  value={modalValues.leaveSession}
                  onChange={(e) =>
                    setModalValues({
                      ...modalValues,
                      leaveSession: e.target.value,
                    })
                  }
                  required
                />
                <div className="flex my-10 w-100 space-between">
                  <FormDateInput
                    name="startDate"
                    label="Start Date"
                    className="w-48"
                    value={modalValues.startDate}
                    onChange={(e) =>
                      setModalValues({
                        ...modalValues,
                        startDate: moment(`${e.target.value}`).format(
                          "yyyy-MM-DD"
                        ),
                      })
                    }
                    required
                  />
                  <FormDateInput
                    name="endDate"
                    label="End Date"
                    className="w-50"
                    value={modalValues.endDate}
                    onChange={(e) =>
                      setModalValues({
                        ...modalValues,
                        endDate: moment(`${e.target.value}`).format(
                          "yyyy-MM-DD"
                        ),
                      })
                    }
                    required
                  />
                </div>

                <button
                  type="submit"
                  // onClick={() => {
                  //   setModalValues({
                  //     ...modalValues,
                  //     leavePeriodInDays: Number(
                  //       `${moment(`${modalValues.endDate}`).diff(
                  //         moment(`${modalValues.startDate}`),
                  //         "days"
                  //       )}`
                  //     ),
                  //   });
                  // }}
                  className="btn btn-large p-r w-100 flex m-t-5 flex-v-center flex-h-center justify-content-center m-t-20"
                  disabled={loading}
                  style={{ height: "43px" }}
                >
                  <p style={{ margin: "auto 15px auto" }}>
                    {loading ? "Creating Leave Period" : "Create Leave Period"}
                  </p>
                  {loading && (
                    <img
                      src={LoaderSrc}
                      className="btn-loading"
                      alt="Loading..."
                    />
                  )}
                </button>
              </form>
            </div>
          </div>
          {modalError &&
            notification({
              title: "Modal Error!",
              message: `${modalError}`,
              type: "danger",
            })}
          {modalError &&
            setTimeout(() => {
              setModalError("");
            }, 5000)}
        </div>
      ) : (
        <SuccessModal
          action={"Leave Period Created Successfully!"}
          closeModal={closeSuccessModal}
        />
      )}
    </div>
  );
};

export default LeavePeriodModal;
