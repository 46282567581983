import React from "react";
import { IoMdClose } from "react-icons/io";

import SelectInput from "../inputs/SelectInput";
import TextInput from "../inputs/TextInput";

const ProfileForm = ({ closeModal }) => {
  const submitForm = () => {
    console.log("Work in Progress...");
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-400">
        <div className="content">
          <TextInput className="w-100 m-b-10" label="Goals/Target" />
          <TextInput className="w-100 m-b-10" label="Weight" />
          <TextInput
            className="w-100 m-b-10"
            label="Key Performance Indicator(KPI)"
          />
          <button
            type="button"
            className="btn btn-large w-100"
            onClick={submitForm}
          >
            Add Goal/Target
          </button>
        </div>
      </div>
      <butto type="button" className="btn btn-large w-100">
        Add
      </butto>
    </div>
  );
};

export default ProfileForm;
