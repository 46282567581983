import * as React from "react";
import { IoMdClose } from "react-icons/io";
import TextInput from "../inputs/TextInput";
import CheckboxInput from "../inputs/CheckboxInput";
import SuccessModal from "./SuccessModal";
import SelectInput from "../inputs/SelectInput";
import moment from "moment";
import Loader from "../../assets/svg/loading.svg";

// Api
import { Get, Put } from "../../utility/fetch";
import { URLAPI } from "../../utility/ApiMethods";

const EditLeaveType = ({
  closeModal,
  leaveDetails,
  setLeaveDetails,
  editLeave,
  ...props
}) => {
  const [modalValues, setModalValues] = React.useState({
    leaveName: "",
    maxLeaveDays: 0,
    isDeductible: 0,
    // requestReason: "false",
    leaveCategory: "",
    createdAt: moment().format("YYYY-MM-DDTHH:mm"),
  });
  const [success, setSuccess] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  console.log("Logging Current Leave", editLeave);

  const getLeaveDetails = (data) => {
    console.log("Logging leave details gotten", data.message);

    data && data.result
      ? setLeaveDetails({
          ...leaveDetails,
          loading: false,
          data: [...data.result],
        })
      : setLeaveDetails({
          ...leaveDetails,
          loading: false,
          error: data.message,
        });
  };

  const getResponse = (response) => {
    console.log("Response of posting leave type", response);
    response && response.statusCode === "00" && setSuccess(true);
    response &&
      response.statusCode === "00" &&
      Get(URLAPI.LeaveTypeSetup.Get, getLeaveDetails);
  };

  const submitForm = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("Work in Progress", modalValues);
    Put(
      { ...modalValues },
      `${URLAPI.LeaveTypeSetup.Put}${modalValues.id}`,
      getResponse
    );

    // setSuccess(true);
  };

  const closeSuccessModal = () => {
    setSuccess(false);
    closeModal();
  };

  React.useEffect(() => {
    console.log("Logging Current Leave", editLeave);
    setModalValues({ ...editLeave });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="overlay flex"
      style={{
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 2px 6px #0000000A",
      }}
    >
      {!success ? (
        <div>
          <IoMdClose className="close-btn pointer" onClick={closeModal} />
          <div className="modal-box max-w-400">
            <div className="content">
              <form onSubmit={(e) => submitForm(e)}>
                <TextInput
                  className="w-100 m-b-10"
                  label="Leave Name"
                  value={modalValues.leaveName}
                  onChange={(e) =>
                    setModalValues({
                      ...modalValues,
                      leaveName: e.target.value,
                    })
                  }
                  required
                />
                <TextInput
                  className="w-100 m-b-10"
                  type="number"
                  label="Number Of Days"
                  value={modalValues.maxLeaveDays}
                  onChange={(e) =>
                    setModalValues({
                      ...modalValues,
                      maxLeaveDays: Number(e.target.value),
                    })
                  }
                  minDate={0}
                  required
                />
                <SelectInput
                  label="Leave Category"
                  options={[
                    { name: "None", value: "None" },
                    { name: "Annual", value: "Annual" },
                  ]}
                  value={modalValues.leaveCategory}
                  onChange={(e) => {
                    console.log("select Value", modalValues.leaveCategory);
                    setModalValues({
                      ...modalValues,
                      leaveCategory: e.target.value,
                    });
                  }}
                  required
                />
                <div className="flex my-10">
                  <CheckboxInput
                    name={"Deductible"}
                    label={"Deductible"}
                    value={modalValues.isDeductible}
                    onChange={(e) =>
                      setModalValues({
                        ...modalValues,
                        isDeductible: e.target.checked ? 1 : 0,
                      })
                    }
                    route="edit"
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-large p-r w-100 flex  flex-v-center flex-h-center justify-content-center m-t-5"
                  //   onClick={submitForm}

                  disabled={isLoading}
                >
                  <p style={{ margin: "auto 15px auto" }}>
                    {isLoading ? " Editing Leave Type" : "Edit Leave Type"}
                  </p>
                  {isLoading && (
                    <img
                      src={Loader}
                      className="btn-loading"
                      alt="Loading..."
                    />
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <SuccessModal
          action={"Leave Type Edited Successfully!"}
          closeModal={closeSuccessModal}
        />
      )}
    </div>
  );
};

export default EditLeaveType;
