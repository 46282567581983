import * as React from "react";
import { IoMdClose } from "react-icons/io";
import TextInput from "../inputs/TextInput";
import FormDateInput from "../inputs/FormDateInput";
import SuccessModal from "./SuccessModal";
import moment from "moment";
import notification from "../../utility/notification";
import LoaderSrc from "../../assets/svg/loading.svg";

// Api
import { Get, Put } from "../../utility/fetch";
import { URLAPI } from "../../utility/ApiMethods";

const EditLeavePeriod = ({
  closeModal,
  leaveDetails,
  setLeaveDetails,
  editLeave,
  ...props
}) => {
  const [modalValues, setModalValues] = React.useState({
    leaveSession: "",
    startDate: "",
    endDate: "",
    leavePeriodInDays: 0,
    createdAt: moment().format("YYYY-MM-DDTHH:mm"),
  });
  const [success, setSuccess] = React.useState(false);
  const [modalError, setModalError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  //   console.log("Logging Current Leave", editLeave);

  const getLeaveDetails = (data) => {
    console.log("Logging leave PERIOD gotten", data);

    data && data.result
      ? setLeaveDetails({
          ...leaveDetails,
          loading: false,
          period: [...data.result],
        })
      : setLeaveDetails({
          ...leaveDetails,
          loading: false,
          error: data.message,
        });
  };

  const getResponse = (response) => {
    console.log("Response of posting leave period", response);
    response && response.statusCode === "00" && setSuccess(true);
    response &&
      response.statusCode === "00" &&
      Get(URLAPI.LeavePeriodSetup.Get, getLeaveDetails);

    if (response) {
      if (response.statusCode === "01") {
        setLoading(false);
        setModalError(response.apiMessage);
      }
    }
  };

  const submitForm = (e) => {
    e.preventDefault();

    console.log("Work in Progress", modalValues);
    if (
      modalValues.startDate < moment(`${new Date()}`) ||
      moment().format("YYYY-MM-DD") > modalValues.startDate
    ) {
      // setModalError("The start Date must be greater than the today's Date");
      notification({
        title: "Modal Error!",
        message: "The start Date must be greater than the today's Date",
        type: "danger",
      });
      return;
    } else if (
      modalValues.startDate >= modalValues.endDate ||
      modalValues.endDate <= modalValues.startDate
    ) {
      // setModalError("The End Date must be greater than the Start Date");
      notification({
        title: "Modal Error!",
        message: "The End Date must be greater than the Start Date",
        type: "danger",
      });
    } else {
      setLoading(true);
      Put(
        { ...modalValues },
        `${URLAPI.LeavePeriodSetup.Put}/${modalValues.id}`,
        getResponse
      );
      setModalError("");
    }
  };

  const closeSuccessModal = () => {
    setSuccess(false);
    closeModal();
  };

  React.useEffect(() => {
    console.log("Logging Current Leave", editLeave);
    setModalValues({
      // ...modalValues,
      ...editLeave,
      leaveSession: editLeave.sessionYear,
      startDate: moment(`${editLeave.startDate}`).format("yyyy-MM-DD"),
      endDate: moment(`${editLeave.endDate}`).format("YYYY-MM-DD"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="overlay flex"
      style={{
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 2px 6px #0000000A",
      }}
    >
      {!success ? (
        <div className="w-100">
          <IoMdClose className="close-btn pointer" onClick={closeModal} />
          <div className="modal-box max-w-700">
            <div className="content">
              {/* {details && Object.keys(details).length > 0 ? 
              
            
            } */}
              <form className="w-100" onSubmit={(e) => submitForm(e)}>
                <TextInput
                  className="w-100 m-b-10"
                  label="Leave Name"
                  value={modalValues.sessionYear}
                  onChange={(e) => {
                    setModalValues({
                      ...modalValues,
                      leaveSession: e.target.value,
                    });
                    // console.log("Leave Session", e.target.value);
                  }}
                  required
                />
                <div className="flex my-10 w-100 space-between">
                  <FormDateInput
                    name="startDate"
                    label="Start Date"
                    className="w-48"
                    value={modalValues.startDate}
                    onChange={(e) =>
                      setModalValues({
                        ...modalValues,
                        startDate: e.target.value,
                      })
                    }
                    required
                  />
                  <FormDateInput
                    name="endDate"
                    label="End Date"
                    className="w-50"
                    value={modalValues.endDate}
                    onChange={(e) =>
                      setModalValues({
                        ...modalValues,
                        endDate: e.target.value,
                      })
                    }
                    required
                  />
                </div>

                <button
                  type="submit"
                  onClick={() => {
                    setModalValues({
                      ...modalValues,
                      leavePeriodInDays: Number(
                        `${moment(`${modalValues.endDate}`).diff(
                          moment(`${modalValues.startDate}`),
                          "days"
                        )}`
                      ),
                    });
                  }}
                  className="btn btn-large p-r w-100 flex m-t-5 flex-v-center flex-h-center justify-content-center m-t-20"
                  disabled={loading}
                  style={{ height: "43px" }}
                >
                  <p style={{ margin: "auto 15px auto" }}>
                    {loading ? "Editing Leave Period" : "Edit Leave Period"}
                  </p>
                  {loading && (
                    <img
                      src={LoaderSrc}
                      className="btn-loading"
                      alt="Loading..."
                    />
                  )}
                </button>
              </form>
            </div>
          </div>
          {modalError &&
            notification({
              title: "Modal Error!",
              message: `${modalError}`,
              type: "danger",
            })}
          {modalError &&
            setTimeout(() => {
              setModalError("");
            }, 5000)}
        </div>
      ) : (
        <SuccessModal
          action={"Leave Period Edited Successfully!"}
          closeModal={closeSuccessModal}
        />
      )}
    </div>
  );
};

export default EditLeavePeriod;
