import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import ReactNotifications from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import Cookies from "js-cookie";

import Homepage from './components/home';
import Connect from "./components/Auth/connect";

import ProtectedRoute from "./routes/ProtectedRoute";
import AdminPortal from "./components/AdminPortal";

function App() {
  const history = createBrowserHistory();

  return (
    <Router history={history}>
      <div style={{ height: "100%" }}>
        <ReactNotifications />
        <Switch>
          <Route path='/' exact component={Homepage} />
          {/* <Route path="/" exact component={Connect} /> */}
          <Route
            path={["/home", "/login"]}
            exact
            component={() => {
              window.location.href = `${Cookies.get("homeLink")}`;
              return null;
            }}
          />
          <ProtectedRoute path="/admin-portal/" component={AdminPortal} />
          <Route render={() => <h1>Error 404. Page not found.</h1>} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
