import React, { Component } from "react";
import { RiAddLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { GiTrashCan } from "react-icons/gi";
import { URLAPI } from "../../utility/ApiMethods";
import { Get } from "../../utility/fetch";
import SearchInput from "../inputs/SearchInput";
import LocationDetails from "../layouts/LocationDetails";
import Form from "../layouts/SetperiodForm";

/*
const SetPages = ({ history }) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [details, setDetails] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Performance Approver';

    setDetails([
      {
        id: 1,

      },
      {
        id: 2,

      },
      {
        id: 3,

      },
      {
        id: 4,

      }
    ]);
  }, []);

  const showEditForm = (id) => {
    const selected = details.filter((item) => id === item.id);

    if (selected.length > 0) {
      setSelectedRecord(selected[0]);
      setShowFormModal(true);
    }
  };

  const closeModal = () => {
    setSelectedRecord([]);
    setShowFormModal(false);
  };

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">
        <h2></h2>
        <LocationDetails />
      </div>
      <div className="w-100 flex space-between flex-v-center m-t-20">
        <h4 className="san-sherif">Set Up</h4>
        <SearchInput placeholder="Add Approver" />
      </div>
      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            <div className="th"><p className="san-sherif">Performance Stages</p></div>
            <div className="th"><p className="san-sherif">Status</p></div>
            <div className="th"><p className="san-sherif">Start Date</p></div>
            <div className="th"><p className="san-sherif">End Date</p></div>
            <div className="th"><p className="san-sherif">Last Updated</p></div>
            <div className="th"><p className="san-sherif">Last Updated By</p></div>
            <div className="th">
              <button className="add-button pointer" type="button" onClick={() => setShowFormModal(true)}>
                <RiAddLine />
                Add Approver
              </button>
            </div>
          </div>
        </div>
        <div className="table-body">
          {details.map((item) => (
            <div className="table-row" key={item.id}>
              <div className="td">{item.role}</div>
              <div className="td">{item.sequence}</div>
              <div className="td" style={{textAlign: "left"}}>{item.EndDAte}</div>
              <div className="td">{item.lastUpdated}</div>
              <div className="td">{item.updatedBy}</div>
              <div className="td actions">
                <button type="button" className="pointer" onClick={() => showEditForm(item.id)}>
                  <BiEditAlt />
                </button>
                <button type="button" className="m-l-10 del pointer">
                  <GiTrashCan />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {showFormModal && (
        <Form details={selectedRecord} closeModal={closeModal} />
      )}

    </div>
  );
};

export default SetPages;
*/

class SetPages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      visible: false,
      loading: false,
      loadingData: false,
      selectedRecord: "",
      roleId: ""
    };
  }

  openModal = (id) => {
    const selected = this.state.records.filter((item) => id === item.id);
    if (selected.length > 0) {
      this.setState({ visible: true });
    }
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  fetchData = (data) => {
    let _records = data.data;
    this.setState({ records: _records, loadingData: false, visible: false });
    console.log(data);
  };

  componentDidMount() {
    this.setState({ loadingData: true }, () => {
      Get(URLAPI.AppraisalPeriod, this.fetchData);
    });
  }
  render() {
    return (
      <>
        <div className="w-100">
          <div className="w-100 flex space-between flex-v-center">{/* <LocationDetails /> */}</div>
          <div className="w-100 flex space-between flex-v-center m-t-20">
            <h4 className="san-sherif">Set Up</h4>
            <SearchInput placeholder="Add Approver" />
          </div>
          <div className="table-view m-t-10">
            <div className="table-header">
              <div className="table-row">
                <div className="th">
                  <p className="san-sherif">Start Date</p>
                </div>
                <div className="th">
                  <p className="san-sherif">End Date</p>
                </div>

                <div className="th">
                  <button
                    className="add-button pointer"
                    type="button"
                    onClick={() => this.openModal(true)}>
                    <RiAddLine />
                    Add Approver
                  </button>
                </div>
              </div>
            </div>
            <div className="table-body">
              {this.state.records.map((m) => (
                <div className="table-row" key={m.id}>
                  <div className="td">{m.startDate}</div>
                  <div className="td">{m.endDate}</div>
                  <div className="td actions">
                    <button type="button" className="pointer" onClick={() => this.openModal(m.id)}>
                      <BiEditAlt />
                    </button>
                    <button type="button" className="m-l-10 del pointer">
                      <GiTrashCan />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {this.openModal && <Form details={this.selectedRecord} closeModal={this.closeModal} />}
        </div>
      </>
    );
  }
}

export default SetPages;
